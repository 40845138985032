import React from 'react'
import '../css/policies.css'

const RefundPol = () => {
  return (
    <>
        <div className='termmaincontainer-div pt-3 pb-3'>
            <div className='container shadow p-2'>
                <div className='termcn-title-div'>
                    <h1>Refund & Cancellation Policy</h1>
                </div>

                <div className='iner-terncnd-cnt-dv'>
                <p><b>NIA Aviation Services informs all candidates that the refund policy will be subject to certain terms and conditions.</b></p>
                <p><u><b>After payment, if the registration number is not provided to you in the given time and you are not able to sit in the examination. Only in that case, we will refund the amount within 7 to 10 days.</b></u></p>
                </div>

                <div className='termcnd-list'>
                    <ul>
                       <li>In case Any disabled person enrolls on our website by mistake and if he clears the exam and is found in the interview that he is physically disabled, then at the same time all his things will be cancelled and the amount of his examination fee will be refunded.</li>
                       <li>If any person whose age is more than our age criteria mistakenly enrolls on the website and also clears the exam and is found in the interview to be over age, then at that very moment all his/her applications are canceled. and his/her exam fee will also be refunded.</li>
                       <li>If after filling out the application form, the candidate is not sent his application form PDF or invoice copy by the company within 7 business working days of making the payment, then his examination fee will be refunded to the candidate by the company.</li>
                       <li>If after completing the form and payment, the candidate sends a refund request for his/her payment before the application pdf and invoice is generated by the company web application, then the company will refund his/her payment to the candidate in that case also.</li>
                       <li>If the candidate makes payment for his training profile/post more than once by mistake. So the company will raise an investigation ticket. If in that case the candidate has actually already paid for the same profile/post more than once and the case goes in favor of the candidate then the company will refund his/her additional payment to that candidate.</li>
                       <li>If the candidate fills his application form through the company's website and also makes the payment and if the company has sent his application form and a copy of the invoice to the candidate on behalf of the company and the candidate downloads his application pdf and invoice. After a few days, weeks, or months of receipt, if the applicant makes a refund of his payment through the dispute, and if the company has proof from the candidate that he has received the application PDF and a copy of the invoice through email. So in that case the company may refuse to refund the payment.</li>
                       <li>If the candidate does not fill his/her application form correctly or gives wrong information to the company in his/her application form or cheats the company, in that case, the candidate is found guilty, and in that case, the company is not bound to give the refund.</li>
                       <li>If the candidate commits fraud with the company demand an unfair payment refund through any dispute and make false allegations against the company. If he determines the amount of the disputed amount wrongly and in that case, the company is found not guilty, then the company can refuse to refund the payment to that applicant and can resort to legal action against that applicant.</li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default RefundPol