

const WhatsApp = () => {
    return (
      <div className="d-print-none">
        <a  href="https://api.whatsapp.com/send/?phone=+919990809076&text=Hello &app_absent=0" className="btn-whatsapp-pulse shadow">
          <i className="iconify" data-icon="akar-icons:whatsapp-fill" style={{ color: "white" }}></i>
        </a>
  
      </div>
    )
  };
  
  export default WhatsApp;