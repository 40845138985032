import React, { useState } from 'react'
import { Select } from 'antd';
import './admin.css'
import axios from "axios";
import { useSelector,useDispatch } from "react-redux";
import { updateEditModeStatus } from '../../store/editModeSlice';

const EditMode = () => {

    const editModeRedux = useSelector((state)=> state.editMode)
    const dispatch = useDispatch();

    const [loading,setLoading] = useState(false)


    const handleChange = async(value) => {
        setLoading(true)
        try{
           const res =  await axios.patch(`${process.env.REACT_APP_HOSTURL}/api/v1/updateeditmode/65795b2dfdcd96a1cb8ce028`,{
                editModeStatus: value
            })
            dispatch(updateEditModeStatus(res.data.data.editModeStatus))
            setLoading(false)

        }catch(err){
            console.log(err)
            setLoading(false)
        }
      };
  return (
    <>
        <div className='container'>
            <div className='editModeContainer'>
            <label className='pb-2' htmlFor="update">Change Form Update Status : <b>{editModeRedux.editModeStatus ? 'Enabled' : 'Disabled'}</b></label>
            <Select
            id='update'
            onChange={handleChange}
                defaultValue="Select Option"
                style={{
                    width: 120,
                }}
                loading={loading}
                options={[
                    {
                    value: 'open',
                    label: 'Enable',
                    },
                    {
                    value: 'close',
                    label: 'Disable',
                    },
                ]}
                />
            </div>
        </div>
    </>
  )
}

export default EditMode