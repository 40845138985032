import React from 'react'
import './newHome.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// eslint-disable-next-line
import SwiperCore, {Autoplay} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination } from "swiper";

const HmCrousel = () => {
  return (
    <>
    <div className='nw_hm-slider_crousel-div'>
    <Swiper 
            modules={[Pagination,Autoplay]}
            pagination={true}
                spaceBetween={0}
                centeredSlides={false}
                loop={true}
                slidesPerView={'1'}
                autoplay={{
                    "delay": 3000,
                    "disableOnInteraction": false
                }}
                >
                 <SwiperSlide>
                    <div className='nw-crourslider-img-div'>
                        <img src="/images/hero1.jpg" alt="slider" />
                    </div>
                 </SwiperSlide>
                 <SwiperSlide>
                    <div className='nw-crourslider-img-div'>
                        <img src="/images/hero2.jpg" alt="slider" />
                    </div>
                 </SwiperSlide>
                 <SwiperSlide>
                    <div className='nw-crourslider-img-div'>
                        <img src="/images/hero3.jpg" alt="slider" />
                    </div>
                 </SwiperSlide>


                </Swiper>
    </div>
    
    </>
  )
}

export default HmCrousel