import React from 'react'
import {Helmet} from 'react-helmet';

import ContactForm from '../ContactForm';

const GroundStaff = () => {
  return (
    <>
    <Helmet>
      <meta charSet='UTF-8'/>
      <title>Job role as a ground staff</title>
      <meta name='description' content='paste your same description which is used on the same page' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
    </Helmet> 

      <div className='container-fluid  top-banner-pg-div'>
          <div className='inner-pg-wrapper-div'>
              <div className='container ot-page-banner-heading'>
                  <h1>Ground Staff</h1>
                  <p>Home / ground staff</p>
              </div>
          </div>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/groundstaff/1.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/groundstaff/2.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Job role as a ground staff</h3>
              <p>You will be in charge of greeting the passengers and responding to their inquiries as part of the Ground Crew. Also, you will be in charge of selling the plane tickets and arranging bookings as needed. It will be advantageous for the candidate if they have prior experience working as ground staff or in a corresponding position within the travel department. Also, you should have exceptional communication skills in addition to a keen eye for detail for correctness. Also, you should be fully informed about flight schedules, weather forecasts, aircraft delays, and other elements that could impact travel for passengers. Also, you should be able to resolve passenger issues rapidly.
               <br />
               <br />
               We are seeking for motivated ground employees who can handle administrative tasks and passenger interaction. Your responsibilities will involve greeting guests, giving out information, helping those who are disabled or are taking young children on a trip, checking luggage, taking reservations, and selling tickets. You should be able to respond to questions from customers quickly and guarantee their satisfaction.
            
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container ot-page-cnt-form-div'>
        <div className='row'>
          <div className='col-lg-7'>
            <div className='ot-page-cnt-leftidv'>
            {/* <h3></h3> */}
              <p>You must exhibit exceptional interpersonal skills and provide good customer service that regularly satisfies the airline company's aims and vision in order to succeed as ground staff. In the end, exceptional ground crew should be able to multitask and make sure that each passenger is relaxed, safe, and aware of flight schedules, aircraft delays, weather issues, and other elements that may affect passengers. Some of the duties they carry out include answering questions from passengers, cleaning the cabin, providing cuisine, selling tickets, and organising, supervising, and managing airline flight operations. 
               <br />
               <br />
               They are also in charge of ensuring that each flight is executed flawlessly and on schedule, as well as adhering to regulatory regulations. They do everything with a smile, including timely boarding, safekeeping of their belongings, maintaining the 
               cleanliness of the aircraft, and managing on-board refreshments. Nice, huh? We're always looking for kind, upbeat, and incredibly helpful individuals who want to improve the experiences of our clients.
              
               </p>
            </div>
          </div>
          <div className='col-lg-5'>
            <div className='ot-page-cnt-rightidv border'>
              <ContactForm className='ot-pg-form-right'/>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img-div'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
         
          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right-btm'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Responsibility and requirement</h3>
                <h5>Responsibility</h5>
                <ul>
                  <li>Greeting travellers when they arrive at the airport.</li>
                  <li>Verifying the passenger's luggage load.</li>
                  <li>The sale of airline tickets.</li>
                  <li>Scheduling reservations as needed.</li>
                  <li>Ensuring the comfort and safety of every passenger.</li>
                  <li>Helping travellers by responding to their inquiries.</li>
                  <li>Assisting kids and passengers with disabilities.</li>
                  <li>Making boarding tickets and identity checks for travellers.</li>
                  <li>Conducting security checks on the travellers and their luggage.</li>
                  <li>Transporting and removing luggage from or onto an aircraft.</li>
                  <li>Carrying out any necessary cabin-related tasks as needed.</li>
                  <li>Staffing of the check-in desks for leaving travellers.</li>
                  <li>Verifying the availability of the plane's refreshments.</li>
                  <li>Providing the passengers with excellent customer service.</li>
                </ul>
             </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left-btm'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/groundstaff/5.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/groundstaff/6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img2-div'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/groundstaff/3.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/groundstaff/4.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
             <h5>Requirements</h5>
            <ul>
              <li>The ability to work in shifts.</li>
              <li>Excellent verbal and written communication abilities.</li>
              <li>The capacity to provide first-rate customer service.</li>
              <li>A person who values their customers.</li>
              <li>Quickness in resolving passengers' issues.</li>
              <li>Should be well-groomed and have a kind disposition.</li>
              <li>The ability to direct customers to the appropriate counters.</li>
              <li>Strong decision-making abilities.</li>
              <li>Strong interpersonal and organisational abilities.</li>
              <li>A remarkable capacity for effective time management.</li>
              <li>An acute attention to detail for accuracy.</li>

            </ul>
             </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GroundStaff