import React from "react";
import { MdPhoneCallback, MdOutlineEmail, MdPinDrop } from "react-icons/md";

import ContactForm from "./ContactForm";

const Contactquery = () => {
  return (
    <>
      <div className="contact-main-container">
        <div className="container inner-main-contact-container">
          <div className="contact-title-div">
            <h3>Contact Us</h3>
          </div>
          <div className="contact-detail-upper-div shadow-sm">
            <div className="conat-email-detail-cnt-pg shadow">
              <div>
                <a href="tel:+919990809076">
                  <h4>
                    <MdPhoneCallback className="contact-form-icons" />
                    <span className="inner-details">+91 99-908090-76</span>
                  </h4>
                </a>
                <a href="tel:+919990809056">
                  <h4>
                    <MdPhoneCallback className="contact-form-icons" />
                    <span className="inner-details">+91 99-908090-56</span>
                  </h4>
                </a>
              </div>
              <a href="mailto:info@niaaviationservices.com">
                <h4>
                  <MdOutlineEmail className="contact-form-icons" />{" "}
                  <span className="inner-details">
                    info@niaaviationservices.com
                  </span>{" "}
                </h4>
              </a>
            </div>
            <h4>
              Operational-Office <MdPinDrop className="contact-form-icons" />{" "}
              <span className="inner-details">
              B3, 3rd Floor, Nawada Housing Complex, Dwarka Mor, Near Pillar No. 787, New Delhi-110078
              </span>
            </h4>
            <h4>
              Head-Office <MdPinDrop className="contact-form-icons" />{" "}
              <span className="inner-details">
              OFFICE NO-1156 11TH FLOOR GAUR CITY MALL C- 01/BGH-01, SECTOR 04 NOIDA Gautam Buddha Nagar UP 201301 IN
              </span>
            </h4>
          </div>

          <div className="cnt-form-lower-div">
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactquery;
