import React from 'react'
import {Helmet} from 'react-helmet';

const TrainingOverview = () => {
  return (
    <>
       <Helmet>
      <meta charSet='UTF-8'/>
      <title>Training & education</title>
      <meta name='description' content='paste your same description which is used on the same page' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
    </Helmet>

      <div className='container-fluid  top-banner-pg-div-training-overview'>
          <div className='inner-pg-wrapper-div'>
              <div className='container ot-page-banner-heading'>
                  <h1>Training Overview</h1>
                  <p>Home / training overview</p>
              </div>
          </div>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/trainingoverview/1.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/trainingoverview/2.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Knowledge of airport</h3>
              
              <p>The complicated interactions between the many parties (such as airports, airlines, and air traffic control) and their unique operational methods and technologies define the international air transport system. A thorough system of regulations defines and governs every aspect of aviation. It can be very difficult to comprehend these complicated procedures and rules, especially for someone who is unfamiliar with the subject.
               <br />
               <br />
               This training aims to give a general overview of aviation as well as a detailed analysis of the connections between air operations and technical issues in particular areas under the purview of the various stakeholders.
            
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container ot-page-cnt-form-div'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='ot-page-cnt-leftidv'>
            {/* <h3>Put Heading Here...</h3> */}
              <p>Participants in the programme will learn about the national and international legal systems, which are crucial for all involved parties. The roles and responsibilities of the several important parties (airport, airline, air traffic control), as well as their interactions with one another, are then thoroughly covered. Also, the fundamental methods of flight operations are described from the perspectives of all concerned organisations.
               <br />
               <br />
               The training focuses on the organisational structure of the different organisations, as well as how they interact and operate on a daily basis. The trainer and guest speakers will create the following aviation qualities with the assistance of the participants: What difficulties do airport aviation operations on the ground and in the air face?
              
               </p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/trainingoverview/3.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/trainingoverview/4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img-div-tr'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
         
          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right-btm'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>All About the Duties</h3>
              
              <p>Who are the main characters? How is a day in the cockpit prepared and what does it look like? What operational techniques and technologies are employed throughout a flight? What kind of interactions occur between airlines? How is airport and aviation safety maintained by air traffic control? What varieties of systems are offered?
               <br />
               <br />
               Plans for training and advancement are developed to guarantee that employees obtain and maintain the levels of training necessary to meet the organization's current and future competency requirements. Ongoing efforts are made to ensure that these plans are carried out without delay. In this approach, emphasis is placed on providing them with the comprehensive information and abilities they need in order to provide the results expected of them in the most efficient and effective manner possible.
            
               </p>
             </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left-btm'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/trainingoverview/5.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/trainingoverview/6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img2-div-tr'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/trainingoverview/7.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/trainingoverview/8.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Types of airport management software solutions </h3>
              <p>The aviation sector depends on customised software solutions to increase security, optimise customer convenience, and improve airport operational efficiency.
               <br />
               <br />
               Among the many different kinds of airport information management systems and solutions are
            
               </p>

               <ul>
                <li>Airport operational database management (AODB) systems</li>
                <li>Flight information display systems (FIDS)</li>
                <li>Automated aircraft movement registration systems</li>
                <li>Air traffic control and management systems</li>
                <li>Aeronautical telecommunications network (ATN) solutions for ground-ground and air-ground data communications</li>
                <li>Secure passenger processing solutions</li>
                <li>Automated boarding pass verification systems</li>
                <li>Aircraft load control software</li>
                <li>Automated baggage handling software</li>
                <li>Apron management systems</li>
                <li>Emergency messaging systems</li>
                <li>Automated immigration and access management solutions</li>
                <li>Software products for airport analytics, concession management, and car park revenue management</li>
               </ul>
             </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TrainingOverview