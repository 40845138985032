import React from 'react'

const GovImgGal = () => {
  return (
    <>
        <div className='nia-nw-hm-title-dv'>
            <div className='container'>
               <div className='nw-hmheading-tirl-dv'>
               <h1>WELCOME TO</h1>
                <h1>NIA Aviation Services Pvt. Ltd.</h1>
               </div>
            </div>

            <div className='container'>
                <div className='gov-img-gal-inr-wrapper'>
                    <div className='img-gov-inr-dv'>
                        <img src="/images/gov/chasma.png" alt="" />
                    </div>
                    <div className='img-gov-inr-dv'>
                        <img src="/images/gov/sbharat.png" alt="" />
                    </div>
                    <div className='img-gov-inr-dv'>
                        <img src="/images/gov/swach.png" alt="" />
                    </div>
                    <div className='img-gov-inr-dv'>
                        <img src="/images/gov/75amrit.png" alt="" />
                    </div>
                    <div className='img-gov-inr-dv'>
                        <img src="/images/gov/digi.png" alt="" />
                    </div>
                    <div className='img-gov-inr-dv'>
                        <img src="/images/gov/india.png" alt="" />
                    </div>
                </div>
            </div>

        </div>
    </>
  )
}

export default GovImgGal