import React, { useState } from 'react'
import axios from 'axios'

const TestPage = () => {

const [amount,setAmount] = useState('')
const [loading, setLoading] = useState(false)

const [initiatePayment, setInitiatePayment] = useState({})

    const phonePe = async(e)=>{
        e.preventDefault()
        try{
            setLoading(true)
     const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/phonepe/gateway`,{
                amount: amount,
                userDetails: new Date().getTime(),
                mobile: '9999999999'
            })
            // console.log(res.data.body)
             setInitiatePayment(res.data.body.data)
             window.open(res.data.body.data.instrumentResponse.redirectInfo.url)
            // window.location.href = res.data.body.data.instrumentResponse.redirectInfo.url
        }catch(err){
            //console.log(err)
        }
    }


    const verifyPayment  = async(e)=>{
        e.preventDefault()
        try{
            const verifyRes = await axios.post(`http://localhost:9090/api/v1/phonepe-verify-payment`,{
                merchantTransactionId: initiatePayment.merchantTransactionId
            })
            console.log(verifyRes.data)

        }catch(err){
            console.log(err.response.status)
            console.log(err.response.data.message)
        }
    }

    console.log('Initiated payment',initiatePayment.merchantTransactionId)

  return (
    <div className='mt-5 mb-5' style={{display: 'flex', flexDirection:'column',alignItems: 'center', justifyContent: 'center'}}>
      <h1 className=' mb-4'>Phone pe test payment page</h1>
        <form onSubmit={phonePe} >
        <div className="form-floating mb-3">
        <input type="number" className="form-control" id="floatingInput" placeholder="Enter Amount" onChange={(e)=> setAmount(e.target.value)} value={amount}/>
        <label htmlFor="floatingInput">Enter Amount</label>
        </div>
            <button className='btn btn-primary'  type='submit'>Pay Now</button>
        </form>

        <div>
            {loading ? 'loading' : 'not loading'}
        </div>

        <div className='verify-div'>
            <button className='btn btn-warning' onClick={verifyPayment}>Verify Payment</button>
        </div>
    </div>
  )
}

export default TestPage
