import axios from 'axios';
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

const LatestUpdateAlert = () => {

  const latestUpdatesurl = `${process.env.REACT_APP_HOSTURL}/api/v1/notificationalert`;

    const [latestUpdates, SetLatestUpdates] = useState([]);
    const [loading, setLoading] = useState(false);


    const fetchLatestUpdates = useCallback(async () => {
        setLoading(true);
        try {
          const res = await axios.get(latestUpdatesurl);
         // console.log(res.data);
          if (res.data.statusCode === 200) {
            SetLatestUpdates(res.data.data);
            setLoading(false);
          }
          if (res.data.data === undefined) {
            setLoading(true);
          }
        } catch (err) {
         // console.log(err);
        }
      }, [latestUpdatesurl]);
    
      useEffect(() => {
        fetchLatestUpdates();
        // eslint-disable-next-line
      }, [fetchLatestUpdates, ]);


  return (
    <>
        <div className='latest-alert-update-main-container'>
            <div className='container'>
                <div className='latest-aler-iner-wrapper-div'>
                    <div className='india-map-inr-div'>
                        <div className='india-map-iner-img-dicv'>
                            <h3>More Information</h3>
                            <div className='ind-map-border-div'>
                            <img src="/images/gov/indmap.png" alt="" />
                            </div>
                            <div className='smallplane-div'>
                                <img src="/images/smallplane.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='upateandalert-inr-div'>
                        <div className='updatealer-inr-cntr-div'>
                            <div className='contr-title-inr-alert-div'>
                                <h1>Latest Updates and Alerts Available</h1>
                                <img src="/images/icons/unrlinr.svg" alt="" />
                            </div>

                            <div className='alert-updt-div-md-bmt'>
                                <div className='aler-iner-btmd0pv-ttle'>    
                                <div className='laert-chat-icn-dv'>
                                <img src="/images/icons/chat.svg" alt="" />
                                <h5>Information</h5>
                                </div>
                                    <div className='aler-sm-box'>
                                        <h5>--</h5>
                                    </div>
                                </div>

                                <div className='main-alert-update-list'>
                                    <ul>
                                       {
                                        loading ? (
                                            <>
                                            <div className="loading-div">
                                                <img
                                                    src="/images/loading.svg"
                                                    width={100}
                                                    height={100}
                                                    alt="loading"
                                                />
                                                <h5>Loading...</h5>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                            {
                                                latestUpdates?.map((d,i)=>{
                                                    return(
                                                        <li key={i}>
                                                        <img src="/images/icons/arrow.svg" alt="" />
                                                        <p>{d?.list}</p>
                                                    </li>
                                                    )
                                                })
                                            }


                                            </>
                                        )
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className='airindiaplane-div'>
                                <img src="/images/airindiaplane.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='kahdi-india-links-div'>
                        <div className='khadi-inr-div-wrapper'>
                            <img src="/images/gov/khadi.png" alt="" />
                        </div>

                        <div className='quclnk-inr-rght-idv-alerts'>
                            <div className='linkqck-let-title-dv'>
                                <h4>QUICK LINKS</h4>
                            </div>
                           <div className='btnaler0qwclinf-div'>
                           <ul>
                                <li>
                                    <img src="/images/icons/tick.svg" alt="" />
                                   <div className='qjclkinlist-txt-rght-lart'>
                                    <Link to={'/register-now'}>
                                   <p>Apply Now</p>
                                    </Link>
                                   </div>
                                </li>
                                <li>
                                    <img src="/images/icons/tick.svg" alt="" />
                                   <div className='qjclkinlist-txt-rght-lart'>
                                    <Link to={'/getadmitcard'}>
                                   <p className='blink'>Admit Card (Available)</p>
                                    </Link>
                                   </div>
                                </li>
                                <li>
                                    <img src="/images/icons/tick.svg" alt="" />
                                   <div className='qjclkinlist-txt-rght-lart'>
                                    <Link to={'/'}>
                                   <p>Result (not Available)</p>
                                    </Link>
                                   </div>
                                </li>
                                <li>
                                    <img src="/images/icons/tick.svg" alt="" />
                                   <div className='qjclkinlist-txt-rght-lart'>
                                    <Link to={'/becamegroundstaff'}>
                                   <p>Become Ground staff</p>
                                    </Link>
                                   </div>
                                </li>
                                <li>
                                    <img src="/images/icons/tick.svg" alt="" />
                                   <div className='qjclkinlist-txt-rght-lart'>
                                    <Link to={'/wheelchairstaff'}>
                                   <p>wheel Chair staff</p>
                                    </Link>
                                   </div>
                                </li>
                                <li>
                                    <img src="/images/icons/tick.svg" alt="" />
                                   <div className='qjclkinlist-txt-rght-lart'>
                                    <Link to={'/trainingoverviews'}>
                                   <p>training Overview</p>
                                    </Link>
                                   </div>
                                </li>
                                <li>
                                    <img src="/images/icons/tick.svg" alt="" />
                                   <div className='qjclkinlist-txt-rght-lart'>
                                    <Link to={'/professionaltraining'}>
                                   <p>professional overview</p>
                                    </Link>
                                   </div>
                                </li>
                                <li>
                                    <img src="/images/icons/tick.svg" alt="" />
                                   <div className='qjclkinlist-txt-rght-lart'>
                                    <Link to={'/aviationaftertwelfth'}>
                                   <p>Aviation after 12<sup>th</sup></p>
                                    </Link>
                                   </div>
                                </li>
                                <li>
                                    <img src="/images/icons/tick.svg" alt="" />
                                   <div className='qjclkinlist-txt-rght-lart'>
                                    <Link to={'/airportdetails'}>
                                   <p>Airport retail</p>
                                    </Link>
                                   </div>
                                </li>
                                <li>
                                    <img src="/images/icons/tick.svg" alt="" />
                                   <div className='qjclkinlist-txt-rght-lart'>
                                    <Link to={'/housekeeping'}>
                                   <p>airport services</p>
                                    </Link>
                                   </div>
                                </li>
                            </ul>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LatestUpdateAlert