import React, { useState , useEffect, useCallback} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { Tooltip } from 'antd';

const Notification = () => {

    const notificationDownloadUrl = `${process.env.REACT_APP_HOSTURL}/api/v1/notificationdownload`;

    const [notificationUrl, setNotificationUrl] = useState();

    const fetchNotificationUrlDownlaod = useCallback(async () => {
        try {
          const res = await axios.get(notificationDownloadUrl);
          setNotificationUrl(res.data);
        } catch (err) {
          console.log("Error: ", err);
        }
      }, [notificationDownloadUrl]);
    
      useEffect(() => {
        fetchNotificationUrlDownlaod();
      }, [fetchNotificationUrlDownlaod]);

     // console.log(notificationUrl?.notification?.url[0]?.notificationurlenglish)

  return (
    <>
        <div className='notify-nw-md-main-conatainer'>
            <div  className='container'>
                <div className='mw-md-bg-cont-wrapper'>
                    <div className='vacancy-inr-dv'>
                        <div className='profile-inr-dvi'>
                            <h2>Profile</h2>
                            <div className='line'></div>
                            <Link to={'/register-now'}>
                            <p>Customer Service Associate (CSA) <span className='new blink'>New</span></p>
                            </Link>
                            {/* <h1 className='shake'><Link to={'/register-now'}>APPLY NOW</Link></h1> */}
                            <Tooltip placement="top" title="Application Submission Date Is Over">

                            <h1 className='shake'>APPLY NOW</h1>
                                 </Tooltip>
                            <div className='line'></div>
                        </div>
                    </div>
                    <div className='notification-sec-dv'>
                        <div className='notification-inr-wrapper-div'>
                            <div className='notifdu-inrt-conatianer'>
                               
                                <h1>Notification Download Available in Hindi / English</h1>
                               
                                <div className='notidfu-btm-line'></div>
                                <div className='notification-inr-btm-cnt-txt'>
                                    <img src="/images/icons/pdf.svg" alt="" />
                                    <a href={notificationUrl?.notification?.url[0]?.notificationurlhindi}>
                                    <p>NIA Aviation Services Notification Hindi 2023-24</p>
                                    </a>
                                </div>
                                <div className='notification-inr-btm-cnt-txt'>
                                    <img src="/images/icons/pdf.svg" alt="" />
                                    <a href={notificationUrl?.notification?.url[0]?.notificationurlenglish}>
                                    <p>NIA Aviation Services Notification English 2023-24</p>
                                    </a>
                                </div>
                                {/* <div className='syllabus-heading'>
                                    <h3>Syllabus You Need To Know !</h3>
                                    <div className='sylabu-cointyer-inr-div'>
                                    <img src="/images/icons/pdf.svg" alt="" />
                                    <Link to={'/'}>
                                    <p>NIA/Semester/Syllabus 2022-23</p>
                                    </Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='how to apply'>
                       <div className='yotube-inr-container-dv'>
                       <iframe width="560" height="214" src="https://www.youtube.com/embed/QJif2MAEGO0?si=Nn-tExTcsbWzMyeO" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Notification
