import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./admin.css";
import { ExportJsonCsv } from "react-export-json-csv";
import { MdOutlineCancel } from "react-icons/md";
import {
  MdFileUpload,
  MdOutlineDeleteOutline,
  MdDeleteForever,
} from "react-icons/md";
import RegisteredUsers from "./RegisteredUsers";
import QueryPage from "./QueryPage";
import NotificationAlerts from "./NotificationAlerts";
import EditMode from "./EditMode";

import { Input } from "antd";
const { Search } = Input;

const Admin = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [formsPaymentStatus, setFormsPaymentSatus] =
    useState("getonlypaidform");

  const updateFormStatus = (status) => {
    setFormsPaymentSatus(status);
  };

  const dataUrl = `${process.env.REACT_APP_HOSTURL}/api/v1/${formsPaymentStatus}?page=${page}&limit=${limit}`;

  const notificationDownloadUrl = `${process.env.REACT_APP_HOSTURL}/api/v1/notificationdownload`;

  const fetchGalleryUrl = `${process.env.REACT_APP_HOSTURL}/api/v1/admin/gallery`;

  const [allFormsData, setAllFormsData] = useState([]);
  const [csvLoading, setCSVLoading] = useState(false);

  const [data, setData] = useState([]);
  const [allqueries, setAllQuary] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isNotificationUpdateUrl, setIsNotificationUrl] = useState(false);
  const [hindiNotificationUrl, setHindiNotificationUrl] = useState("");
  const [engNotificationUrl, setEngNotificationUrl] = useState("");
  const [notificationUrl, setNotificationUrl] = useState({});
  const [notificationURLUpdated, setNotificationURLUpdated] = useState({});

  const [galleryImage, setGalleryImage] = useState("");
  const [galleyImageStore, setGalleryImageStore] = useState([]);
  const [uploadImgMsg, setUploadImgMsg] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [itemsPerpage, setItemsPerPage] = useState(15);

  const indexOfLastitems = currentPage * itemsPerpage;
  const indexOfFirstItems = indexOfLastitems - itemsPerpage;
  const currentItems = data?.slice(indexOfFirstItems, indexOfLastitems);

  // eslint-disable-next-line
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [totalPaidCount, setTotalPaidCount] = useState("");

  const fetcData = async () => {
    setLoading(true);

    const adminData = localStorage.getItem("admininfo");

    if (adminData) {
      try {
        const res = await axios.get(dataUrl);

        setData(res.data.body);
        setTotalPaidCount(res.data.total);
        setLoading(false);
      } catch (err) {
        console.log("Error Fetching Candidate Data : ", err);
      }
    } else {
      navigate("/admin/login");
    }
  };

  const enableNotificationUrlUpdate = () => {
    setIsNotificationUrl(true);
  };

  const closeNotifucationUrl = () => {
    setIsNotificationUrl(false);
  };

  const fetchNotificationUrlDownlaod = async () => {
    try {
      const res = await axios.get(notificationDownloadUrl);
      setNotificationUrl(res.data);
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  //TODO: Notification URL Update Path

  const updateHindiNotificationUrl = async (e) => {
    e.preventDefault();

    const notificationUrlId = notificationUrl.notification.url[0]._id;
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_HOSTURL}/api/v1/notificationdownload/${notificationUrlId}`,
        {
          notificationurlhindi: hindiNotificationUrl,
        }
      );

      setNotificationURLUpdated(res.data);
      setHindiNotificationUrl("");
    } catch (err) {
      console.log(err);
    }
  };

  const updateEnglishNotificationUrl = async (e) => {
    e.preventDefault();

    const notificationUrlId = notificationUrl.notification.url[0]._id;
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_HOSTURL}/api/v1/notificationdownload/${notificationUrlId}`,
        {
          notificationurlenglish: engNotificationUrl,
        }
      );

      setNotificationURLUpdated(res.data);
      setEngNotificationUrl("");
    } catch (err) {
      console.log(err);
    }
  };

  // eslint-disable-next-line
  const fetchGalleryImgs = async () => {
    try {
      const res = await axios.get(fetchGalleryUrl);

      setGalleryImageStore(res.data.imageResponse);
    } catch (err) {
      console.log("Gallery Image not fetched ", err);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetcData();
    fetchNotificationUrlDownlaod();
    //fetchGalleryImgs();
    // eslint-disable-next-line
  }, [formsPaymentStatus, page, limit]);

  const logoutAdmin = () => {
    localStorage.removeItem("admininfo");
    navigate("/");
  };

  const queryHandler = () => {
    setAllQuary(true);
  };
  const goBackHandler = () => {
    setAllQuary(false);
  };

  const getSingleFromData = (e) => {
    console.log(e.target.id);
    let listId = e.target.id;

    if (listId) {
      const id = listId;
      navigate(`/viewcandidatesdetails/${id}`, {
        state: {
          id: id,
        },
      });
    }
    console.log("list-ID", listId);
  };

  const fetchAllFormsDataCSV = async () => {
    setCSVLoading(true);
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_HOSTURL
        }/api/v1/formdata?page=${1}&limit=${999999999}`
      );
      setAllFormsData(res.data.body);
    } catch (err) {
      alert(err.response.data.message);
    } finally {
      setCSVLoading(false);
    }
  };

  const headers = [
    {
      key: "formId",
      name: "Form ID",
    },

    {
      key: "userId",
      name: "User ID",
    },
    {
      key: "registerId",
      name: "Registration ID",
    },
    {
      key: "applicationId",
      name: "Application ID",
    },

    {
      key: "fname",
      name: "First Name",
    },
    {
      key: "lastname",
      name: "Last Name",
    },
    {
      key: "fatherHusbandName",
      name: "Father/Husband Name",
    },

    {
      key: "motherName",
      name: "Mother Name",
    },
    {
      key: "dob",
      name: "Date Of Birth",
    },
    {
      key: "gender",
      name: "Gender",
    },
    {
      key: "categoryCast",
      name: "Categories Cast",
    },
    {
      key: "maritalStatus",
      name: "Marital Status",
    },
    {
      key: "postapply",
      name: "Post Apply",
    },
    {
      key: "price",
      name: "Application Fees",
    },
    {
      key: "postId",
      name: "Post ID",
    },
    {
      key: "email",
      name: " E-mail",
    },
    {
      key: "mobile",
      name: " Mobile",
    },
    {
      key: "educationalqualification",
      name: "Educational Qualification",
    },

    {
      key: "educationaldivision",
      name: "Educational Division",
    },
    {
      key: "intermediate",
      name: "Intermediate",
    },
    {
      key: "intermediatedivision",
      name: "Intermediate Divison",
    },
    {
      key: "highschool",
      name: "High School",
    },
    {
      key: "highschooldivision",
      name: "High School Division",
    },
    {
      key: "uploadPhoto",
      name: "Upload Photo",
    },

    {
      key: "firebasestudentprofile",
      name: "Firebase Profile Image",
    },

    {
      key: "uploadSignature",
      name: "Upload Signature",
    },

    {
      key: "firebasestudentsignature",
      name: "Firebase Signature Image",
    },

    {
      key: "add_Present_Street",
      name: "Address Present Street",
    },
    {
      key: "add_Present_houseno",
      name: "Address Present houseno",
    },
    {
      key: "add_Present_village",
      name: "Address Present village",
    },
    {
      key: "add_Present_city",
      name: "Address Present city",
    },
    {
      key: "add_Present_postoffice",
      name: "Address Present postoffice",
    },
    {
      key: "add_Present_state",
      name: "Address Present state",
    },
    {
      key: "add_Present_district",
      name: "Address Present district",
    },
    {
      key: "add_Present_pincode",
      name: "Address Present pincode",
    },

    {
      key: "sameAdd",
      name: "Same Address",
    },
    {
      key: "declaration",
      name: "Declaration",
    },

    {
      key: "addPermanent_PR_Street",
      name: "Address Permanent Street",
    },
    {
      key: "addPermanent_PR_houseno",
      name: "Address Permanent houseno",
    },
    {
      key: "addPermanent_PR_village",
      name: "Address Permanent village",
    },
    {
      key: "addPermanent_PR_city",
      name: "Address Permanent city",
    },
    {
      key: "addPermanent_PR_postoffice",
      name: "Address Permanent postoffice",
    },
    {
      key: "addPermanent_PR_state",
      name: "Address Permanent state",
    },
    {
      key: "addPermanent_PR_district",
      name: "Address Permanent district",
    },
    {
      key: "addPermanent_PR_pincode",
      name: "Address Permanent pincode",
    },
    {
      key: "createdAt",
      name: "Created At",
    },
    {
      key: "updatedAt",
      name: "Updated At",
    },
    {
      key: "examCentreState",
      name: "Exam centre state",
    },
    {
      key: "examCentreCity",
      name: "Exam centre city",
    },
    {
      key: "paymentStatus",
      name: "Payment Status",
    },
    {
      key: "paymentId",
      name: "Payment Id",
    },
  ];

  const datas = [];

  allFormsData?.map((a, index) => {
    let obj = {
      formId: a._id,
      userId: a.userId,
      registerId: a.registrationNumber,
      applicationId: a.applicationnumber,
      fname: a.candidatename,
      lastname: a.lastname,
      fatherHusbandName: a.fatherhusbandname,
      motherName: a.mothername,
      dob: a.dateofbirth,
      gender: a.gender,
      categoryCast: a.categorycast,
      maritalStatus: a.maritalstatus,
      postapply: a.postapply,
      price: a.price,
      postId: a.postId,
      email: a.email,
      mobile: a.mobile,
      educationalqualification: a.educationalqualification,
      educationaldivision: a.educationdivision,
      intermediate: a.intermediate,
      intermediatedivision: a.intermediatedivision,
      highschool: a.highschool,
      highschooldivision: a.highschooldivision,
      uploadPhoto: a.uploadphoto,
      firebasestudentprofile: a.firebasestudentprofile,
      uploadSignature: a.uploadsignature,
      firebasestudentsignature: a.firebasestudentsignature,
      add_Present_Street: a.address.present.street,
      add_Present_houseno: a.address.present.houseno,
      add_Present_village: a.address.present.village,
      add_Present_city: a.address.present.city,
      add_Present_postoffice: a.address.present.postoffice,
      add_Present_state: a.address.present.state,
      add_Present_district: a.address.present.district,
      add_Present_pincode: a.address.present.pincode,
      sameAdd: a.isPresent,
      declaration: a.isDeclaration,
      addPermanent_PR_Street: a.address.permanent.pr_street,
      addPermanent_PR_houseno: a.address.permanent.pr_houseno,
      addPermanent_PR_village: a.address.permanent.pr_village,
      addPermanent_PR_city: a.address.permanent.pr_city,
      addPermanent_PR_postoffice: a.address.permanent.pr_postoffice,
      addPermanent_PR_state: a.address.permanent.pr_state,
      addPermanent_PR_district: a.address.permanent.pr_district,
      addPermanent_PR_pincode: a.address.permanent.pr_pincode,
      examCentreState: a.examCentreState,
      examCentreCity: a.examCentreCity,
      createdAt: a.createdAt,
      updatedAt: a.updatedAt,
      paymentStatus: a.paymentStatus,
      paymentId: a.paymentId,
    };
    datas.push(obj);
    return a;
  });

  const pages = [];

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  // eslint-disable-next-line
  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let increamentBtn = null;
  if (pages.length > maxPageNumberLimit) {
    // eslint-disable-next-line
    increamentBtn = <li onClick={handleNextBtn}> &hellip; </li>;
  }

  for (let i = 0; i <= Math.ceil(data?.length / itemsPerpage); i++) {
    pages.push(i);
  }
  // eslint-disable-next-line
  const renderPageNumber = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const galleryUpload = async (e) => {
    e.preventDefault();

    const imageObj = {};

    if (galleryImage) {
      const gImage = new FormData();
      const fileName = galleryImage.name;

      gImage.append("name", fileName);
      gImage.append("file", galleryImage);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_HOSTURL}/api/v1/upload`,
          gImage
        );
        console.log("Gallery Image: ", res.data);
        imageObj.galleryImgPath = res.data.response;

        const gRes = await axios.post(
          `${process.env.REACT_APP_HOSTURL}/api/v1/admin/gallery`,
          {
            galleryImageUrl: imageObj.galleryImgPath,
          }
        );
        setUploadImgMsg(gRes.data.status);
        setGalleryImage("");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const galleryImageDelHandler = async (e) => {
    const id = e.currentTarget.id;

    try {
      // eslint-disable-next-line
      const res = await axios.delete(
        `${process.env.REACT_APP_HOSTURL}/api/v1/admin/gallery/${id}`
      );
      //console.log(res.data);

      const delImgGal = galleyImageStore.filter((d) => d._id !== id);

      setGalleryImageStore(delImgGal);
    } catch (err) {
      console.log("not deleted: ", err);
    }
  };

  const delAllGalImg = async () => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_HOSTURL}/api/v1/admin/gallery`
      );
      console.log(res.data);
      setGalleryImageStore([]);
    } catch (err) {
      console.log("Error: in Delete", err);
    }
  };

  const onSearch = async (value) => {
    let newValue;
    if (!isNaN(value)) {
      // If the input is a valid number, convert it to a number
      newValue = Number(value);
    } else {
      // If the input is not a valid number, keep it as a string
      newValue = value;
    }

    if (!newValue) {
      alert("Search field not be empty!");
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HOSTURL}/api/v1/searchform`,
        {
          title: newValue,
        }
      );
      console.log(res.data.body);
      setData(res.data.body);
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
    }
  };

  //! payment status update start

  //!updatepayment status

  const updateFormPaymentStatus = async (id, paymentstatus, paymentid) => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_HOSTURL}/api/v1/updatepayment`,
        {
          id: id,
          paymentstatus: paymentstatus,
          paymentid: paymentid,
        }
      );
      console.log("upate form status : ", res.data);
      if (res.data.status === "success") {
        navigate(`/successapplicationpdf/${res.data.body._id}`);
      }
    } catch (err) {
      console.log(err.response.data.message);
      alert("Something went wrong contact to company support!");
      window.location.reload();
    }
  };

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const makePayment = async (formId, email, phone) => {
    setLoading(true);

    // console.log("in make payement");

    const res = await initializeRazorpay();
    if (!res) {
      alert("Razorpay SDK Failed to load");
      return;
    }

    const data = await fetch(
      `${process.env.REACT_APP_HOSTURL}/api/v1/api/payment`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ price: 410 }),
      }
    ).then((t) => t.json());
    // console.log(data);
    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      name: "NIA Aviation Services Pvt Ltd.",
      currency: data.currency,
      amount: data.amount,
      order_id: data.id,
      description: "Payment for NIA",
      image: "https://niaaviationservices.com/images/logo.png",
      handler: function (response) {
        // console.log(response.razorpay_payment_id);
        // console.log(response.razorpay_order_id);
        // console.log(response.razorpay_signature);
        // console.log('after payment form id', formId)
        setLoading(true);
        //! Form payment status update after payment complete
        updateFormPaymentStatus(formId, "paid", response.razorpay_payment_id);

        //! Form submit after payment paid
        //  submitForm(response.razorpay_payment_id, "paid");
      },
      prefill: {
        name: "",
        email: email,
        contact: phone,
      },
      theme: {
        color: "#2D4B8E",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  //! payment status update end

  function paginationNextHandler() {
    setPage((page) => page + 1);
    setLimit((limit) => limit + 10);
    // if(page < 1){

    // }else{
    //   return
    // }
  }

  function paginationBackHandler() {
    setPage((page) => page - 1);
    setLimit((limit) => limit - 10);
  }

  const PublicFolder = `${process.env.REACT_APP_HOSTURL}/api/v1/`;
  return (
    <>
      <div className="admin-heading-div container">
        <div className="admin-header-div   p-3 mb-1  bg-light">
          <div className="reg-title-div">
            <h1>Admin Dashboard</h1>
          </div>
          <EditMode />
          <div>
            <Search
              placeholder="search with only mobile,email,examination centre state"
              enterButton="Search"
              size="large"
              //  suffix={suffix}
              onSearch={onSearch}
            />
          </div>
          <div className="admin-buttons">
            <div className="queries-div">
              <button className="contact-query-btn" onClick={queryHandler}>
                Contact Form Queries
              </button>
              {isNotificationUpdateUrl ? (
                <div
                  className="update-notification-url-div mt-3 shadow-sm"
                  style={{ backgroundColor: "white", padding: "10px" }}
                >
                  <div className="notification-url-close">
                    <MdOutlineCancel
                      id="notification-url-icon"
                      onClick={() => closeNotifucationUrl()}
                    />
                  </div>
                  <span>{notificationURLUpdated.message}</span>
                  <div className="mb-3">
                    <form onSubmit={updateHindiNotificationUrl}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <label htmlFor="">Update Hindi Notification Url</label>
                        <input
                          className="form-control mt-2 mb-2"
                          type="text"
                          placeholder="Enter Your NotificationUrl Here"
                          value={hindiNotificationUrl}
                          onChange={(e) =>
                            setHindiNotificationUrl(e.target.value)
                          }
                          required
                        />
                        <button type="submit" className="btn btn-warning">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="mb-3">
                    <form onSubmit={updateEnglishNotificationUrl}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <label htmlFor="">
                          Update English Notification Url
                        </label>
                        <input
                          className="form-control mt-2 mb-2"
                          type="text"
                          placeholder="Enter Your NotificationUrl Here"
                          value={engNotificationUrl}
                          onChange={(e) =>
                            setEngNotificationUrl(e.target.value)
                          }
                          required
                        />
                        <button type="submit" className="btn btn-warning">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <div className="mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => enableNotificationUrlUpdate()}
                  >
                    Update Notification URL
                  </button>
                </div>
              )}
            </div>

            <div className="adm-btn">
              {allFormsData.length === 0 ? (
                <button
                  onClick={fetchAllFormsDataCSV}
                  disabled={csvLoading}
                  style={{
                    padding: "8px",
                    borderRadius: "4px",
                    backgroundColor: "teal",
                    color: "white",
                  }}
                >
                  {csvLoading ? "Processing..." : "Load CSV Data"}
                </button>
              ) : (
                <>
                  <ExportJsonCsv
                    style={{
                      padding: "8px",
                      borderRadius: "4px",
                      backgroundColor: "teal",
                      color: "white",
                    }}
                    headers={headers}
                    items={datas}
                  >
                    Click To Download
                  </ExportJsonCsv>
                </>
              )}

              <button className="admin-logout" onClick={logoutAdmin}>
                Admin Logout
              </button>
            </div>
          </div>
          {allqueries ? null : (
            <div className="pages-btn-div">
              <div>
                <button
                  type="button"
                  className="btn btn-secondary position-relative"
                >
                  Total Forms
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {totalPaidCount}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </button>
              </div>
              {formsPaymentStatus === "getonlypaidform" ? (
                <button
                  className="btn btn-danger"
                  onClick={() => updateFormStatus("getonlynotpaidform")}
                >
                  Not Paid Candidates
                </button>
              ) : (
                <button
                  className="btn btn-success"
                  onClick={() => updateFormStatus("getonlypaidform")}
                >
                  Paid Candidates
                </button>
              )}
              <ul className="pageNumbers">
                <li>
                  <button
                    className="btn btn-warning"
                    onClick={paginationBackHandler}
                    //  disabled={currentPage === pages[1] ? true : false}
                    disabled={page === 1}
                  >
                    PREV
                  </button>
                </li>
                {/* {renderPageNumber}
                {increamentBtn} */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  {page}
                </div>
                <li>
                  <button
                    className="btn btn-warning"
                    onClick={paginationNextHandler}
                    // disabled={
                    //   currentPage === pages[pages.length - 1] ? true : false
                    // }
                  >
                    NEXT
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>

        {loading ? <h4>Loading...</h4> : null}

        <div className="table-div table">
          {allqueries ? (
            <div className="contact-query-div">
              <div className="go-back-div">
                <button className="go-back" onClick={goBackHandler}>
                  Go Back
                </button>
              </div>
              <QueryPage />
            </div>
          ) : (
            <table className="table table-striped table-hover table-bordered">
              <tbody>
                <tr className="table-info sticky-top">
                  <th>Registration Number</th>
                  <th>Candidate Name</th>
                  <th>Father/Husband Name</th>
                  <th>Mother Name</th>
                  <th>D.O.B</th>
                  <th>E-mail</th>
                  <th>Phone</th>
                  <th>Apply For</th>
                  <th>Application Fees</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Payment Status</th>
                  <th>More...</th>
                </tr>
                {currentItems?.map((d) => {
                  return (
                    <tr key={d._id}>
                      <td>{d.registrationNumber}</td>
                      <td>{d.candidatename}</td>
                      <td>{d.fatherhusbandname}</td>
                      <td>{d.mothername}</td>
                      <td>{d.dateofbirth}</td>
                      <td>{d.email}</td>
                      <td>{d.mobile}</td>
                      <td>{d.postapply}</td>
                      <td>{d.price}</td>
                      <td>{new Date(d?.createdAt).toDateString()}</td>
                      <td>{new Date(d?.createdAt).toLocaleTimeString()}</td>
                      <td>{d.paymentStatus}</td>
                      <td>
                        <button
                          className="btn btn-secondary"
                          onClick={getSingleFromData}
                          id={d._id}
                        >
                          View
                        </button>
                        {d.paymentStatus === "notpaid" && (
                          <button
                            className="btn btn-danger my-2"
                            onClick={() =>
                              makePayment(d._id, d.email, d.mobile)
                            }
                          >
                            Pay
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="allRegidstered-div mt-4 mb-4">
        <RegisteredUsers />
      </div>
      <div className="mt-4 mb-4 bg-light">
        <NotificationAlerts />
      </div>
      <div className="gallery-main-div container border border-3 p-3 shadow-sm">
        <h1 className="text-muted fs-2 ">
          <u>Gallery Section</u>
        </h1>
        <div className="mb-5 mt-5">
          <label className="gallery-upload-icon-bg" htmlFor="gallery-upload">
            <MdFileUpload className="gallery-upload-icon" />
          </label>
          <img
            src={
              galleryImage
                ? URL.createObjectURL(galleryImage)
                : "/images/dummyimage.jpeg"
            }
            width={100}
            height={80}
            alt=""
          />
          <input
            accept=".jpg, .png, .jpeg"
            className="gallery-upload-input"
            type="file"
            id="gallery-upload"
            onChange={(e) => setGalleryImage(e.target.files[0])}
          />

          <button className="ms-3 btn btn-warning" onClick={galleryUpload}>
            Upload Image
          </button>
        </div>
        <div>
          <p>{uploadImgMsg}</p>
          <span onClick={delAllGalImg} className="p-5">
            Delete All Images
            <MdDeleteForever className="galleryImgDelIcon" />
          </span>
        </div>

        <div className="gallery-image-list  border">
          {galleyImageStore?.map((d) => {
            return (
              <div className="galerry-list" key={d._id}>
                <img
                  src={PublicFolder + d.galleryImageUrl}
                  width={100}
                  height={80}
                  alt=""
                />
                <span onClick={galleryImageDelHandler} id={d._id}>
                  <MdOutlineDeleteOutline className="galleryImgDelIcon" />
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Admin;
