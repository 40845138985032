import React from 'react'
import {Helmet} from 'react-helmet';
import ContactForm from '../ContactForm';
const About = () => {
  return (
   <>
     <Helmet>
      <meta charSet='UTF-8'/>
      <title>NIA Aviation Services</title>
      <meta name='description' content='The NIA Aviation Services Pvt. Ltd. organisation is run by a team of specialists with extensive combined experience in the aviation sector. We prepare students for promising jobs in the hospitality, tourism, and aviation industries. NIA Aviation Services Pvt. Ltd. was founded with the intention of offering our customers well-trained professionals to manage passenger, ramp, and cargo activities in India. We provide dependable service while paying close attention to the unique needs of our clientele. The aviation industry is making every effort to improve the fit between the supply and demand for job openings. For the purpose of hiring people for various services and positions, NIA Aviation Services Pvt. Ltd. administers training courses and exams. After successfully completing the training programme, candidates from NIA Aviation Services Pvt. Ltd. were also hired by other airports across the nation. We provide certificate programmes that are focused on getting you a career in the highly sought-after aviation and tourism sector.' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
    </Helmet>

      <div className='container-fluid  top-banner-pg-div-about-us'>
          <div className='inner-pg-wrapper-div'>
              <div className='container ot-page-banner-heading'>
                  <h1>About Us</h1>
                  <p>Home / about us</p>
              </div>
          </div>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/about/1.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/about/2.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>NIA Aviation Services Pvt. Ltd.</h3>
              <p>The NIA Aviation Services Pvt. Ltd. organisation is run by a team of specialists with extensive combined experience in the aviation sector. We prepare students for promising jobs in the hospitality, tourism, and aviation industries. NIA Aviation Services Pvt. Ltd. was founded with the intention of offering our customers well-trained professionals to manage passenger, ramp, and cargo activities in India. We provide dependable service while paying close attention to the unique needs of our clientele. The aviation industry is making every effort to improve the fit between the supply and demand for job openings.
               <br />
               <br />
               For the purpose of hiring people for various services and positions, NIA Aviation Services Pvt. Ltd. administers training courses and exams. After successfully completing the training programme, candidates from NIA Aviation Services Pvt. Ltd. were also hired by other airports across the nation. We provide certificate programmes that are focused on getting you a career in the highly sought-after aviation and tourism sector.
            
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container ot-page-cnt-form-div'>
        <div className='row'>
          <div className='col-lg-7'>
            <div className='ot-page-cnt-leftidv'>
            {/* <h3>Put Heading Here...</h3> */}
              <p>The NIA Aviation Services Pvt. Ltd. offers extensive training in foundational skills, aviation operations, aviation retail, emergency preparedness, and aviation airline management, including Air India, Indigo, and many other airline operations, to its students enrolled in airport operations programmes. We take great pride in providing education outside of the walls of the classroom. Our students acquire the technical know-how, practical experience, and problem-solving abilities necessary to embrace complexity and drive innovation in a workforce that is undergoing fast change. 
               <br />
               <br />
               Our network of alumni and partners continues to achieve global success through tight industry collaboration. It maintains a competitive advantage in the business thanks to state-of-the-art infrastructure, teachers who are highly qualified and professionals, and a staff that includes some of the most seasoned personnel in the aviation industry. Our operations centres are conveniently located in Delhi, and we are passionate about cutting-edge innovation.
              
               </p>
            </div>
          </div>
          <div className='col-lg-5'>
            <div className='ot-page-cnt-rightidv border'>
              <ContactForm className='ot-pg-form-right'/>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img-div-abt'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
         
          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right-btm'>
             <div className='ot-pg-put-right-top-cnt'>
              {/* <h3>Put Heading Here...</h3> */}
              <p>a track record of providing the best services to airports and airlines across India. We as a company take on all liabilities and render quick, competent service. Our services include everything from risk assessment and planning to requirements for integrated facility services and the supply and management of people. 
               <br />
               <br />
               We also provide solutions that are individually designed to satisfy the needs of our clients. serves 35 different clients at All Over Airport for occupations like as passenger operations, ground crew, loaders and cleaners, customer sales executives, and guest service associates, among others, and offers training and workforce to the aviation industry.
               </p>
               <br />
               <h3>Why NIA Aviation Services.. ?</h3>
               <h3>Our Commitments</h3>
               <p>The mission of NIA Aviation Services Pvt. Ltd. is to offer candidates and companies alike the best services in the world. Every day, NIA Aviation Services Pvt.Ltd. puts a lot of effort into creating bridges of loyalty and trust. We work with you to guarantee that our services are tailored to your needs in an effort to give you this through personal responsibility and professional devotion. We think that our clients should come first and that you have a right to expect top-notch support from us.</p>
               <br/>
               <p>We put a lot of effort into providing a helpful and timely service because we believe that everyone deserves to be respected. Our commitment is to giving customers first-rate tourist experiences and helping job seekers advance their professional careers.</p>
           
               
             </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left-btm'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/about/3.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/about/4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img2-div-abt'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/about/5.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/about/6.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3> Our Mission</h3>
             
              <p>
              Ground Staff - Customer Service Agent people are hired and trained by NIA Aviation Services Pvt. Ltd. for lucrative jobs in the aviation sector. Our goal is to make a difference in the organisations we work with and to help job seekers be successful.We support the delivery of excellent guest experiences, higher business income, and safety by providing individuals who are highly qualified and passionate about service.
We improve our clients' reputations and foster employee loyalty by making the hiring process dependable, fast, and transparent.
               <br />
               <br />
               <b> Our Vision</b>
              
               <br />
               <br />
               By creating the finest network among our clients, including companies and students, we aim to be an inspirational firm in the aviation industry. As a business, we wish to use the aviation industry as a platform to offer our clients the best solutions. In order for them to succeed, we want to educate more and more students. Since that aviation serves as a catalyst for a nation's development, we will exert all of our effort to actively contribute to upholding the industry's high standards for training and human resources.

               <br/>
               <br/>
               <b>Faculty</b>
               <br/>
               <p>The founder of NIA Aviation Services Pvt.Ltd. possesses the vision and expertise necessary to achieve the organization's objectives. Their leadership is put into practise to guarantee that every level of the organisational structure operates smoothly in order to produce an effective and efficient outcome. They assess each batch of applicants personally in order to comprehend both their strengths and weaknesses. As a result, during the training period, the candidate's performances are greatly enhanced and improvised.
Being led by a highly knowledgeable and experienced corporate sector with a background in the travel, hospitality, and aviation sectors is a luxury.
The majority of candidates chose this organisation to begin their careers in aviation services because of their years of expertise and exposure in locations like reputable worldwide and domestic airlines, which have made them irreplaceable.
The company's great reputation is due in large part to the pioneers' admirable ethics, which helped lay the groundwork for the organization's founding. This also enables them to do a great job of inspiring new hires, and such a desirable character later reflects in the candidate's performances that further the objectives of the organisation.</p>
            
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>
   </>
  )
}

export default About