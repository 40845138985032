import React from 'react'
import './newHome.css'
import HmCrousel from './HmCrousel'
import UpperBnr from './UpperBnr'
import OurPolicyButton from './OurPolicyButton'
import GovImgGal from './GovImgGal'
import Notification from './Notification'
import LatestUpdateAlert from './LatestUpdateAlert'
import QuerySection from './QuerySection'
import Modal from "react-modal";
import AirlineLogo from './AirlineLogo'

const NewHomeDesign = () => {
  Modal.setAppElement("#root");
  return (
    <>
    <div className='nw-hm-main-conatainer'>
      <HmCrousel/>
      <UpperBnr/>
      <OurPolicyButton/> 
      <GovImgGal/>
      <Notification/>
      <LatestUpdateAlert/>
      <QuerySection/>
      <AirlineLogo/> 

      <Modal
       isOpen={false}
      >
        <div className="warn-main-containe" style={{textAlign: 'center'}}>
         <img style={{
          width:'20%'
         }} src="/images/warning.png" alt="" />
         <h1 style={{color: 'tomato'}}>Under Maintenance</h1>
         <h3>Due to some technical glitch, this website is currently under maintenance. We are sorry for the inconvenience caused to you. We will make this website live again soon after technical improvements are made. Thank you</h3>
        </div>
        <br />
        <h3>कुछ तकनीकी खराबी के कारण यह वेबसाइट अभी रखरखाव में है। आपको हुई असुविधा के लिए हमें खेद है। तकनीकी सुधार होने के बाद हम जल्द ही इस वेबसाइट को फिर से लाइव कर देंगे। धन्यवाद</h3>
         <br />
        <p style={{textAlign: 'center'}}>If you have any questions or need further assistance, please don't hesitate to contact our support team.</p>
        <p style={{textAlign: 'center'}}>[+91 99-908090-56]</p>
        <p style={{textAlign: 'center'}}>[+91 99-908090-76]</p>
        
      </Modal>
    </div>


    </>
  )
}

export default NewHomeDesign