import React from 'react'
import './newFooter.css'
import { Link } from "react-router-dom";
import { IoLogoFacebook ,IoLogoInstagram} from "react-icons/io";

const NewFooter = () => {
  return (
   <>
    <div className='footer-main-container d-print-none'>
        <div className='container'>
          <div className='footer-inr-wrapper'>
              <div className='footer-inr-left-div'>
                <div className='footer-inr-links-div'>
                  <div className='ftr-quick-links'>
                   <h5>Quick Link</h5>
                   <div className='qc-links-dv'>
                      <ul>
                        <li><Link to={'/aboutnia'}>About Us</Link></li>
                        <li><Link to={'/groundstaffs'}>Ground staff</Link></li>
                        <li><Link to={'/becamegroundstaff'}>Become cabin Crew</Link></li>
                        <li><Link to={'/flightattandent'}>Flight attendant</Link></li>
                        <li><Link to={'/trainingoverviews'}>training overview</Link></li>
                        <li><Link to={'/professionaltraining'}>professional Training</Link></li>
                        <li><Link to={'/aptitudeskills'}>Aptitudes Skills</Link></li>
                      </ul>
                   </div>
                  </div>
                  <div className='more-links-inr-div'>
                  <h5>More Links</h5>
                  <div className='mr-lin-btm-div'>
                  <ul>
                        <li><Link to={'/housekeeping'}>House Keeping</Link></li>
                        <li><Link to={'/wheelchairstaff'}>wheel Chair Staff</Link></li>
                        <li><Link to={'/eligibilityexamforaviation'}>Eligibility & Entrance Exam</Link></li>
                        <li><Link to={'/becamegroundstaff'}>Become Ground Staff</Link></li>
                        <li><Link to={'/careeratairports'}>Career At Airport</Link></li>
                      </ul>
                  </div>
                  </div>
                  <div className='company-policy-links'>
                    <h5>Company Policy</h5>
                    <div className='poli-list-ftr-inr-div'>
                      <ul>
                        <li><Link to={'/privacyrefund'}>Privacy/Refund/Cancellation Policy</Link></li>
                        <li><Link to={'/termcondition'}>Term & Condition</Link></li>
                        <li><Link to={'/privacypolicy'}>Privacy Policy</Link></li>
                        <li><Link to={'/refundpolicy'}>Refund Policy</Link></li>
                        <li><Link to={'/shippingdelivery'}>Shipping & Delivery</Link></li>
                        <li><Link to={'/shippingdelivery'}>Pricing</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className='footer-right-inr-div'>
                <div className='foter-right-div-img-dv'>
                  <img id='qr-img' src="/images/qr.png" alt="" />
                  <img id='logo-ftr-img' src="/images/logo.png" alt="" />
                </div>

                  <div className='copy-right-divftr'>
                  <p>Copyright © [2023] NIA Aviation Services Pvt Ltd. All rights reserved.</p>
                  </div>

                  <div className='social-icons-div'>
                    <h5>Follow us on:</h5>
                    <div className='icons-ftr-social-dv'>
                      <a href="https://www.facebook.com/profile.php?id=100090318101319">
                      <IoLogoFacebook className='scl-icon'/>
                      </a>
                      <a href="https://www.instagram.com/niaaviationservices/">
                      <IoLogoInstagram className='scl-icon'/>
                      </a>
                    </div>
                  </div>

              </div>
          </div>
        </div>
    </div>
   </>
  )
}

export default NewFooter