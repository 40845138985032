import React, { useEffect, useRef, useState }  from 'react'
import './result.css'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import jsPDF from "jspdf";
import html2canvas from "html2canvas";


const ResultData = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;

  //console.log(data)

  const result = 'pass'
  //const [imagesLoaded, setImagesLoaded] = useState(false);
  const [download, setDownload] = useState(false)


  //! download pdf start 

  const elementToCaptureRef = useRef(null);

//   const handleImageLoad = () => {
//     setImagesLoaded(true);
//   };
 
//   const handleImageError = (event) => {
//    event.target.src = '/images/white.png';
//  };
  const captureScreenshot = async () => {
    setDownload(true);
    // if (!imagesLoaded) {
    //   alert('Images are still loading. Wait for all images to load before downloading the admit card');
    //   return;
    // }
  
    const element = elementToCaptureRef.current;
    const canvas = await html2canvas(element, { scrollY: -window.scrollY, useCORS: true, allowTaint: true });
    const imgData = canvas.toDataURL('image/png');
  
    const pdf = new jsPDF({
      unit: 'mm',
      format: 'a4',
      orientation: 'portrait',
      compress: true,
      hotfixes: [], // Add any required hotfixes
    });
  
    const imgWidth = 210; // A4 width in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
  
    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= 310; // A4 height in mm
  
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= 310;
    }
  
    pdf.save('NIA_admitcard.pdf');
    setDownload(false);
  };
  //! download pdf end


  useEffect(()=>{
    if(data === null){
      navigate('/result-search')
      return
     }
  },[data, navigate])

  const convertDate = (dateString) => {
    
    const [day, month, year] = dateString.split('/');
    const fullYear = parseInt(year, 10) < 50 ? `20${year}` : `19${year}`; // Adjust year logic as per your requirement
    return `${day}/${month}/${fullYear}`;
  };

  
  
  return (
    <>
     <div className='container-lg main-result-data-wrapper' ref={elementToCaptureRef} id="element-to-capture">
     <div className="card">
     <div className='wrapper'>
      <div className="header">
        <h5 style={{marginBottom: '5px'}}>NIA Aviation Services Pvt. Ltd.</h5>
        <h6 style={{marginBottom: '5px'}}>एनआईए एविएशन सर्विसेज प्रा. लि.</h6>
        <h6 className='small-txt' style={{marginBottom: '10px'}}>B3, 3rd Floor, Nawada Housing Complex, Dwarka Mor, Near Pillar No. 787, New Delhi-110078</h6>
        <h6 className='small-txt' style={{marginBottom: '15px'}}><u>The result has been declared for CUSTOMER SERVICES ASSOCIATE (CSA).</u></h6>
      </div>
      <div className="info">
        <p className='small-txt'>ADVERTISEMENT NO.-01/NIA-CSA/2023-24</p>
        <div className="info-row-container name-cat-tbl">
          <div className="info-row">
            <div className='small-txt'><strong>Candidate Name:</strong></div>
            <div className='small-txt'>{`${data?.firstname} ${data?.lastname}`}</div>
          </div>
          <div className="info-row">
            <div className='small-txt'><strong>Father Name:</strong></div>
            <div className='small-txt'>{data?.fathername}</div>
          </div>
          <div className="info-row">
            <div className='small-txt'><strong>Category:</strong></div>
            <div className='small-txt'>{data?.category}</div>
          </div>
        </div>
        
        <div className="info-row-container rol-reg-div">
          <div className="info-row">
            <div className='small-txt'><strong>Roll No.:</strong></div>
            <div className='small-txt'>{data?.rollno}</div>
          </div>
          <div className="info-row">
            <div className='small-txt'><strong>Application No.:</strong></div>
            <div className='small-txt'>{data?.applicationno}</div>
          </div>
          <div className="info-row">
            <div className='small-txt'><strong>Date of Birth:</strong></div>
            <div className='small-txt'>{convertDate(data?.dob)}</div>
          </div>
        </div>
      </div>
      <table className="marks-table">
        <thead>
          <tr>
            <th>Result</th>
            <th style={data?.result === 'pass' ? {color: 'green', textTransform: 'capitalize', fontSize: '16px'} : {color: 'red',textTransform: 'capitalize'}}><strong>{data?.result}</strong></th>
            {/* <th>Marks Secured</th> */}
          </tr>
          {/* <tr>
            <th>Interview Promotion</th>
            <th style={result === 'pass' ? {color: 'green'} : {color: 'red'}}>-</th>
          
          </tr> */}
        </thead>
        {/* <tbody>
          <tr>
            <td>English Language</td>
            <td>30</td>
            <td>{data?.marks.english}</td>
          </tr>
          <tr>
            <td>Quantitative Aptitude</td>
            <td>35</td>
            <td>{data?.marks.quantitative}</td>
          </tr>
          <tr>
            <td>Reasoning Ability</td>
            <td>35</td>
            <td>{data?.marks.reasoning}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>100</td>
            <td>{data?.marks.total}</td>
          </tr>
        </tbody> */}
      </table>
      <div className="status">
        <h6 ><strong>Candidate Status:</strong></h6>
        <h6 className='blink'>
        <strong style={data?.result === 'pass' ? {color: 'green'} : {color: 'tomato'}}> {data?.result === "pass" ? "Congratulations! You are qualified for interview" : "You are not qualified for interview"}</strong>
        </h6>
      </div>

      <div>
      <table className="marks-table">
      <thead>
        <tr>
        <th>Note: </th>
        <th style={{textAlign: 'justify',}} className=''>
          <ol style={{margin: '10px', fontSize: '12px'}}>
            <li className='mb-3'>You will receive an official Call Letter in your email inbox within 7 working days if your status indicates that you are qualified for an interview. Details regarding the date, time, and location of the interview will be included in this letter. Make sure you attend the interview by adhering to the guidelines outlined in the call letter.<br/>यदि आपकी स्थिति इंगित करती है कि आप इंटरव्यू के लिए क्वालिफाइड हैं, तो आपको 7 कार्य दिवसों के भीतर अपने ईमेल इनबॉक्स में एक आधिकारिक कॉल लेटर प्राप्त होगा। इस पत्र में इंटरव्यू की तारीख, समय और स्थान के बारे में विवरण शामिल किया जाएगा। सुनिश्चित करें कि आप कॉल लेटर में उल्लिखित दिशानिर्देशों का पालन करके इंटरव्यू में भाग लें।</li>
            <li>If you haven't received the Call Letter within 7 working days, please reach out to us through our official helpline numbers <strong>(+91 99-908090-76 ,+91 99-908090-56)</strong> or by sending us an email <strong>info@niaaviationservices.com</strong> regarding this matter. <br /> यदि आपको 7 कार्य दिवसों के भीतर कॉल लेटर प्राप्त नहीं हुआ है, तो कृपया इस संबंध में हमारे आधिकारिक हेल्पलाइन नंबरों <strong>(+91 99-908090-76,+91 99-908090-56)</strong> के माध्यम से या हमें <strong>info@niaaviationservices.com</strong> पर एक ईमेल भेजकर हमसे संपर्क करें।</li>
          </ol>
        </th>
        </tr>
      </thead>
      </table>
      </div>

      {/* <div>
        <ol className='intruction-list'>
          <li>Please watch your email inbox for the Call Letter, which you should receive within 7 working days. Reading and following the instructions carefully is important to ensure a successful interview process. Your cooperation is highly valued. <br /> कृपया कॉल लेटर के लिए अपने ईमेल इनबॉक्स पर नज़र रखें, जो आपको 7 कार्य दिवसों के भीतर प्राप्त हो जाना चाहिए। सफल साक्षात्कार प्रक्रिया सुनिश्चित करने के लिए निर्देशों को ध्यान से पढ़ना और उनका पालन करना महत्वपूर्ण है। आपका सहयोग अत्यधिक मूल्यवान है.</li>

          <li>
          If your Qualification Status shows <strong>'Not Qualified'</strong> but your Interview Promotion Status shows <strong>'Promoted for Interview'</strong>, you will receive a call letter in your official email within 7 working days.
          <br />
          यदि आपकी योग्यता स्थिति <strong>'नॉट क्वालिफाइड'</strong> दिखाती है, लेकिन आपके साक्षात्कार पदोन्नति स्थिति <strong>'प्रमोटेड फॉर इंटरव्यू'</strong> दिखाती है, तो आपको 7 कार्य दिवसों के भीतर अपने आधिकारिक ईमेल में एक कॉल लेटर प्राप्त होगा।
          </li>

         
        </ol>
      </div> */}
      </div>
    </div>
     </div>

     <div className="d-print-none  print-application-div">
                <button
                  className="btn btn-warning small-txt"
                  onClick={() => window.print()}
                >
                  Print Result or Save as PDF
                </button>
                {/* <button className="btn btn-warning ms-3" onClick={captureScreenshot}>{download? 'Downloading...': 'Download PDF'}</button> */}
              </div>

    </>
  )
}

export default ResultData
