import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const ForgetPassword = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [number, setNumber] = useState('')

  const foregetFunc = async (e) => {

    e.preventDefault()

    setEmail("");

    if (email === "" || !email) {
      setError(true);
    } else {
      try {
        setLoading(true);

        const res = await axios.post(
          `${process.env.REACT_APP_HOSTURL}/api/v1/forget`,
          {
            email,
          }
        );
       // console.log(res.data);

        setError(false);
        setMsg(res.data);
        setEmail("");
        setLoading(false);

        if(res.data.status === 200){
          setTimeout(()=>{
           navigate('/loginuser')
          },1500)
         }

      } catch (err) {
       // console.log("forget password error: ", err);
        setLoading(false);
      }
    }
  };

  const sendUserDetailsHandler = async(e) =>{
    e.preventDefault()
    try{
      setMsg('')
      setLoading(true)
  
      if(number === '' || !number){
        setError(true);
  
    }else{
      const res  = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/sendsmsdetails`,{
        number
      })
  
      //console.log(res.data)
      setError(false);
      setMsg(res.data)
      setLoading(false)
      setEmail('')
  
      if(res.data.status === 200){
       setTimeout(()=>{
        navigate('/loginuser')
       },1500)
      }
  
    }
  
    }catch(err){
      console.log(err)
    }
  }

  return (
    <div>
      <div style={{ textAlign: "center", paddingTop: "70px" }}>
        <form onSubmit={foregetFunc}>
        <div style={{ color: "red", marginBottom: "10px" }}>
          {error && <span>Input field can not be empty</span>}
        </div>

        <label className="fw-bold text-muted fs-5">
          Enter Your  Email
        </label>
        <div
          className="mt-3 mb-3  w-100"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="container w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <input
              className="form-control "
              type="email"
              placeholder="Enter Your Registered Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
            <button
             type="submit"
              className="btn btn-warning ms-4"
              disabled={loading ? true : false}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
        </form>

        <form onSubmit={sendUserDetailsHandler}>
        <div style={{ color: "red", marginBottom: "10px" }}>
          {error && <span>Input field can not be empty</span>}
        </div>

        <label className="fw-bold text-muted fs-5">
        Enter Your Number
        </label>
        <div
          className="mt-3 mb-3  w-100"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="container w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <input
              className="form-control "
              type="number"
              placeholder="Enter Your Registered Mobile Number"
              onChange={(e) => setNumber(e.target.value)}
              value={number}
              required
            />
            <button
             type="submit"
              className="btn btn-warning ms-4"
              disabled={loading ? true : false}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
        </form>

        <div>
          {msg.status === 200 ? (
            <span style={{ color: "gray", marginTop: "10px" }}>
              <b>{msg.message}</b>
            </span>
          ) : (
            <span style={{ color: "red", marginTop: "10px" }}>
              <b>{msg.message}</b>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
