import axios from "axios"

export async function getResult(data){
    try{
        const res = await axios.post(`${process.env.REACT_APP_HOSTURL}/api/v1/findresult`, data)
        if(res.data.status === 'success') return res.data
    }catch(err){
        //console.log(err)
        throw new Error(err.response.data.message)
    }
}