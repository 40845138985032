import React from 'react'
import {Link} from 'react-router-dom'

const OurPolicyButton = () => {
  return (
   <div className='amrit-pocily-maindov'>
        <div className='container'>
          <div className='policy-imgs-tab-inr-wrapper'>
            <Link to={'/privacyrefund'}>
          <div className='ourpolicy-img-dv'>
                <img src="/images/ourpolicy.png" alt="ourpolicy" />
            </div>
            </Link>
            <div className='75amrit-img-div s75amrit-img-div'>
            <img src="/images/75amrit-tab.png" alt="amrit" />
            </div>
          </div>
        </div>
   </div>
  )
}

export default OurPolicyButton