import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Candidate = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const userExist = useSelector((state)=> state.user.user)

 // console.log(userExist)
  const {id} = userExist

  let getLoggedinuser = localStorage.getItem("userinfo");

  let user = JSON.parse(getLoggedinuser);

 
  // const getData = async (id) => {
  //   console.log(id)
  //   await axios
  //     .get(`${process.env.REACT_APP_HOSTURL}/api/v1/userdash/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       setData(res.data);
  //     })
  //     .catch((err) => {
  //       console.log("error", err);
  //     });
  // };

  const getData = useCallback(async()=>{
    setLoading(true)
    try{

      const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/userdash/${id}`)
      setData(res.data.response)
      setLoading(false)
    }catch(err){
      console.log(err)
    }
  },[id])

  useEffect(() => {
    getData(id);
    // eslint-disable-next-line
  }, [getData,id]);

  const getSingleFromData = (e) => {
    console.log(e.target.id);

    let listId = e.target.id;

    if (listId) {
      const id = listId;
      navigate(`/viewcandidatesdetails/${id}`, {
        state: {
          id: id,
        },
      });
    }
    console.log("list-ID", listId);
  };

  //filter only paid user forms
  const paidUsers = data?.filter(user => user.paymentStatus === 'paid');
   
if(loading){
  return <div className="container text-center my-5">
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <p className="text-muted"><b>Loading...</b></p>
  </div>
}

  return (
    <>
      <div className="container candidate-main-div">
        <h3>Your Submitted Applications Forms</h3>
        <div className="container-inner-div">
          <div className="candidate-form-table-div">
            <table className="table table-light table-hover shadow-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>D.O.B</th>
                  <th>Father</th>
                  <th>Apply For</th>
                  <th>Payment Status</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {paidUsers?.map((d) => {
                  return (
                    <tr key={d?._id}>
                      <td>{d?.candidatename}</td>
                      <td>{d?.dateofbirth}</td>
                      <td>{d?.fatherhusbandname}</td>
                      <td>{d?.postapply}</td>
                      <td>{d?.paymentStatus}</td>
                      <td>
                        <button
                          className="btn btn-warning"
                          onClick={getSingleFromData}
                          id={d?._id}
                        >
                          Print
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Candidate;
