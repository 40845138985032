import React from 'react'
import {Helmet} from 'react-helmet';
const ProfessionalTraining = () => {
  return (
    <>
       <Helmet>
      <meta charSet='UTF-8'/>
      <title>Professional Trainer</title>
      <meta name='description' content='paste your same description which is used on the same page' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
    </Helmet>

      <div className='container-fluid  top-banner-pg-div'>
          <div className='inner-pg-wrapper-div'>
              <div className='container ot-page-banner-heading'>
                  <h1>Professional Trainer</h1>
                  <p>Home / professional</p>
              </div>
          </div>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/professionaltrainer/1.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/professionaltrainer/3.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Learner- cantered approach</h3>
              <p><b>96% of participants agree that our instructors are experts in their fields.</b></p>
              <p>We work hard to create trainings that encourage active learning and prevent the learner from absorbing the training material passively. We promote ongoing and frequent interactions between the instructor and students, as well as interactions between students and the course material.
               <br />
               <br />
               To maximise learning outcomes and provide practical results, we encourage participant self-responsibility and, if practical, we encourage participants to include their personal learning objectives in the course activities. This gives students several chances to interact with the material through experience, observation, conceptualization, and experimentation.
            
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container ot-page-cnt-form-div'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='ot-page-cnt-leftidv'>
            {/* <h3>Put Heading Here...</h3> */}
              <p>Training that is applicable and useful for career advancement 
               <br />
               <br />
               In order to achieve actual business results, we teach specialists. Our courses are created by professionals in the field to give candidates knowledge they can use right away.
              
               </p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/professionaltrainer/6.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/professionaltrainer/7.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  )
}

export default ProfessionalTraining