import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./css/register.css";
import { useDispatch, useSelector } from "react-redux";
import {login} from '../store/userSlice'

import {Helmet} from 'react-helmet';


const Register = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const userData = useSelector((state)=> state.user.user)



  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [require, setRequire] = useState(false);

  const [loading, setLoading] = useState(false);

  const registeForm = async (e) => {

    e.preventDefault()
    if (name === "" || email === "" || phone === "" || password === "") {
      setRequire(true);
      return;
    } else {
      try {
        setLoading(true);
        const res = await axios.post(
          `${process.env.REACT_APP_HOSTURL}/api/v1/userregistration`,
          {
            name: name,
            email: email,
            phone: phone,
            password: password,
          }
        ); 
        //console.log("this is response from backend", res.data);
        if (res.data.status === 201) {
          // navigate("/login");
          const obj = {
            id: res.data.body._id,
            name: res.data.body.name,
            registrationnumber: res.data.body.registrationnumber,
          };
          dispatch(login(obj))
          

          setLoading(false);
        } else if (res.data.status === 500) {
          setError(true);
          setRequire(false);
          setLoading(false);
        }
      } catch (err) {
       // console.log("error in sending file ", err);
        setError(true);
        setLoading(false);
      }
    }
  };


  useEffect(()=>{
    if(!userData){
      return
    }else {
      navigate('/application-form')
    }
  },[userData,navigate])
 
 

  return (
    <>
       <Helmet>
      <meta charSet='UTF-8'/> 
      <meta name='description' content='paste your same description which is used on the same page' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
      <script>
      {`
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
              "gtm.start": new Date().getTime(),
              event: "gtm.js",
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-5WP56CFD");
        `}
      </script>
    </Helmet>
    <div className="register-container" style={{ textAlign: "center" }}>
      {loading ? (
        <div className="loading-div  loading-register-page">
          <img src="/images/loading.svg" alt="aloding" />
          <h5>Wait We're Register Your Account !!</h5>
        </div>
      ) : null}

      <form onSubmit={registeForm}>
      <div className="register-inner-div shadow">
        <h1 className="mainheader">Create New Account</h1>
        <div className="name_div ">
          <div style={{ color: "red", marginBottom: "10px", marginTop:"10px"}}>
            {require ? (
              <span>All fields required</span>
            ) : (
              error && <span >Email Already Registered</span>
            )}
          </div>
         
          <label className="form-name">Name :</label>
          <input className="inputbox"
            type="text"
            placeholder="Enter your Full Name"
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="email_div">
          <label className="form-name">E-mail :</label>
          <input className="inputbox"
            type="email"
            placeholder="Enter your E-mail"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="phone_div">
          <label className="form-name">Phone :</label>
          <input className="inputbox"
            type="number"
            placeholder="Enter your phone no"
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="password_div">
          <label className="form-name">Password :</label>
          <input className="inputbox"  type="date" onChange={(e) => setPassword(e.target.value)} required />
        </div>

        <div className="submit-div">
          <button 
          type="submit"
            className="button"
            disabled={loading ? true : false}
          >
            Register Now
          </button>
          <Link className="text"  to={"/loginuser"}>Already Registered ?</Link>
        </div>
      </div>
      </form>
    </div>

    
    </>
  );
};

export default Register;
