import React from 'react'

const Error = () => {
  return (
    <div>
        <div className='mt-5 mb-5'>
            <div className="alert alert-warning text-center" role="alert">
            <h1>404 Page Not Found</h1>
            </div>
        </div>
    </div>
  )
}

export default Error