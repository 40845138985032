import React from 'react'

const AirlineLogo = () => {
  return (
    <>
        <div className='airlineo-loig-main-ciontainer'>
            <div className='container'>
               <div className='airline-log-inr-wrapper'>
               <div className='digitalera-container'>
                    <h1>WARNING :</h1>
                    <p>Beware of touts, otherwise the candidates themselves will be
                responsible.<br/> ( दलालों से सावधान, अन्यथा अभ्यर्थी स्वयं जिम्मेदार
                होंगे। )</p>
                </div> 
                <div className='airline-logos-container-div'>
                   <div className='airline-logo-airlogo-div'>
                    <div className="marquee-container">

                    <div className='img-airline-dv'>
                    <img src="/images/1ai-logo.png" alt="" />
                    <p>AIR INDIA</p>
                    </div>
                    <div className='img-airline-dv'>
                    <img src="/images/2indilogo.png" alt="" />
                    <p>INDIGO</p>
                    </div>
                    <div className='img-airline-dv'>
                    <img src="/images/3spicelogo.png" alt="" />
                    <p>SPICE JET</p>
                    </div>
                    <div className='img-airline-dv'>
                    <img src="/images/4gologo.png" alt="" />
                    <p>GO AIR</p>
                    </div>
                    <div className='img-airline-dv'>
                    <img src="/images/5vistara.png" alt="" />
                    <p>VISTARA</p>
                    </div>
                  

                    </div>


                    </div> 
                    <div className='bmt-ailrine-hr-img-contaier'>
                        <img src="/images/btmairline.png" alt="" />
                    </div>
                </div>
               </div>
            </div>
        </div>


        <div className='gov-img-container-btm-div'>
            <div className='container'>
            <div className='airline-logo-airlogo-div'>
                    <div className="marquee-container">

                    <div className='img-airline-dv'>
                    <img className='govbtm-img'  src="/images/gov/bmtimg1.png" alt="" />
                    </div>
                    <div className='img-airline-dv'>
                    <img className='govbtm-img'  src="/images/gov/bmtimg2.png" alt="" />
                    </div>
                    <div className='img-airline-dv'>
                    <img className='govbtm-img' src="/images/gov/bmtimg3.png" alt="" />
                    </div>
                    <div className='img-airline-dv'>
                    <img className='govbtm-img' src="/images/gov/bmtimg4.png" alt="" />
                    </div>
                    <div className='img-airline-dv'>
                    <img className='govbtm-img' src="/images/gov/bmtimg5.png" alt="" />
                    </div>
                    <div className='img-airline-dv'>
                    <img className='govbtm-img' src="/images/gov/bmtimg6.png" alt="" />
                    </div>
                    <div className='img-airline-dv'>
                    <img className='govbtm-img' src="/images/gov/bmtimg7.png" alt="" />
                    </div>
                    <div className='img-airline-dv'>
                    <img className='govbtm-img' src="/images/gov/bmtimg8.png" alt="" />
                    </div>
                    </div>


            </div>
            </div>
        </div>
    </>
  )
}

export default AirlineLogo