import React from 'react'
import {Helmet} from 'react-helmet';

const HouseKeeping = () => {
  return (
    <>
       <Helmet>
      <meta charSet='UTF-8'/>
      <title>House Keeping</title>
      <meta name='description' content='paste your same description which is used on the same page' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
    </Helmet>

      <div className='container-fluid  top-banner-pg-div-housekeeping'>
          <div className='inner-pg-wrapper-div'>
              <div className='container ot-page-banner-heading'>
                  <h1>House Keeping</h1>
                  <p>Home / house keeping</p>
              </div>
          </div>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/housekeeping-img/hk1.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/housekeeping-img/hk2.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>House Keeping</h3>
              <p>Housekeeping- It include maintaining clean, orderly work places, keeping hallways and floors clear of trip hazards, and eliminating waste (paper, cardboard, and other fire dangers) from work areas.
It also necessitates paying attention to crucial particulars including the design of the entire workspace, aisle marking, the suitability of storage options, and upkeep. A fundamental component of accident and fire prevention is good housekeeping.
               <br />
               <br />
               Proper housekeeping is a continuous process; it is not a spotty clean-up carried out sporadically. Cleaning up in a "panic" is expensive and ineffectual at lowering accidents.
            
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container ot-page-cnt-form-div'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='ot-page-cnt-leftidv'>
            <h3>Efective Results</h3>
              <p>
              reduced handling to improve material flow
Less tripping and slide incidents in work spaces free of debris and spills
decreased risk of fire
reducing the dangers of hazardous substances to workers (e.g. dusts, vapours)
improved management of equipment and supplies, particularly inventory and supply 
               <br />
               <br />
               cleaner and more effective equipment upkeep
better sanitary conditions resulting in better health
more efficient utilisation of space improved preventative maintenance decreased property damage
less janitorial tasks Better morale
There will be increased productivity and easier access to supplies.
              
               </p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/housekeeping-img/hk3.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/housekeeping-img/hk4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img-div-hkmd1'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
         
          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right-btm'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Health and safety compliance</h3>
              
              <p>
              How can a Health and Safety Compliance Audit help with poor housekeeping?
              Employers can discover areas of poor housekeeping in the workplace and take action to address them by performing a health and safety compliance audit. For instance, an inspector may notice during an audit that a workspace is congested, obstructing emergency exits or causing trip risks. Maybe it can discover that tools or machinery aren't kept up properly and produce dust or other airborne contaminants. Employers can take steps to eliminate these dangers and make the work environment safer for employees by detecting them during an audit.
               <br />
               <br />
             
            
               </p>
             </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left-btm'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/housekeeping-img/hk5.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/housekeeping-img/hk6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img2-div-hkmd2'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/housekeeping-img/hk7.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/housekeeping-img/hk8.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              {/* <h3>Put Heading Here...</h3> */}
              <p>
              The audit can aid management in identifying the areas that need the most cleaning and upkeep and in setting priorities for such areas.
Compliance audits also assist organisations in maintaining compliance with safety laws, lowering the possibility of fines and penalties for non-compliance. Overall, by detecting and eliminating hazards and making sure the workplace complies with pertinent rules, a health and safety compliance audit can assist improve poor housekeeping.
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HouseKeeping