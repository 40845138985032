import { configureStore } from "@reduxjs/toolkit";
import userReducer from './userSlice';
import editModeReducer from './editModeSlice'
 
const store = configureStore({
    reducer: {
        user:  userReducer,
        editMode: editModeReducer
    }
});


export default store;
