import { createSlice } from "@reduxjs/toolkit";

const editModeSlice = createSlice({
    name: 'editmode',
    initialState: {
        editModeStatus : false
    },

    reducers:{
        updateEditModeStatus(state, action){
            state.editModeStatus = action.payload
        }
    },
})

export const {updateEditModeStatus} = editModeSlice.actions

export default editModeSlice.reducer;