import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

const QueryPage = () => {
  const contactQueries = `${process.env.REACT_APP_HOSTURL}/api/v1/ContactForm`;

  const [regData, setRegData] = useState([]);
  const [loading, setLoading] = useState(false);

  //* Pagination Start
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [itemsPerpage, setItemsPerPage] = useState(10);

  const indexOfLastitems = currentPage * itemsPerpage;
  const indexOfFirstItems = indexOfLastitems - itemsPerpage;
  const currentItems = regData?.slice(indexOfFirstItems, indexOfLastitems);

  // eslint-disable-next-line
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let increamentBtn = null;
  if (pages.length > maxPageNumberLimit) {
    increamentBtn = <li onClick={handleNextBtn}> &hellip; </li>;
  }

  for (let i = 0; i <= Math.ceil(regData?.length / itemsPerpage); i++) {
    pages.push(i);
  }

  const renderPageNumber = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  //* Pagination End

  //* fetch Registered Users

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(contactQueries);
      // console.log(res.data.response)
      setRegData(res.data.response);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [contactQueries]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  return (
    <>
      <section className="container">
        <div>
          <div className="pages-btn-div">
            <p></p>
            <ul className="pageNumbers">
              <li>
                <button
                  className="btn btn-warning"
                  onClick={handlePrevBtn}
                  disabled={currentPage === pages[1] ? true : false}
                >
                  PREV
                </button>
              </li>
              {renderPageNumber}
              {increamentBtn}
              <li>
                <button
                  className="btn btn-warning"
                  onClick={handleNextBtn}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                >
                  NEXT
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="reg-title-div bg-info">
          <h2>Candidates Queries</h2>
        </div>
        <div className="reg-forms-table-div">
          <table className="table table-striped table-hover table-bordered">
            <thead className="table-info">
              <tr>
                <th scope="col">Student Name</th>
                <th scope="col">Email Address </th>
                <th scope="col">Phone No.</th>
                <th scope="col">Queries</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {currentItems?.map((d, i) => {
                return (
                  <tr key={i}>
                    <td>{d.name}</td>
                    <td>{d.email}</td>
                    <td>{d.phone}</td>
                    <td>{d.message}</td>
                    <td>{new Date(d?.createdAt).toDateString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default QueryPage;
