import React, { useState } from 'react';
import { Drawer } from 'antd';
import './newheader.css'
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { MdOutgoingMail,MdHome,MdLock,MdOutlineSettingsPower } from "react-icons/md";
import { Link } from "react-router-dom";
import { MenuFoldOutlined} from '@ant-design/icons';
import { useSelector,useDispatch } from 'react-redux';
import { logoutuser } from '../../store/userSlice';
import { useNavigate } from "react-router-dom";

const NewHeader = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

const userExist = useSelector((state)=> state.user.user)


const time = new Date()

const newDateArr =  time.toString().split(' ')

const hour = time.getHours()
const minute = time.getMinutes()

const newTime = `${newDateArr[2]} ${newDateArr[1]} | ${hour}:${minute}`


  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const style={
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around'
  }

  const imgStyle = {
    width: '30px'
  }


  const IconDrawe = () =>{
    return(
      <div style={style}>
        <img style={imgStyle}  src="/images/logo.png" alt="" />
        <p>NIA Aviation Services Pvt. Ltd.</p>
      </div>
    )
  }

  const logout = () => {
    dispatch(logoutuser());
    navigate("/");
  };

  return (
   <>
      <div className='topBar-nwd-main-div d-print-none'>
        <div className='container'>
          <div className='top-bar-inr-wrapper'>
            <div className='top-bar-inrt-left-wraaper'>
            <div className='today-time-div'>
            <p>{newTime}</p>
          </div>
          <div className='helpline-inforcontainer'>
            <div className='call-div'>
            <BsFillTelephoneForwardFill  className='top-bar-icon'/>
            <p><a href="tel:+919990809076">Helpline No: 99-908090-76</a></p>
            </div>
            <div className='email-div-tpbr'>
            <MdOutgoingMail className='top-bar-icon'/>
            <p>info@niaaviationservices.com</p>
            </div>
          </div>
            </div>
            <div className='top-bar-inrt-right-wraaper'>
              <Link to={'/'}>
               <div className='home-inr-container-dv'>
                <MdHome className='top-bar-icon'/>
                <p>Home</p>
                </div>
              </Link>
                <div className='maincontent-div'>
                 <div className='a-img-dv'>
                 <img src="/images/icons/a-outline.svg" alt="" />
                  <img src="/images/icons/a-fill.svg" alt="" />
                 </div>
                 {
                  userExist ? (
                    <>
                    <Link to={'/candidatedashboard'}><p>Dashboard</p></Link>
                    </>
                  ) : (
                    <>
                    <p>Main Content</p>
                    </>
                  )
                 }
                </div>
                {
                  userExist ? (
                    <>
                    <div className='login-div-topbnar' onClick={logout}>
                    <MdOutlineSettingsPower className='top-bar-icon'/>
                    <p><Link to={'/candidatedashboard'}>LogOut</Link></p>
                  </div>
                    </>
                  ) : (
                    <>
                    <div className='login-div-topbnar'>
                    <MdLock className='top-bar-icon'/>
                    <p><Link to={'/loginuser'}>Login</Link></p>
                  </div>
                    </>
                  )
                }
              
            </div>
          </div>
        </div>
      </div>

      <div className='mid-header-div d-print-none'>
      <div className='container'>
          <div className='logo-g-20-logo-wrapper'>
            <Link to={'/'}>
            <div className='logo-div'>
              <div className='logo-img-div'>
                <img src="/images/logo.png" alt="logo" />
              </div>
              <div className='logo-title-div'>
              <h3>NIA Aviation Services Pvt. Ltd.</h3>
              <h4>एनआईए एविएशन सर्विसेज प्रा. लि.</h4>
              <p>Registered Under Ministery Of Corporate Affairs</p>
              </div>
            </div>
            </Link>
            <div className='g20img-div'>
              <img id='chasma' src="/images/chasma.png" alt="chasma" />
              <img src="/images/g20head.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className='menu-main-header-div d-print-none'>
        <div className='container'>
            <div className='menu-list-inr-wrapper-dv'>
              <ul>
                <li><Link to={'/aboutnia'}>ABOUT US</Link></li>
                <li><Link to={'/careeratairports'}>CAREER & JOBS</Link></li>
                <li><Link to={'/groundstaffs'}>GROUND STAFF</Link></li>
                <li><Link to={'/eligibilityexamforaviation'}>ELIGIBILITY & ENTRANCE EXAM FOR AVIATION</Link></li>
                <li><Link to={'/flightattandent'}>FLIGHT ATTENDANT</Link></li>
                <li><Link to={'/jobselectionprocess'}>SELECTION PROCESS</Link></li>
                <li><Link to={'/contactus'}>CONTACT US</Link></li>
              </ul>
            </div>
            <div className='mobilenavButton'>
            <MenuFoldOutlined className='mobile-navbtn' onClick={showDrawer} />
            </div>

            <div className='mobnile-menu-list'>
            <Drawer title={<IconDrawe/>} placement="right" onClose={onClose} open={open}
            bodyStyle={{
              backgroundColor: '#3999E3'
            }}
            
            >
            <div className='mobile-menu-drawerlist'>
            <ul>
              {userExist &&   <li ><Link to={'/'} onClick={onClose}>Name: {userExist?.name}</Link></li>}
               {userExist &&  <li ><Link to={'/'} onClick={onClose}>Registration No: {userExist?.registrationnumber}</Link></li>}
                {userExist && <li ><Link to={'/candidatedashboard'} onClick={onClose}>Dashboard</Link></li>}
                <li className=''><Link to={'/register-now'} onClick={onClose}>APPLY NOW</Link></li>
                <li><Link to={'/getadmitcard'} onClick={onClose} className='blink'>ADMIT CARD (Available)</Link></li>
                <li><Link to={'/'} onClick={onClose}>RESULT (Not Available)</Link></li>
                <li><Link to={'/aboutnia'} onClick={onClose}>ABOUT US</Link></li>
                <li><Link to={'/careeratairports'} onClick={onClose}>CAREER & JOBS</Link></li>
                <li><Link to={'/groundstaffs'} onClick={onClose}>GROUND STAFF</Link></li>
                <li><Link to={'/eligibilityexamforaviation'} onClick={onClose}>ELIGIBILITY & ENTRANCE EXAM FOR AVIATION</Link></li>
                <li><Link to={'/flightattandent'} onClick={onClose}>FLIGHT ATTENDANT</Link></li>
                <li><Link to={'/jobselectionprocess'} onClick={onClose}>SELECTION PROCESS</Link></li>
                <li><Link to={'/becamegroundstaff'} onClick={onClose}>BECOME GrOUND STAFF</Link></li>
                <li><Link to={'/wheelchairstaff'} onClick={onClose}>WHEEL CHAIR STAFF</Link></li>
                <li><Link to={'/trainingoverviews'} onClick={onClose}>TRAINING OVERVIEW</Link></li>
                <li><Link to={'/professionaltraining'} onClick={onClose}>PROFESSIONAL OVERVIEW</Link></li>
                <li><Link to={'/aviationaftertwelfth'} onClick={onClose}>AVIATION AFTER 12 <sup>th</sup></Link></li>
                <li><Link to={'/airportdetails'} onClick={onClose}>AIRPORT RETAIL</Link></li>
                <li><Link to={'/housekeeping'} onClick={onClose}>AIRPORT SERVICES</Link></li>

                <li><Link to={'/contactus'} onClick={onClose}>CONTACT US</Link></li>
              </ul>
            </div>
      </Drawer>
            </div>
        </div>
      </div>
   </>
  )  
}

export default NewHeader