import React from 'react'
import {Helmet} from 'react-helmet';

const Careeratairport = () => {
  return (
   <>
     <Helmet>
      <meta charSet='UTF-8'/>
      <title>Career at airport</title>
   
      <meta name='description' content='paste your same description which is used on the same page' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
    </Helmet>

      <div className='container-fluid  top-banner-pg-div'>
          <div className='inner-pg-wrapper-div'>
              <div className='container ot-page-banner-heading'>
                  <h1>Career At Airport</h1>
                  <p>Home / career at airport</p>
              </div>
          </div>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/careeratairport/1.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/careeratairport/2.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Career at airport</h3>
              <p>NIA Aviation Services Pvt.Ltd. believes that its most valuable asset is the experience it gains from its candidates, so as an organisation, it promotes the success and progress of its applicants. The secret to our effectiveness is supporting investment, collaboration, activity, innovation, and profitability of our assets.
               <br />
               <br />
               NIA Aviation Services Pvt.Ltd. wants to work with its applicants in the long run. It believes that each person who supports it is a unique representative of the organisation as a whole. Hence, it anticipates that the majority of its professors will take part in sharing gathering solidarity while upholding the law and demonstrating that consistency is maintained in both their behaviour and the performance of their duties.
            
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container ot-page-cnt-form-div'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='ot-page-cnt-leftidv'>
            <h3>Manpower Services</h3>
              <p>NIA Aviation Services Pvt.Ltd. has significant roots in aviation, hospitality, travel & tourism, retail, event management, and other areas of the tourism industry. At various airports in India and overseas, it has engaged more than 6000 candidates to do ground handling tasks for both domestic and foreign airlines. To meet the demands of new clients in many industries, NIA Aviation Services Pvt.Ltd. alone needs a sizable workforce.
               <br />
               <br />
               NIA Aviation Services Pvt.Ltd. typically requires 100 professionals in ground staff on a regular basis. Airlines automatically contact NIA Aviation Services Pvt.Ltd. for their Ground Crew requirements before making this information public as a natural result of NIA Aviation Services Pvt.Ltd.’s good relationships with aviation industry stakeholders. As a result, NIA Aviation Services Pvt.Ltd. is frequently the first to learn about these coveted openings.
               </p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/careeratairport/3.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/careeratairport/4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img-div-cata'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
         
          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right-btm'>
             <div className='ot-pg-put-right-top-cnt'>
              {/* <h3>Put Heading Here...</h3> */}
              <p>In the aviation, hospitality, travel & tourism, retail, and event management industries, we at NIA Aviation Services Pvt.Ltd. make sure that our candidates get the best job openings possible. According to our routine of matching the ideal person with the ideal job and in accordance with our HR planning, potential candidates are gathered in a competitive pool. In terms of faculty selection, our policy is to provide consideration to all candidates for available positions based on their unique career paths and potentials.
               <br />
               <br />
               NIA Aviation Services Pvt.Ltd. uses the most recent advancements in communication, general development, and visual aids. The prep space is completely furnished and furnished with a variety of multimedia tools, including projectors and workstations with PC offices. Also, the arena maintains a comfortable room temperature and virtually guarantees the environment's cleanliness. A surveillance camera has been installed to further secure the area and monitor potential wrongdoing.
            
               </p>
             </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left-btm'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/careeratairport/5.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/careeratairport/6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img2-div-cata'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/careeratairport/7.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/careeratairport/8.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              {/* <h3>Put Heading Here...</h3> */}
              <p>This is true for all employment possibilities across the board of personnel requirements. We have a reputation for creating a variety of specialist occupations and supplying intelligent, fiercely competitive people to the airline and travel and tourism industries.
              
               <br />
               <br />
               The prep space is completely furnished and furnished with a variety of multimedia tools, including projectors and workstations with PC offices. Also, the arena maintains a comfortable room temperature and virtually guarantees the environment's cleanliness. A surveillance camera has been installed to further secure the area and monitor potential wrongdoing.uses the most recent advancements in communication, general development, and visual aids. The prep space is completely furnished and furnished with a variety of multimedia tools, including projectors and workstations with PC offices. Also, the arena maintains a comfortable room temperature and virtually guarantees the environment's cleanliness. A surveillance camera has been installed to further secure the area and monitor potential wrongdoing.
            
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>
   </>
  )
}

export default Careeratairport