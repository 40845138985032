import React from 'react'
import { AiOutlineForm } from "react-icons/ai";
import { Link } from 'react-router-dom';

const QuerySection = () => {
  return (
    <>
    <div className='queryContainer-maain-new-div'>
        <div className='container'>
            <div className='query-iner-wrapper-container-dv'>
                <div className='query-container-dv'>
                <Link to={'/contactus'}>
                  <div className='query-inr-wrapper'>
                    <h4>How May I Help You ?</h4>
                    <div className='inputqurty-div-cointainer'>
                    <p>Click here to submit your query... </p>
                    <AiOutlineForm className='edit-cicon'/>
                    </div>
                  </div>
                </Link>
                </div>
                <div className='privacy-poliy-cohtainer-dvc'>
                   <div className='policy-inrr0-dv'>
                    <Link to={'/privacyrefund'}>
                    <h1>Privacy/Refund/Cancellation Policy</h1>
                    <p>Refund & Cancellation Policy / Term & conditions</p>
                    </Link>
                   </div>

                   <div className='policy-inrr0-dv'>
                    <Link to={'/careeratairports'}>
                   <h1>Career At Airport</h1>
                    <p>Set no Limit To Get success in Your Life</p>
                    </Link>
                   </div>
                </div>

                <div className='conat-query-containerv'>
                    <div className='conatact-inr-btm-div'>
                        <div className='contact-icon-bg-div skew-shake-y'>
                        <div className='cohnat-icon-div'>
                        <img src="/images/icons/call.svg" alt="" />
                        </div>
                        </div>
                        <div className='contct-details-vbtm-div'>
                            <h1>Contact Us</h1>
                            <h4><a href="tel:+919990809076">+91 99-908090-76</a></h4>
                            <h4><a href="tel:+919990809056">+91 99-908090-56</a></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default QuerySection