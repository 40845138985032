import React from 'react'
import {Helmet} from 'react-helmet';

const FlightAttendant = () => {
  return (
    <>
       <Helmet>
      <title>Flight attendant</title>
      <meta charSet='UTF-8'/>
        <meta name="keywords" content="Aviation, Aviation Jobs, Noida Airport,NIA Aviation Services, NIA, NIA, airport jobs, graound staff jobs"/>
        <meta name="description" content="This is a very important responsibility when the aircraft is parked at the airport. In this, all those small and big tasks are involved that the ground staff has to perform. Like taking care of the luggage of the passengers, the passenger should not face any kind of inconvenience after coming off the plane. The responsibilities of the ground staff keep increasing and decreasing according to the size of the aircraft. If there is a passenger plane, then the ground staff has to be responsible more carefully because when many passengers come out of the plane at the same time then there is more possibility of inconvenience or trouble. And when the cargo plane lands at the airport, the ground staff has to take care of the luggage very carefully. Along with performing these responsibilities, the ground staff has to maintain security at the airport."/>
        <meta name="author" content="Nia aviation services pvt ltd"/>
        <meta name="refresh" content="50"/>
        <meta http-equi="refresh" content="" url="www.niaaviation.com"/>
         </Helmet>

      <div className='container-fluid  top-banner-pg-div-flight-attendant'>
          <div className='inner-pg-wrapper-div'>
              <div className='container ot-page-banner-heading'>
                  <h1>Flight attendant</h1>
                  <p>Home / flight attendant</p>
              </div>
          </div>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/flightattendant/1.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/flightattendant/2.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>What are the Requirements to Become a Flight Attendant?</h3>
              <p>You must fulfil a number of prerequisites in order to work as a flight attendant. There is a list of "Minimum Qualifications to Become a Cabin Attendant" issued by the General Directorate of Civil Aviation, despite the fact that these requirements vary between firms. In addition to these, depending on your job zone, it is also expected that you speak one or more foreign languages.
               <br />
               <br />
               Hours of labour The shifts that cabin crew members work are sometimes unpredictable and unsociable. This may entail working in the wee hours of the night, on weekends, and on legal holidays. Some airlines have varying work schedules from week to week. Long-haul flights may offer fewer regular hours than short-haul flights.If your return trip is cancelled or delayed, you might occasionally need to work or be flexible on your days off, but in these situations, you'll be paid.
There are opportunities for part-time work, but they still require irregular hours.
            
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container ot-page-cnt-form-div'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='ot-page-cnt-leftidv'>
            <h3>What to expect</h3>
              <ul>
                <li>Airlines that serve the package vacation industry typically hire flight attendants on a seasonal basis.</li>
                <li>Several airlines have a requirement that employees live close to the airport or within easy driving distance. Staff members may need to be on call for work at short notice, therefore flexibility is essential. Also, you might reside abroad</li>
                <li>The task can be challenging because cabin crew members frequently struggle with jet lag and fatigue when travelling between different time zones. You'll have to stand for long periods of time while working in small places. Even while dealing with difficult passengers, you'll need to keep a good attitude.</li>
                <li>The airline will give you a uniform, and you'll be required to always dress formally and maintain good grooming.</li>
                <li>Depending on the airline you work for and whether you work on short- or long-haul flights, the amount of time spent away from home varies. While long-haul flights necessitate spending nights away from home, short-haul flights usually allow you to return the same day.</li>
                <li>A diversity of people from various origins and cultures will be your coworkers. Even if it could just be for a few hours or one evening in certain situations, long-haul travellers will get to experience the places they are travelling to. Short-haul flights usually have a short turnaround time of 45 minutes to 2 hours before returning. You won't have time to visit any of the destination at that time because the aircraft will be getting ready for the flight.</li>
              </ul>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/flightattendant/3.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/flightattendant/4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img-div-flt-atndt'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
         
          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right-btm'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Skills</h3>
              <p>Your ability to demonstrate:</p>
            <ul>
              <li>Outstanding communication abilities.</li>
              <li>First-rate client service.</li>
              <li>Assurance when interacting with a variety of people.</li>
              <li>Excellent teamwork abilities because you'll frequently collaborate with other teams.</li>
              <li>Empathy and the capacity to assist your coworkers.</li>
              <li>Tact when interacting with VIPs.</li>
              <li>The capability to handle challenging circumstances and maintain composure under pressure and in emergency situations.</li>
              <li>The talent for communicating with passengers diplomatically while still being aggressive when necessary.</li>
              <li>Sales expertise and business acumen.</li>
              <li>The ability to work irregular hours at any time of the year.
              <ul>
                  <li>The ability to operate swiftly and effectively under pressure, frequently.</li>
                  <li>The ability to work in a small space.</li>
                  <li>Numeracy abilities for managing cash, including foreign currencies.</li>
                  <li>The capability to diffuse conflict gently and promptly.</li>
                </ul>
              </li>
                
            </ul>
             </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left-btm'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/flightattendant/5.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/flightattendant/6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default FlightAttendant