import React from 'react'
import { useLocation } from "react-router-dom";

const PaymentStatus = () => {

  const location = useLocation();

  // console.log(location.state.data)

  return (
    <>
      <div className='container'>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '20px 0px'}}>
          <h1 className='text-muted mb-2'>Payment status</h1>
        <div className="card" style={{width: '18rem'}}>
  <div className="card-header bg-danger text-light fw-bold">
   ⚠️ {location?.state?.data?.state}
  </div>
  <ul className="list-group list-group-flush">
    <li className="list-group-item"><b>Amount:</b> {location?.state?.data?.amount / 100} INR</li>
    <li className="list-group-item"><b>Merchant Transaction ID:</b> {location?.state?.data?.merchantTransactionId} INR</li>
    <li className="list-group-item"><b>Response Code:</b> {location?.state?.data?.responseCode === null ? 'FAILED' : '-'}</li>
  </ul>
</div>
<h5 className='mt-2 text-danger p-2 text-center'> ⚠️  Your payment may get canceled in the future, please check your bank details. If you have failed, please try again after some time.  ⚠️</h5>
  <a href="/" className='btn btn-warning mt-2'>Try Again</a>
        </div>
      </div>
    </>
  )
}

export default PaymentStatus
