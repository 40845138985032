import React from 'react'
import '../css/policies.css'

const PrivacyPol = () => {
  return (
    <>
        <div className='termmaincontainer-div pt-3 pb-3'>
            <div className='container shadow p-2'>
                <div className='termcn-title-div'>
                    <h1>Privacy Policy</h1>
                </div>

                <div className='iner-terncnd-cnt-dv'>
                <p><u>The Policy explains our rules for the gathering, using, and disclosing of your personal information as well as all the reasonable security measures we take to protect it.</u></p>
             
                </div>

                <div className='termcnd-list'>
                    <ul>
                        <li>You consent to being bound by the current provisions of this Policy as amended from time to time, subject to your legal rights. We reserve the right to change our Policy, therefore we encourage you to frequently review the most recent version.</li>
                        <li>The Personal Data we collect includes Personal Data obtained from thewellnessstore.in and its associated domains and subdomains, as well as through its mobile application. This Privacy Policy is applicable to all Personal Data we collect.</li>
                        <li>You agree to the collection, use, and disclosure of your personal information in accordance with this policy, as it may be updated from time to time by us,
                        and to the transfer of that information to other businesses or entities within the NIA by using our website and/or providing your personal information to order our products and use our services.</li>
                        <li>Any changes to the Policy will be posted on our website. Please refrain from accessing this Website or submitting Personal Data if you do not agree to the conditions of this Policy</li>
                        <li>The terms of this Policy do not affect any agreements you might have with NIA, which would supersede the terms of this Policy.</li>
                        <li>"Personal data" refers to information about an individual who can be identified I from that information alone or (ii) from that information and other data to which NIA has or is likely to have access. Your name, contact information, email address, residential address, payment information, and
                        other personal data that NIA may need in order to deliver our services or Website to you are a few examples of the types of personal data that may be collected by NIA.</li>
                        <li>NIA does not gather, utilise, or process personal data unless you freely do so. We enforce rigorous security and confidentiality standards for our employees, and we promise to completely abide by industry-recognized privacy
                        protection standards when using, disclosing, and processing your personal data.</li>
                        <li><u>In general, we may gather, use, and disclose your Personal Data for any or all of the reasons listed below:</u></li>
                        <ul className='privacypolalphalist'>
                            <li>To confirm your identity.</li>
                            <li>To stay in touch with you and let you know of any NIA news or ongoing specials.</li>
                            <li>To modify and customise the website's content.</li>
                            <li>To assess your preferences and make it easier for future Website updates.</li>
                            <li>To oversee our internal operations and infrastructure, including—but not exclusively—where necessary to ensure adherence to internal rules and regulations.</li>
                            <li>To examine, review, and audit our internal procedures.</li>
                            <li>To stop, spot, and/or look into fraud, crime, and criminal activities.</li>
                            <li>To uphold and defend our legal and contractual rights and responsibilities.</li>
                            <li>To respond to information requests from public or governmental
                            organisations, regulatory agencies, or other organisations with similar powers
                            that are authorised to perform certain government functions.</li>
                            <li>To make sure we abide by all pertinent laws, rules, regulations, and codes of
                            conduct.</li>
                            <li>Any additional goals connected to the ones listed above, or those that may be
                            brought to your attention and receive your approval at the time of collecting
                            or using the personal data.</li>
                        </ul>

                        <li>Moreover, NIA automatically gathers and logs information from your browser,
                        such as your IP address, cookie data, and the page you requested, on our server logs. Please consult the help and support section of your Internet browser for instructions on how to find the file or directory that holds cookies if you wish to remove any existing cookies from your computer.</li>
                        <li>Unless where forced to do so for legal or regulatory purposes, NIA will never sell, rent, share, or otherwise disseminate or make available your Personal Data to other parties outside the NIA group of companies or affiliates. Your personal information might, however, occasionally be given to third parties who work for and on behalf of NIA for additional processing in line with the original purposes for which it was obtained. The NIA shall ensure that such third parties provide the same level of protection as NIA and that they are
                        required to process your Personal Data transferred only for the purposes authorised by you and on a confidential and secure basis when disclosure of your Personal Data to third parties is likely or necessary for any reason.</li>
                        <li>The majority of the data we gather from our website is anonymous data. We do not gather any personal information about you when you visit our website unless you directly, intentionally, and willingly disclose it to us. NIA processes
                        anonymous information to help us improve the content of our website, to better serve our visitors, and to better understand them and how they use it. To achieve this, we might employ tracking technologies (cookies) to collect anonymous data about your browser and operating system as well as the time and date of your access. Please visit our Cookies Policy at Cookies Policy to view our cookies policy.</li>
                        <li>NIA doesn't engage in "spamming". Spamming is the practise of frequently
                        and in bulk sending unsolicited commercial emails to recipients who have either declined to receive them or with whom the sender has had no prior contact.</li>
                        <li>Links to websites or mobile applications not run or under the control of NIA may be provided on our website as a courtesy to our visitors. As a result, we are not liable or responsible for the information included on such websites, mobile applications, or other third parties. Please be aware that our Policy may not apply to their privacy practises. Before giving them any personal information, we advise you to research and understand their privacy standards.</li>
                        <li>You agree to submit to the exclusive jurisdiction of the courts in New Delhi and that this Policy and the use of the Website shall be governed in all respects by the laws of India, without giving effect to any principles of conflict of law.</li>
                        <li>Any argument, disagreement, controversy, or claim between you and NIA relating to this Policy must first be brought to their attention. You commit to
                        taking part in the mediation in good faith and to upholding the conditions of
                        any agreement made.</li>
                        <li>Through this privacy policy, you are informed that all the candidates will have
                        to pay an amount of Rs. 410/- for the examination fee.</li>
                        <li>Candidates are uploading their photo and signature as per their wish.</li>
                        <li>Candidates are filling their personal details in the application form as per their
                        wish. There is no pressure on them and this information is only for data purpose. The company ensures all the candidates that their information will not be misused.</li>
                        <li>After this entire process, the exam of all the candidates is conducted by the company and the candidates who clear the exam are given proper professional training for which they are also given a certificate.</li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default PrivacyPol