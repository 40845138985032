import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/userSlice";
import "./css/register.css";

import {Helmet} from 'react-helmet';

const Login = () => {
  
  const dispatch = useDispatch();

  const loginUserData = useSelector((state) => state.user.user);

  //console.log("login New State user: ", loginUserData);

  const navigate = useNavigate();

  const [registrationnumber, setRegistration] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [require, setrequire] = useState(false);

  const [msg, setMsg] = useState('')

  const [userData, setUserData] = useState();

  const [loading, setLoading] = useState(false);

  //console.log(userData);

  const loginHandler = async () => {
    if (registrationnumber === "" || password === "") {
      setrequire(true);
    //  console.log("user details required");
      return;
    } else {
      try {
        const userLoggedin = localStorage.getItem("userinfo");
        if (userLoggedin) {
          navigate("/");
        } else {
          setLoading(true);
          const res = await axios.post(
            `${process.env.REACT_APP_HOSTURL}/api/v1/login`,
            {
              registrationnumber,
              password,
            }
          );

          setUserData(res.data);

          if (res.data.status === 200) {
            const { _id, name, registrationnumber } = res.data.body;

            const obj = {
              id: _id,
              name: name,
              registrationnumber: registrationnumber,
            };

            dispatch(login(obj));



            navigate("/register-now");
            setLoading(false);
          } else if (res.data.status === 300) {
            //console.log("user already logged in");
          } else if (res.data.status === 404) {
            setError(true);
            console.log(res.data.message)
            setMsg(res.data.message)
            setLoading(false);
          } else if (res.data.status === 500) {
            setError(true);
          }
        }
      } catch (err) {
        console.log(err)
        setLoading(false);
        // console.log(err);
        // console.log("something went wrong");
      }
    }
  };

  useEffect(()=>{
    if(!loginUserData){
      return
    }else{
      navigate('/application-form')
    }
  },[loginUserData,navigate])


  return (
    <>
 <Helmet>
      <meta charSet='UTF-8'/> 
      <meta name='description' content='paste your same description which is used on the same page' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
      <script>
      {`
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
              "gtm.start": new Date().getTime(),
              event: "gtm.js",
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-5WP56CFD");
        `}
      </script>
    </Helmet>

      <div className="login-main-container">

      <div className="login-container shadow" >
        {loading ? (
          <div className="loading-div  loading-register-page">
            <img src="/images/loading.svg" alt="aloding" />
            <h5>Wait Try To Logged In </h5>
          </div>
        ) : null}
       <div className="login-title-bg">
       <h1>Login Page</h1>
       </div>
        <div style={{ color: "red", marginBottom: "10px", textAlign: 'center', marginTop: '10px' }}>
          {require ? (
            <span style={{fontWeight: 'bold'}}>All fields required</span>
          ) : (
            error && <span>{msg}</span>
          )}
        </div>
        <div className="text-center mt-3 mb-3"><p><b>{msg}</b></p></div>
        <div className="form-element-container">
          <label>Registration Number</label>
          <input
          className="form-control"
            type="number"
            placeholder="enter your registration number"
            onChange={(e) => setRegistration(e.target.value)}
          />
        </div>
        <div className="form-element-container">
          <label>Password</label>
          <input
           className="form-control"
            type="date"
            placeholder="enter your password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-element-container">
          <button className="btn btn-primary"  onClick={loginHandler} disabled={loading ? true : false}>
            Login Now!
          </button>
          <Link className="create-acc-text" to={"/register-now"}>Don't have an account register now</Link>
        </div>

        <div className="forget-link-div">
        <Link to={"/forget"}>Forget Password ?</Link>
        </div>
      </div>
      </div>
    </>
  );
};

export default Login;
