import React from 'react'
import {Helmet} from 'react-helmet';

const Selectionprocess = () => {
  return (
    <>
       <Helmet>
      <meta charSet='UTF-8'/>
      <title>Selection procedure</title>
      <meta name='description' content='paste your same description which is used on the same page' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
    </Helmet>

      <div className='container-fluid  top-banner-pg-div-selection-process'>
          <div className='inner-pg-wrapper-div'>
              <div className='container ot-page-banner-heading'>
                  <h1>Selection Process</h1>
                  <p>Home / selection process</p>
              </div>
          </div>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/selectionprocess/1.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/selectionprocess/2.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Selection procedure</h3>
            <ol>
              <li>The certificate of 10+2 is mandatory for the candidate.</li>
              <li>Age should be 18 to 27 years. (Relaxation will be given for the reserved category).</li>
              <li>Candidate have to apply the form in online mode.</li>
              <li>Exam will be held at the center.</li>
              <li>It is mandatory for the candidate to clear the exam.</li>
              <li>After the exam, the candidate will have to come to our head office for the interview and it is necessary to clear the interview.</li>
              <li>Then after clearing the exam and interview the training will be given by professional trainers.</li>
              <li>It is mandatory for the candidate to pass the training.</li>
              <li>If the candidate passes the training then we will assist him/her for job 100%.</li>
            </ol>
             </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Selectionprocess