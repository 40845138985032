import React from 'react'

const PaymentFailed = () => {

  const style={
    margin: '0 auto',
    marginBottom: '50px',
    marginTop: '50px'
  }

  return (
    <>
       <div className='container'>
       <div style={style} className="alert alert-warning text-center" role="alert">
         <h3>Payment Failed</h3>
         <p className='mt-4'>Your payment is failed please try again after sometime with diffrent payment method.</p>
        <a href="/" className='btn btn-warning my-4'>Try Again</a>
        </div>
       </div>
    </>
  )
}

export default PaymentFailed