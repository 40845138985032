import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { State } from "country-state-city";
import Modal from "react-modal";
import "../components/css/Form.css";
import FormPreview from "../components/FormPreview";
import { Progress } from "antd";
import { MdWorkspaces } from "react-icons/md";
import { SlArrowDown } from "react-icons/sl";

import { useSelector } from "react-redux";

const Form = () => {
  let getLoggedinuser = localStorage.getItem("userinfo");
  const loginUserData = useSelector((state) => state.user.user);
  let user = JSON.parse(getLoggedinuser);
  const navigate = useNavigate();

  const jobPost = `${process.env.REACT_APP_HOSTURL}/api/v1/post`;

  const [permanentAddressChecked, setPermanentAddressChecked] = useState(true);

  const [data, setData] = useState("");
  const [postapplyName, setPostApplyName] = useState("");
  const [postId, setPostID] = useState();

  const [choosestate, setChooseState] = useState();
  const [chooseCity, setChooseCity] = useState();

  const [chooseExamCenter, setChooseExamCenter] = useState([]);
  const [chooseExamCity, setChooseExamCity] = useState([]);
  const [stateID, setStateID] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loginUserData) {
      return;
    } else {
      navigate("/register-now");
    }
  }, [loginUserData, navigate]);

  //* Exam Center City API Start

  async function fetchExamCentreState() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_HOSTURL}/api/v1/country/getstates`
      );

      setChooseExamCenter(res.data.data);
    } catch (err) {
      // console.log(err);
    }
  }
  useEffect(() => {
    fetchExamCentreState();
  }, []);

  async function fetchExamCentreStateCity() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HOSTURL}/api/v1/country/getcities`,
        {
          stateNameId: stateID,
        }
      );

      setChooseExamCity(res.data.data);
    } catch (err) {
      //  console.log(err);
    }
  }

  useEffect(() => {
    fetchExamCentreStateCity();
    // eslint-disable-next-line
  }, [stateID]);

  function getExamCenterId(e) {
    e.preventDefault();
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    setStateID(option);
    setChooseState(e.target.value);
  }

  //* Exam Center City API END

  let state = State.getStatesOfCountry("IN");

  const fetchData = useCallback(async () => {
    await axios
      .get(jobPost)
      .then((res) => {
        setData(res.data.body[0].price);
        setPostApplyName(res.data.body[0].name);
        setPostID(res.data.body[0]._id);
      })
      .catch((err) => {
        //console.log("error", err);
      });
  }, [jobPost]);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);

  const sameAddress = (e) => {
    const value = e.target.checked;
    if (value) {
      setPermanentAddressChecked(false);
      setPermanentStreetName("-");
      setPermanentHouseNo("-");
      setPermanentVillage("-");
      setPermanentCity("-");
      setPermanentPostOffice("-");
      setPermanentState("-");
      setPermanentDistrict("-");
      setPermanentPincode("-");
    } else if (!value) {
      setPermanentAddressChecked(true);
    }
  };
  const [declaration, setDeclaration] = useState("false");

  // Form Handling with start

  const [candidatename, setCandidateName] = useState("");
  const [lastname, setLastName] = useState("");
  const [fathername, setFatherName] = useState("");
  const [mothername, setMotherName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [cast, setCast] = useState("");
  const [married, setMarried] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [education, setEducation] = useState("");
  const [educationdivision, setEducationDivision] = useState("");
  const [intermediate, setIntermediate] = useState("");
  const [intermediatedivision, setIntermediateDivision] = useState("");
  const [highschool, setHighSchool] = useState("");
  const [highschooldivision, setHighSchoolDivision] = useState("");
  const [profilePhoto, setProfilePhoto] = useState();
  const [signature, setSignature] = useState();

  //TODO: Present Address Handling Form.
  const [presentstreetname, setPresentStreetName] = useState("");
  const [presenthouseno, setPresentHouseNo] = useState("");
  const [presentvillage, setPresentVillage] = useState("");
  const [presentcity, setPresentCity] = useState("");
  const [presentpostoffice, setPresentPostOffice] = useState("");
  const [presentstate, setPresentState] = useState("");
  const [presentdistrict, setPresentDistrict] = useState("");
  const [presentpincode, setPresentPincode] = useState("");

  //TODO: Permanent Address

  const [permanentstreetname, setPermanentStreetName] = useState("");
  const [permanenthouseno, setPermanentHouseNo] = useState("");
  const [permanentvillage, setPermanentVillage] = useState("");
  const [permanentcity, setPermanentCity] = useState("");
  const [permanentpostoffice, setPermanentPostOffice] = useState("");
  const [permanentstate, setPermanentState] = useState("");
  const [permanentdistrict, setPermanentDistrict] = useState("");
  const [permanentpincode, setPermanentPincode] = useState("");
  // Form Handling with end
  const [progress, setProgress] = useState(null);
  //create form data as object and pass it to form preview component

  const previewData = {
    candidatename: candidatename,
    lastname: lastname,
    fathername: fathername,
    mothername: mothername,
    dob: dob,
    gender: gender,
    cast: cast,
    married: married,
    email: email,
    phone: phone,
    education: education,
    educationdivision: educationdivision,
    intermediate: intermediate,
    intermediatedivision: intermediatedivision,
    highschool: highschool,
    highschooldivision: highschooldivision,
    profilePhoto: profilePhoto,
    signature: signature,
    postapplyName: postapplyName,
    fees: data,
    presentstreetname: presentstreetname,
    presenthouseno: presenthouseno,
    presentvillage: presentvillage,
    presentcity: presentcity,
    presentpostoffice: presentpostoffice,
    presentstate: presentstate,
    presentdistrict: presentdistrict,
    presentpincode: presentpincode,
    permanentAddressChecked: permanentAddressChecked,
    permanentstreetname: permanentstreetname,
    permanenthouseno: permanenthouseno,
    permanentvillage: permanentvillage,
    permanentcity: permanentcity,
    permanentpostoffice: permanentpostoffice,
    permanentstate: permanentstate,
    permanentdistrict: permanentdistrict,
    permanentpincode: permanentpincode,
    choosestate: choosestate,
    chooseCity: chooseCity,
  };

  const declartionFunc = (e) => {
    const value = e.target.checked;
    if (!value) {
      setDeclaration(false);
    } else {
      setDeclaration(true);
    }
  };

  //! PhonePe Start

  const phonePe = async (data) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HOSTURL}/api/v1/phonepe/gateway`,
        {
          amount: data.amount,
          userDetails: data.userDetails,
          mobile: data.mobile,
          bodyData: previewData,
        }
      );
      // console.log(res.data.body.data.instrumentResponse.redirectInfo.url)
      window.location.href =
        res.data.body.data.instrumentResponse.redirectInfo.url;
      setLoading(false);
    } catch (err) {
      // console.log(err)
      setLoading(false);
    }
  };
  //! PhonePe End

  //!updatepayment status

  const updateFormPaymentStatus = async (id, paymentstatus, paymentid) => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_HOSTURL}/api/v1/updatepayment`,
        {
          id: id,
          paymentstatus: paymentstatus,
          paymentid: paymentid,
        }
      );
      console.log("upate form status : ", res.data);
      if (res.data.status === "success") {
        navigate(`/successapplicationpdf/${res.data.body._id}`);
      }
    } catch (err) {
      console.log(err.response.data.message);
      alert("Something went wrong contact to company support!");
      window.location.reload();
    }
  };

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const makePayment = async (formId) => {
    setLoading(true);

    // console.log("in make payement");

    const res = await initializeRazorpay();
    if (!res) {
      alert("Razorpay SDK Failed to load");
      return;
    }

    const data = await fetch(
      `${process.env.REACT_APP_HOSTURL}/api/v1/api/payment`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ price: 410 }),
      }
    ).then((t) => t.json());
    // console.log(data);
    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      name: "NIA Aviation Services Pvt Ltd.",
      currency: data.currency,
      amount: data.amount,
      order_id: data.id,
      description: "Payment for NIA",
      image: "https://niaaviationservices.com/images/logo.png",
      handler: async function (response) {
        // console.log(response.razorpay_payment_id);
        // console.log(response.razorpay_order_id);
        // console.log(response.razorpay_signature);
        setLoading(true);

        //! Form payment status update after payment complete
        await updateFormPaymentStatus(
          formId,
          "paid",
          response.razorpay_payment_id
        );

        //! Form submit after payment paid
        //submitForm(response.razorpay_payment_id, "paid");
      },
      prefill: {
        name: "",
        email: email,
        contact: phone,
      },
      theme: {
        color: "#2D4B8E",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const submitForm = async (payId, payStatus) => {
    setLoading(true);
    // Create a new FormData object
    var formData = new FormData();

    // Append data to the FormData object
    formData.append("userId", user.id);
    formData.append("registrationNumber", user.registrationnumber * 1);
    formData.append("candidatename", candidatename);
    formData.append("lastname", lastname);
    formData.append("fatherhusbandname", fathername);
    formData.append("mothername", mothername);
    formData.append("dateofbirth", dob);
    formData.append("gender", gender);
    formData.append("categorycast", cast);
    formData.append("maritalstatus", married);
    formData.append("postId", postId);
    formData.append("email", email);
    formData.append("mobile", phone);
    formData.append("educationalqualification", education);
    formData.append("educationdivision", educationdivision);
    formData.append("intermediate", intermediate);
    formData.append("intermediatedivision", intermediatedivision);
    formData.append("highschool", highschool);
    formData.append("highschooldivision", highschooldivision);
    formData.append("uploadphoto", profilePhoto);
    formData.append("firebasestudentprofile", "you commented this section");
    formData.append("uploadsignature", signature);
    formData.append("firebasestudentsignature", "you commented this section");

    // Append address data to the FormData object
    formData.append("address[present][street]", presentstreetname);
    formData.append("address[present][houseno]", presenthouseno);
    formData.append("address[present][village]", presentvillage);
    formData.append("address[present][city]", presentcity);
    formData.append("address[present][postoffice]", presentpostoffice);
    formData.append("address[present][state]", presentstate);
    formData.append("address[present][district]", presentdistrict);
    formData.append("address[present][pincode]", presentpincode);

    formData.append("address[permanent][pr_street]", permanentstreetname);
    formData.append("address[permanent][pr_houseno]", permanenthouseno);
    formData.append("address[permanent][pr_village]", permanentvillage);
    formData.append("address[permanent][pr_city]", permanentcity);
    formData.append("address[permanent][pr_postoffice]", permanentpostoffice);
    formData.append("address[permanent][pr_state]", permanentstate);
    formData.append("address[permanent][pr_district]", permanentdistrict);
    formData.append("address[permanent][pr_pincode]", permanentpincode);

    formData.append("isPresent", permanentAddressChecked);
    formData.append("isDeclaration", declaration);
    formData.append("examCentreState", choosestate);
    formData.append("examCentreCity", chooseCity);

    formData.append("paymentId", payId);
    formData.append("paymentStatus", payStatus);

    try {
      const instance = axios.create({
        baseURL: `${process.env.REACT_APP_HOSTURL}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const res = await instance.post(
        `${process.env.REACT_APP_HOSTURL}/api/v1/formdata`,
        formData,
        {
          onUploadProgress(progressEvent) {
            setProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
        }
      );
      //!phone pe gateway
      //     if(res.data.status === 'success'){
      //     const phonePeCredentials ={
      //         amount: res.data.body.price,
      //         userDetails: res.data.body._id,
      //         mobile: res.data.body.mobile,
      //     }
      //    // phonePe(phonePeCredentials)
      // }

      if (res.data.status === "success") {
        makePayment(res.data.body._id);
        // const id = res.data.body._id;
        // navigate(`/successapplicationpdf/${id}`)
      }
    } catch (err) {
      console.log(err);
      console.log("Form not submit try again later!!");
      console.log("Form not submit try again later!!");
      alert("Form not submit try again later!!");
      window.location.reload();
    }
  };

  const backtoHome =  true


  useEffect(()=>{
    if(backtoHome){
      navigate('/')
    }
  },[backtoHome,navigate])



  return (
    <>
      <div className="form-container">
        <form
          onSubmit={(e) => {
            submitForm("paymentnotfound", "notpaid");
            e.preventDefault();
          }}
        >
          <div className="inner-form container shadow-sm">
            <h2 className="application-form-title">Application Form</h2>
            <hr className="top-heading-bottom-hr-line" />
            <h3 className="details-heading-color text-decoration-underline">
              Basic Details
            </h3>
            <div className="candidate-name name-field-width-reduce ">
              <label htmlFor="candidate-name">Candidate Name</label>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control"
                  type="text"
                  id="candidate-name"
                  placeholder="enter your first name"
                  onChange={(e) => setCandidateName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="last-name name-field-width-reduce">
              <label htmlFor="last-name">Last Name</label>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control"
                  type="text"
                  id="last-name"
                  placeholder="enter your last name"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="father-name name-field-width-reduce">
              <label htmlFor="father-name">Father Name</label>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control"
                  type="text"
                  id="father-name"
                  placeholder="enter your father name"
                  onChange={(e) => setFatherName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mother-name name-field-width-reduce">
              <label htmlFor="mother-name">Mother Name</label>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control"
                  type="text"
                  id="mother-name"
                  placeholder="enter your mother name"
                  onChange={(e) => setMotherName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="email name-field-width-reduce">
              <label htmlFor="email">E-mail</label>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control"
                  type="email"
                  id="email"
                  placeholder="enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="phone name-field-width-reduce">
              <label htmlFor="phone">Phone No.</label>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control"
                  type="number"
                  id="phone"
                  placeholder="enter your phone"
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="gender name-field-width-reduce">
              <label htmlFor="gender">Gender</label>

              <div
                className="gender-main-dv border"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <div
                  className="form-check "
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="Male"
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Male
                  </label>
                </div>
                <div
                  className="form-check "
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    className="form-check-input me-2"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="Female"
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    Female
                  </label>
                </div>
              </div>
            </div>
            <div className="dob name-field-width-reduce">
              <label htmlFor="dob">Date of Birth</label>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control"
                  type="date"
                  id="dob"
                  placeholder="enter your dob name"
                  onChange={(e) => setDob(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="marital-status name-field-width-reduce">
              <label htmlFor="marital-status">Marital Status</label>

              <div
                className="gender-main-dv border"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <div
                  className="form-check "
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="flexRadioDefault5"
                    id="flexRadioDefault3"
                    value="Married"
                    onChange={(e) => setMarried(e.target.value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault3"
                  >
                    Married
                  </label>
                </div>
                <div
                  className="form-check "
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    className="form-check-input me-2"
                    type="radio"
                    name="flexRadioDefault5"
                    id="flexRadioDefault4"
                    value="Unmarried"
                    onChange={(e) => setMarried(e.target.value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault4"
                  >
                    Unmarried
                  </label>
                </div>
              </div>
            </div>
            <div className="categories-cast name-field-width-reduce">
              <label htmlFor="categories-cast">Category(Cast)</label>
              <div className="input-group flex-nowrap icon-input-wrapper">
                <select
                  className="form-control icon-wrap"
                  id="categories-cast"
                  onChange={(e) => setCast(e.target.value)}
                >
                  <option value="not selected">Select Option</option>
                  <option value="GENERAL">GENERAL</option>
                  <option value="SC">SC</option>
                  <option value="ST">ST</option>
                  <option value="OBC">OBC</option>
                </select>
                <div>
                  <SlArrowDown className="form-ico" />
                </div>
              </div>
            </div>

            <div className="education name-field-width-reduce">
              <label htmlFor="education">Education</label>
              <div className="input-group flex-nowrap icon-input-wrapper">
                <select
                  className="form-control icon-wrap"
                  id="education"
                  onChange={(e) => setEducation(e.target.value)}
                >
                  <option value="none">None</option>
                  <option value="Under Graduate">Under Graduate</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Post Graduate">Post Graduate</option>
                </select>
                <div>
                  <SlArrowDown className="form-ico" />
                </div>
              </div>
            </div>
            <div className="education-division name-field-width-reduce">
              <label htmlFor="education-division">Division</label>
              <div className="input-group flex-nowrap icon-input-wrapper">
                <select
                  className="form-control icon-wrap"
                  id="education-division"
                  onChange={(e) => setEducationDivision(e.target.value)}
                >
                  <option value="not selected">Select Option</option>
                  <option value="First Division">First Division</option>
                  <option value="Second Division">Second division</option>
                  <option value="Third Division">Third Division</option>
                  <option value="none">None</option>
                </select>
                <div>
                  <SlArrowDown className="form-ico" />
                </div>
              </div>
            </div>
            <div className="intermediate name-field-width-reduce">
              <label htmlFor="intermediate">Intermediate</label>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control"
                  type="text"
                  placeholder="enter your board name"
                  onChange={(e) => setIntermediate(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="education-division name-field-width-reduce">
              <label htmlFor="education-division">Division</label>
              <div className="input-group flex-nowrap icon-input-wrapper">
                <select
                  className="form-control icon-wrap"
                  id="education-division"
                  onChange={(e) => setIntermediateDivision(e.target.value)}
                >
                  <option value="not selected">Select Option</option>
                  <option value="First Division">First Division</option>
                  <option value="Second Division">Second division</option>
                  <option value="Third Division">Third Division</option>
                  <option value="None">None</option>
                </select>
                <div>
                  <SlArrowDown className="form-ico" />
                </div>
              </div>
            </div>
            <div className="highschoole name-field-width-reduce">
              <label htmlFor="highschoole">High School</label>
              <div className="input-group flex-nowrap">
                <input
                  className="form-control"
                  type="text"
                  placeholder="enter your school board name"
                  onChange={(e) => setHighSchool(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="education-division name-field-width-reduce">
              <label htmlFor="education-division">Division</label>
              <div className="input-group flex-nowrap icon-input-wrapper">
                <select
                  className="form-control icon-wrap"
                  id="education-division"
                  onChange={(e) => setHighSchoolDivision(e.target.value)}
                >
                  <option value="not selected">Select Option</option>
                  <option value="First Division">First Division</option>
                  <option value="Second Division">Second division</option>
                  <option value="Third Division">Third Division</option>
                  <option value="None">None</option>
                </select>
                <div>
                  <SlArrowDown className="form-ico" />
                </div>
              </div>
            </div>

            <div className="job-post name-field-width-reduce">
              <label htmlFor="job-post">Apply For</label>
              <div
                className="input-group flex-nowrap border mb-4"
                style={{ cursor: "not-allowed" }}
              >
                <p className="p-2 text-muted fw-bold">{`${postapplyName}`}</p>
              </div>
              <div
                className="input-group flex-nowrap border"
                style={{ cursor: "not-allowed" }}
              >
                <p className="p-2 text-muted fw-bold">{`₹ ${data} -/`}</p>
              </div>
            </div>

            <div className="profile-photo">
              <div>
                <span style={{ color: "green", fontWeight: "700" }}>
                  Upload Only 'JPG', 'JPEG', 'PNG'{" "}
                </span>
              </div>
              <input
                accept=".jpg, .png, .jpeg"
                type="file"
                placeholder="choose your photo"
                onChange={(e) => setProfilePhoto(e.target.files[0])}
                required
              />
              <img
                src={
                  profilePhoto
                    ? URL.createObjectURL(profilePhoto)
                    : "/images/user.png"
                }
                width={100}
                height={80}
                alt=""
              />
            </div>

            <div className="signature-photo">
              <input
                accept=".jpg, .png, .jpeg"
                type="file"
                placeholder="choose your signature"
                onChange={(e) => setSignature(e.target.files[0])}
                required
              />
              <img
                src={
                  signature
                    ? URL.createObjectURL(signature)
                    : "/images/sign.png"
                }
                width={100}
                height={80}
                alt=""
              />
            </div>

            <div className="present-address">
              <h3 className="details-heading-color text-decoration-underline">
                Present Address
              </h3>
              <div className="streetName name-field-width-reduce">
                <label htmlFor="street-name">Street Name</label>
                <div className="input-group flex-nowrap">
                  <input
                    className="form-control"
                    type="text"
                    id="street-name"
                    placeholder="enter your street name"
                    onChange={(e) => setPresentStreetName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="houseno name-field-width-reduce">
                <label htmlFor="street-name">House No</label>
                <div className="input-group flex-nowrap">
                  <input
                    className="form-control"
                    type="text"
                    id="street-name"
                    placeholder="enter your house no"
                    onChange={(e) => setPresentHouseNo(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="village name-field-width-reduce">
                <label htmlFor="street-name">Village</label>
                <div className="input-group flex-nowrap">
                  <input
                    className="form-control"
                    type="text"
                    id="street-name"
                    placeholder="enter your village"
                    onChange={(e) => setPresentVillage(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="city name-field-width-reduce">
                <label htmlFor="street-name">City</label>
                <div className="input-group flex-nowrap">
                  <input
                    className="form-control"
                    type="text"
                    id="street-name"
                    placeholder="enter your city"
                    onChange={(e) => setPresentCity(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="post-office name-field-width-reduce">
                <label htmlFor="street-name">Post-office</label>
                <div className="input-group flex-nowrap">
                  <input
                    className="form-control"
                    type="text"
                    id="street-name"
                    placeholder="enter your post office"
                    onChange={(e) => setPresentPostOffice(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="state name-field-width-reduce">
                <label htmlFor="">State</label>
                <div className="input-group flex-nowrap icon-input-wrapper">
                  <select
                    className="form-control icon-wrap"
                    id="state"
                    onChange={(e) => setPresentState(e.target.value)}
                  >
                    <option value="not selected"> Select State</option>
                    {state?.map((s) => (
                      <option value={s.name} key={s.isoCode}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                  <div>
                    <SlArrowDown className="form-ico" />
                  </div>
                </div>
              </div>
              <div className="district name-field-width-reduce">
                <label htmlFor="street-name">District</label>
                <div className="input-group flex-nowrap">
                  <input
                    className="form-control"
                    type="text"
                    id="street-name"
                    placeholder="enter your district"
                    onChange={(e) => setPresentDistrict(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="pincode name-field-width-reduce">
                <label htmlFor="street-name">Pincode</label>
                <div className="input-group flex-nowrap">
                  <input
                    className="form-control"
                    type="number"
                    id="street-name"
                    placeholder="enter your pincode"
                    onChange={(e) => setPresentPincode(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="checked form-check form-switch">
              <label className="" htmlFor="flexSwitchCheckDefault">
                Same Address As Above
              </label>
              <input
                className="form-check-input checkInput"
                type="checkbox"
                id="flexSwitchCheckDefault"
                onChange={sameAddress}
              />
            </div>

            <div>
              {permanentAddressChecked ? (
                <div className="permanent-address">
                  <h3 className="details-heading-color text-decoration-underline">
                    Permanent Address
                  </h3>
                  <div className="streetName name-field-width-reduce">
                    <label htmlFor="street-name">Street Name</label>
                    <div className="input-group flex-nowrap">
                      <input
                        className="form-control"
                        type="text"
                        id="street-name"
                        placeholder="enter your street name"
                        onChange={(e) => setPermanentStreetName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="houseno name-field-width-reduce">
                    <label htmlFor="street-name">House No</label>
                    <div className="input-group flex-nowrap">
                      <input
                        className="form-control"
                        type="text"
                        id="street-name"
                        placeholder="enter your house no"
                        onChange={(e) => setPermanentHouseNo(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="village name-field-width-reduce">
                    <label htmlFor="street-name">Village</label>
                    <div className="input-group flex-nowrap">
                      <input
                        className="form-control"
                        type="text"
                        id="street-name"
                        placeholder="enter your village"
                        onChange={(e) => setPermanentVillage(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="city name-field-width-reduce">
                    <label htmlFor="street-name">City</label>
                    <div className="input-group flex-nowrap">
                      <input
                        className="form-control"
                        type="text"
                        id="street-name"
                        placeholder="enter your city"
                        onChange={(e) => setPermanentCity(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="post-office name-field-width-reduce">
                    <label htmlFor="street-name">Post-Office</label>
                    <div className="input-group flex-nowrap">
                      <input
                        className="form-control"
                        type="text"
                        id="street-name"
                        placeholder="enter your post office"
                        onChange={(e) => setPermanentPostOffice(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="state name-field-width-reduce">
                    <label htmlFor="">State</label>
                    <div className="input-group flex-nowrap icon-input-wrapper">
                      <select
                        className="form-control icon-wrap"
                        id="state"
                        onChange={(e) => setPermanentState(e.target.value)}
                      >
                        <option value="not selected"> Select State</option>
                        {state?.map((s) => (
                          <option value={s.name} key={s.isoCode}>
                            {s.name}
                          </option>
                        ))}
                      </select>
                      <div>
                        <SlArrowDown className="form-ico" />
                      </div>
                    </div>
                  </div>
                  <div className="district name-field-width-reduce">
                    <label htmlFor="street-name">District</label>
                    <div className="input-group flex-nowrap">
                      <input
                        className="form-control"
                        type="text"
                        id="street-name"
                        placeholder="enter your district"
                        onChange={(e) => setPermanentDistrict(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="pincode name-field-width-reduce">
                    <label htmlFor="street-name">Pincode</label>
                    <div className="input-group flex-nowrap">
                      <input
                        className="form-control"
                        type="number"
                        id="street-name"
                        placeholder="enter your pincode"
                        onChange={(e) => setPermanentPincode(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="state-city-div name-field-width-reduce">
                <div className="state">
                  <label htmlFor="state">Exam Center State</label>
                  <div className="input-group flex-nowrap icon-input-wrapper">
                    <select
                      className="form-control icon-wrap"
                      id="state"
                      onChange={getExamCenterId}
                    >
                      <option value="not selected">Select State</option>
                      {chooseExamCenter?.map((s) => {
                        return (
                          <option key={s._id} id={s._id}>
                            {s.stateName}
                          </option>
                        );
                      })}
                    </select>
                    <div>
                      <SlArrowDown className="form-ico" />
                    </div>
                  </div>
                </div>

                <div className="city">
                  <label htmlFor="city">Exam Center City</label>
                  <div className="input-group flex-nowrap icon-input-wrapper">
                    <select
                      className="form-control icon-wrap"
                      id="city"
                      onChange={(e) => setChooseCity(e.target.value)}
                    >
                      <option value="not Selected">Select City</option>
                      {chooseExamCity?.map((c) => (
                        <option value={c.stateCityName} key={c._id}>
                          {c.stateCityName}
                        </option>
                      ))}
                    </select>
                    <div>
                      <SlArrowDown className="form-ico" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="declaration">
              <h2 className="details-heading-color text-decoration-underline">
                Declaration
              </h2>
              <div className="disclaim-inner-div">
                <input
                  className="form-check-input"
                  type="checkbox"
                  required
                  onChange={declartionFunc}
                />
                <p className="text-muted">
                  I declare that the name, class, date of birth, address and
                  other information given by me in the online application form
                  is correct to the best of my knowledge and belief. Which I
                  declare to be truely correct. If the above information is
                  found incomplete or incorrect, my candidature is liable to be
                  terminated at any time.
                </p>
              </div>
            </div>
            <div className="submit formsubBtn-dv">
              <button
                className="submit-btn btn btn-primary"
                type="submit"
                value="Submit"
                disabled={loading ? true : false}
              >
                Submit Form
              </button>

              <FormPreview prevData={previewData} />
            </div>
            <div>
              {loading ? (
                <>
                  <div className="form-loading-div">
                    <img src="/images/loading.svg" alt="aloding" />
                    <h6>Loading...</h6>
                    <p>
                      Please wait we try to submit & process payment <br />
                      Do not go back or refresh this page
                      <br />
                      You'll be redirect to your application form soon !!
                    </p>
                    <div className="container" style={{ width: "50%" }}>
                      {progress && (
                        <Progress percent={progress} status="active" />
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
