import "./admitcard.css";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getAdmitcard } from "./admitcardApi";

const AdmitcardForm = () => {
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const { isLoading, mutate } = useMutation({
    mutationFn: getAdmitcard,
    onSuccess: (data) => {
      const { admitcard } = data;
      navigate(`/hallticket/${admitcard._id}`, {
        state: admitcard,
      });
      setErrMsg("");
      reset();
    },
    onError: (err) => {
      setErrMsg(`${err.message}.Please provide valid details ⚠️`);
    },
  });

  function onSubmit(data) {
    // console.log("formdata : ", data);
    mutate(data);
  }
  function onError(error) {
    // console.log("Error: ", error);
  }

  const firstName = watch("firstname");
  const lastName = watch("lastname");
  const dob = watch("dob");
  const fatherName = watch("fathername");

  
  const applicationnumber =  watch("applicationnumber")
  const applicationdob = watch("applicationdob")

  const isApplicationNumberDisabled = firstName && lastName && dob && fatherName;

  const isFormDisable = applicationnumber && applicationdob
 

  return ( 
    <>
      <div className="container admitdcard-form-div my-4">
        <div className="shadow">
          <div className="ps-3  admtcrd-head">
            <h4>Admitcard Download</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit, onError)} className="p-3">
            <div className="admtcrd-input-div">
              <label htmlFor="firstname">First Name</label>
              <input
                type="text"
                disabled={isLoading || isFormDisable}
                className="form-control"
                id="firstname"
                placeholder="Enter Candidate Name"
                {...register("firstname", {
                  required: isFormDisable ? false : "This field is required",
                })}
                
              />
            </div>
            {errors?.firstname?.message && (
              <div className="mb-2 errmsg-div">
                <p className="err-msg">{errors?.firstname?.message}</p>
              </div>
            )}
            <div className="admtcrd-input-div">
              <label htmlFor="lastname">Last Name</label>
              <input
                type="text"
                disabled={isLoading || isFormDisable}
                className="form-control"
                id="lastname"
                placeholder="Enter Candidate Name"
                {...register("lastname", {
                  required: isFormDisable ? false : "This field is required",
                })}
              />
            </div>
            {errors?.lastname?.message && (
              <div className="mb-2 errmsg-div">
                <p className="err-msg">{errors?.lastname?.message}</p>
              </div>
            )}
            <div className="admtcrd-input-div">
              <label htmlFor="name">D.O.B</label>
              <input
                type="date"
                disabled={isLoading || isFormDisable}
                className="form-control"
                id="dob"
                placeholder="Enter D.O.B"
                {...register("dob", {
                  required: isFormDisable ? false : "This field is required",
                })}
              />
            </div>
            {errors?.dob?.message && (
              <div className="mb-2 errmsg-div">
                <p className="err-msg">{errors?.dob?.message}</p>
              </div>
            )}
            <div className="admtcrd-input-div">
              <label htmlFor="name">Father Name</label>
              <input
                type="text"
                disabled={isLoading || isFormDisable}
                className="form-control"
                id="fathername"
                placeholder="Enter Father name"
                {...register("fathername", {
                  required: isFormDisable ? false : "This field is required",
                })}
              />
            </div>
            {errors?.fathername?.message && (
              <div className="mb-2 errmsg-div">
                <p className="err-msg">{errors?.fathername?.message}</p>
              </div>
            )}

            <p className="mt-2 text-danger text-center fw-bold">{errMsg}</p>
            <p className="text-center fw-bold mb-2">OR</p>
            <div className="admtcrd-input-div">
              <label htmlFor="applicationnumber">Application No.</label>
              <input
                type="number"
                disabled={isLoading || isApplicationNumberDisabled}
                className="form-control"
                id="applicationnumber"
                placeholder="Enter Application no."
                {...register("applicationnumber", {
                  required: isApplicationNumberDisabled ? false : "This field is required",
                })}
              />
            </div>
            {errors?.applicationnumber?.message && (
              <div className="mb-2 errmsg-div">
                <p className="err-msg">{errors?.fathername?.message}</p>
              </div>
            )}
            <div className="admtcrd-input-div">
              <label htmlFor="name">D.O.B</label>
              <input
                type="date"
                disabled={isLoading || isApplicationNumberDisabled}
                className="form-control"
                id="applicationdob"
                placeholder="Enter D.O.B"
                {...register("applicationdob", {
                  required: isApplicationNumberDisabled ? false : "This field is required",
                })}
              />
            </div>
            {errors?.applicationdob?.message && (
              <div className="mb-2 errmsg-div">
                <p className="err-msg">{errors?.applicationdob?.message}</p>
              </div>
            )}

            <button disabled={isLoading} className="btn btn-warning w-100">
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdmitcardForm;
