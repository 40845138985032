import React, { useState } from 'react'
import './result.css'
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getResult } from './resultAPI';
import CryptoJS from 'crypto-js';

const ResultForm = () => {

    const navigate = useNavigate();

    const [errMsg, setErrMsg] = useState("");

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
      } = useForm();

      const { isLoading, mutate } = useMutation({
        mutationFn: getResult,
        onSuccess: (data) => {
          const { result } = data;
          const encryptedId = encodeURIComponent(CryptoJS.AES.encrypt(result._id, 'cvsdv2312v323ffvwerv242v2v').toString());
          navigate(`/result/${encryptedId}`, {
            state: result,
          });
          setErrMsg("");
          reset();
        },
        onError: (err) => {
          setErrMsg(`${err.message},Please provide valid details ⚠️`);
        },
      });

      function onSubmit(data) {
         console.log("formdata : ", data);
         mutate(data);
      }
      function onError(error) {
        // console.log("Error: ", error);
      }


      const rollNo =  watch('rollno')
      const dob =  watch('dob')

      const applicationNo =  watch('appno')
      const applicationDOB =  watch('appdob')


      const isRolnoExist = rollNo && dob
      const isApplicationnoExist =  applicationNo && applicationDOB


  return (
    <div className='container'>
        <div className='main-body '>
            <div className='main-title-div'>
                <p>Result Declared by NIA Aviation Services Pvt. Ltd. <br/>Result of Customer Service Associate (CSA) <br /> (ADVERTISEMENT NO. 01/NIA-CSA/2023-24) <br /></p>
            </div>
            <div className='title-div-tp'>
                <p>Please enter your <b>Roll Number and Date of Birth</b></p>
            </div>

           <div className='result-form'>
           <form onSubmit={handleSubmit(onSubmit, onError)}>
           <div className='input-containers-fields'>
            <label htmlFor="rollno">Roll Number *</label>
            <input type="number" id='rollno' className='form-control' placeholder='Enter roll no.' {
                ...register("rollno",{
                    required: isApplicationnoExist ? false  :  "This field is required"
                })
            } disabled={isLoading || isApplicationnoExist} />
            
            </div>
            {errors?.rollno?.message && (
              <div className="mb-2 errmsg-div">
                <p className="err-msg">{errors?.rollno?.message}</p>
              </div>)}
            <div className='input-containers-fields'>
            <label htmlFor="dob">Date Of Birth *</label>
            <input type="date" id='dob' className='form-control' {
            ...register("dob",{
                required: isApplicationnoExist ? false  :  "This field is required"
            })
            } disabled={isLoading || isApplicationnoExist}/>
            </div>
            {errors?.dob?.message && (
              <div className="mb-2 errmsg-div">
                <p className="err-msg">{errors?.dob?.message}</p>
              </div>
            )}
            <div className='text-center mb-2'><b className='text-danger'>OR</b></div>
            <div className='title-div-tp'>
                <p>Please enter your <b>Application Number and Date of Birth</b></p>
            </div>
            <div className='input-containers-fields'>
            <label htmlFor="appno">Application Number *</label>
            <input type="number" id='appno' className='form-control' placeholder='Enter roll no.' {
                ...register("appno",{
                    required: isRolnoExist ? false : "This field is required"
                })
            } disabled={isLoading || isRolnoExist}/>
            </div>
            {errors?.appno?.message && (
              <div className="mb-2 errmsg-div">
                <p className="err-msg">{errors?.appno?.message}</p>
              </div>
            )}
            <div className='input-containers-fields'>
            <label htmlFor="appdob">Date Of Birth *</label>
            <input type="date" id='appdob' className='form-control' {
                ...register("appdob",{
                    required: isRolnoExist ? false : "This field is required"
                })
            } disabled={isLoading || isRolnoExist}/>
            </div>
            {errors?.appdob?.message && (
              <div className="mb-2 errmsg-div">
                <p className="err-msg">{errors?.appdob?.message}</p>
              </div>
            )}
            <div className='mb-2'>
            <p className="mt-2 text-danger text-center fw-bold">{errMsg}</p>
            </div>
            <div>
                <button className='btn btn-warning w-100'>{isLoading? 'Loading...': 'Submit'}</button>
            </div>
           </form>
           </div>
        </div>
    </div>
  )
}

export default ResultForm
