import React from 'react'
import '../css/policies.css'

const Shipping = () => {
  return (
    <>
        <div className='termmaincontainer-div pt-3 pb-3'>
            <div className='container shadow p-2'>
                <div className='termcn-title-div'>
                    <h1>Shipping & Delivery </h1>
                </div>

                <div className='iner-terncnd-cnt-dv'>
                <p><b>All candidates are informed that NIA Aviation Services will send a copy of their application form and invoice to all the candidates through shipping and delivery company email to the candidate's registered email.</b></p>
                <p><u><b>We are collecting a sufficient amount from all the candidates for this post. After enrolling for the profile, PDF of the application will be sent to the mail of all the candidates along with the invoice for payment.</b></u></p>
                </div>

                <div className='termcnd-list'>
                    <ul>
                       <li>NIA Aviation Services, after filling the application form and making payment to all its registered candidates, the company delivers the filled application form of the candidate and an invoice copy of the payment made by the company to the candidate through its website. This application form and The copy of the invoice also validates the shipping and delivery by the company.</li>
                       <li>NIA Aviation Services Company does not provide any physical item for shipping and delivery to any of its candidates. The Company accepts only the completed application form and copy of the invoice of its candidate as the property of shipping and delivery and this application is the same. The form and payment invoice only will be considered as shipping and delivery property for the candidate. The Company disclaims any claims of any kind or nature of the candidate and asks the candidate to endorse only his/her application form and copy of the invoice.</li>
                       <li>After filling the application form and making the payment, the candidate himself/herself ensures that the copy of the invoice along with the candidate's application form and application PDF is received by the Company in the form of shipping and delivery of his/her application form. And the copy of the invoice has been received by him. The company sends the application form and payment invoice to the candidate through his registered email address.</li>
                       <li>After the candidate fills out the application form and makes the payment, the company sends a copy of the same and the payment invoice to the candidate's registered email within a few moments. Sometimes due to a busy server, the company sends a copy of the candidate's application form and invoice. With some delay or delay of days or weeks, the company sends a copy of the application form and invoice to the candidate's registered email ID and the company requests the candidate to send this copy of the invoice and the application form to its shipping and delivery property.</li>
                    </ul>
                </div>
                <div className='termcnd-list'>
                    <div className='termcn-title-div'>
                    <h1>Pricing: (Required)</h1>
                    </div>
                    <ul>
                       <li>NIA Aviation Services informs all the candidates that the company will charge an examination fee of ₹ 410 from all the candidates as profile/ post. All the candidates are also informed that after filling out their application form, the candidates will have to pay an examination fee of ₹ 410. Candidates can make this payment through their payment method like debit card, credit card, UPI, etc.</li>
                       <li>Before the candidates can apply for the profile or post given on the website, the candidate will have to create an account on the website of NIA Aviation Services. After creating the account, the company will send the candidate's registration number and other details to his registered email. Using this, the candidate will log in to the website and will be able to apply for the post. The candidate will have to fill out his application form. After filling out the application form, an examination fee of ₹ 410 will be charged by the company. The candidate will have to deposit that fee through any means. You will be able to use the payment method of  ₹ 410. Payment will be required by the company. Without payment, the candidate cannot submit his application form to the company. To submit the application form, the candidate will be required to deposit a fee of ₹ 410.</li>
                       <li>Customer Service Associate (CSA)  Amount:  ₹ 410 </li>
                    </ul>
                </div>

            </div>
        </div>
    </>
  )
}

export default Shipping