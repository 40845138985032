import React from 'react'
import {Helmet} from 'react-helmet';
import '../css/otherpages.css'

const PrivacyRefund = () => {
  return (
    <>
     <Helmet>
      <meta charSet='UTF-8'/>
      <title>NIA Aviation Services</title>
      <meta name='description' content='NIA Aviation Services Pvt. Ltd.
In essence, NIA Aviation Services Pvt. Ltd. trains potential employees in the aviation industry.
Notice- If someone or any organisation uses the NIA Aviation Services Pvt. Ltd. name, logo, or spreads untrue information about the company, legal action will be taken.
Candidates are informed that NIA Aviation Services Pvt. Ltd. has no connection to the money required by any specific individual to pass the exam or the interview; if a candidate agrees to it, he or she will be responsible for paying it.' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
    </Helmet>

<div className='bg-light main-wrapper-container'>
    <div className='innerpolicywrapper container shadow-sm'>
      <div className='policy-title-div'>
        <h1>Company Privacy Policy</h1>
      </div>
      <div className='wrapper-inner'>
        <h1>Company details:-</h1>
        <ul>
          <li><b>Name of the Company –</b> NIA Aviation Services Pvt. Ltd.</li>
          <li>In essence, NIA Aviation Services Pvt. Ltd. trains potential employees in the aviation industry.</li>
          <li><b>Notice-</b> If someone or any organisation uses the NIA Aviation Services Pvt. Ltd. name, logo, or spreads untrue information about the company, legal action will be taken.</li>
          <li>Candidates are informed that NIA Aviation Services Pvt. Ltd. has no connection to the money required by any specific individual to pass the exam or the interview; if a candidate agrees to it, he or she will be responsible for paying it.</li>
        </ul>

        <h1>General:-</h1>
        <p>The Company reserves the right to change, add, remove, or modify (in whole or in part) any of the terms and conditions of any Promotions or Offers, or to completely replace them with other Promotions or Offers, whether or not they are similar to the current Promotions or Offers. The Company's decision in this regard shall be final and shall not be subject to any notification to the Candidates.</p>
        <br />
        <p>The court in New Delhi, India would have exclusive jurisdiction over all issues.</p>

        <h1>Collection of personal details:-</h1>
        <p>No personally identifiable information provided voluntarily on the NIA Aviation Services Pvt.Ltd. website is sold or otherwise made available to unaffiliated (public or private) third parties. The data submitted to this website will be safeguarded against loss, unauthorised access or disclosure, abuse, modification, or destruction.</p>
        <br />
        <p>You will be made aware of how any additional Personal Information will be used if you choose to provide it if it is requested of you. Please contact the webmaster via the contact us page if you ever feel that the guidelines outlined in this privacy statement have not been followed or if you have any additional comments on them.</p>

        <h1>Privacy Policy:-</h1>
        <p id='prunderline'>The Policy explains our rules for the gathering, using, and disclosing of your personal information as well as all the reasonable security measures we take to protect it.</p>
        <ul>
          <li>You consent to being bound by the current provisions of this Policy as amended from time to time, subject to your legal rights. We reserve the right to change our Policy, therefore we encourage you to frequently review the most recent version.</li>
          <li>The Personal Data we collect includes Personal Data obtained from thewellnessstore.in and its associated domains and subdomains, as well as through its mobile application. This Privacy Policy is applicable to all Personal Data we collect.</li>
          <li>You agree to the collection, use, and disclosure of your personal information in accordance with this policy, as it may be updated from time to time by us, and to the transfer of that information to other businesses or entities within the NIA by using our website and/or providing your personal information to order our products and use our services.</li>
          <li>Any changes to the Policy will be posted on our website. Please refrain from accessing this Website or submitting Personal Data if you do not agree to the conditions of this Policy</li>
          <li>The terms of this Policy do not affect any agreements you might have with NIA, which would supersede the terms of this Policy.</li>
          <li>"Personal data" refers to information about an individual who can be identified I from that information alone or (ii) from that information and other data to which NIA has or is likely to have access. Your name, contact information, email address, residential address, payment information, and other personal data that NIA may need in order to deliver our services or Website to you are a few examples of the types of personal data that may be collected by NIA.</li>
          <li>NIA does not gather, utilise, or process personal data unless you freely do so. We enforce rigorous security and confidentiality standards for our employees, and we promise to completely abide by industry-recognized privacy protection standards when using, disclosing, and processing your personal data.</li>
        </ul>
        <p id='prunderline'>In general, we may gather, use, and disclose your Personal Data for any or all of the reasons listed below:</p>
        
        <div className='ordelist-div'>
        <ol>
          <li>To confirm your identity.</li>
          <li>To stay in touch with you and let you know of any NIA news or ongoing specials.</li>
          <li>To modify and customise the website's content.</li>
          <li>To assess your preferences and make it easier for future Website updates.</li>
          <li>To oversee our internal operations and infrastructure, including—but not exclusively—where necessary to ensure adherence to internal rules and regulations.</li>
          <li>To examine, review, and audit our internal procedures.</li>
          <li>To stop, spot, and/or look into fraud, crime, and criminal activities.</li>
          <li>To uphold and defend our legal and contractual rights and responsibilities.</li>
          <li>To respond to information requests from public or governmental organisations, regulatory agencies, or other organisations with similar powers that are authorised to perform certain government functions.</li>
          <li>To make sure we abide by all pertinent laws, rules, regulations, and codes of conduct.</li>
          <li>Any additional goals connected to the ones listed above, or those that may be brought to your attention and receive your approval at the time of collecting or using the personal data.</li>
        </ol>
        </div>
        <ul>
          <li>Moreover, NIA automatically gathers and logs information from your browser, such as your IP address, cookie data, and the page you requested, on our server logs. Please consult the help and support section of your Internet browser for instructions on how to find the file or directory that holds cookies if you wish to remove any existing cookies from your computer.</li>
          <li>Unless where forced to do so for legal or regulatory purposes, NIA will never sell, rent, share, or otherwise disseminate or make available your Personal Data to other parties outside the NIA group of companies or affiliates. Your personal information might, however, occasionally be given to third parties who work for and on behalf of NIA for additional processing in line with the original purposes for which it was obtained. The NIA shall ensure that such third parties provide the same level of protection as NIA and that they are required to process your Personal Data transferred only for the purposes authorised by you and on a confidential and secure basis when disclosure of your Personal Data to third parties is likely or necessary for any reason.</li>
          <li>The majority of the data we gather from our website is anonymous data. We do not gather any personal information about you when you visit our website unless you directly, intentionally, and willingly disclose it to us. NIA processes anonymous information to help us improve the content of our website, to better serve our visitors, and to better understand them and how they use it. To achieve this, we might employ tracking technologies (cookies) to collect anonymous data about your browser and operating system as well as the time and date of your access. Please visit our Cookies Policy at Cookies Policy to view our cookies policy.</li>
          <li>NIA doesn't engage in "spamming". Spamming is the practise of frequently and in bulk sending unsolicited commercial emails to recipients who have either declined to receive them or with whom the sender has had no prior contact.</li>
          <li>Links to websites or mobile applications not run or under the control of NIA may be provided on our website as a courtesy to our visitors. As a result, we are not liable or responsible for the information included on such websites, mobile applications, or other third parties. Please be aware that our Policy may not apply to their privacy practises. Before giving them any personal information, we advise you to research and understand their privacy standards.</li>
          <li>You agree to submit to the exclusive jurisdiction of the courts in New Delhi and that this Policy and the use of the Website shall be governed in all respects by the laws of India, without giving effect to any principles of conflict of law.</li>
          <li>Any argument, disagreement, controversy, or claim between you and NIA relating to this Policy must first be brought to their attention. You commit to taking part in the mediation in good faith and to upholding the conditions of any agreement made.</li>
        </ul>
        <h1>Refund & Cancellation Policy:-</h1>
        <p><b>After payment, if the registration number is not provided to you in the given time and you are not able to sit in the examination. Only in that case, we will refund the amount within 7 to 10 days.</b></p>
        <h1>Term & conditions:-</h1>
        <p id='prunderline'>We appreciate your use of a computer, tablet, or mobile phone to access our website. The company NIA Aviation Service Pvt.Ltd. runs this website.</p>
        <p id='prunderline'>An agreement between our candidates and NIA is made legally binding by the terms and conditions listed below.</p>
        <ul>
          <li>The data processing, including the gathering, use, disclosure, and transfer of any personal information we obtain about you or that you voluntarily supply to us, is governed by the Privacy Policy and Cookie Policy on this website. You also consent to be legally bound by the terms of the Privacy and Cookie Policies by using this website.</li>
          <li>NIA maintains the right, at any time, and without previous notice to you, to change, add to, vary, or amend all or a portion of these Terms. When any modifications are made on the website, if you use the website or any of the services offered on it, it will be assumed that you have accepted the terms as updated.</li>
          <li>NIA reserves the right to take any action it deems necessary, including terminating your use of the Website without notice and, in the case of illegal use, initiating legal proceedings, in the event that NIA determines, in its sole discretion, that you are making any illegal and/or unauthorised use of the Website or that your use of the Website is in violation of these Terms</li>
          <li>There is no way to guarantee the security of data communications via the Internet or over wireless networks. As a result, you transmit any information and/or communication to NIA at your own risk because NIA cannot and does not guarantee the security, validity, integrity, or confidentiality of such information and/or communication.</li>
          <li>You consent to NIA using the Personal Information you voluntarily supply so that proper anti-fraud checks can be carried out. A credit reference or fraud prevention agency may receive the personal information you supply, and the agency may keep a record of the information.</li>
          <li>You may only use this website for noncommercial, personal purposes. It is prohibited to alter, duplicate, distribute, transmit, perform, display, reproduce, publish, licence, commercially exploit, transfer, sell, or create derivative works from any of the information, software, goods, or services found on this website. You are not permitted to use this website or any of its contents for any commercial endeavours, including any advertising or revenue-generating efforts on your own website.</li>
          <li>Fire, accident, any law, order, proclamation, regulation, demand, or requirement of the Government of India or of any of its government agencies, strikes, labour disputes, a lack of skilled labour, a lack of labour, a lack of products or raw materials, a delay in transit, or any other causes (whether related to the foregoing or not) beyond NIA's reasonable control.</li>
          <li>War or armed conflict or acts of terrorism, which include disruption of civil authority, transportation, and communication services.</li>
          <li>Act of God (i.e. extraordinary or unforeseeable manifestation of the forces of nature beyond the powers of human intervention, such as tornadoes, hurricanes, typhoons, and earthquakes).</li>
          <li>No other person, whether under the Indian Contract Act of 1872 or any other legislation, shall have any rights to enforce or benefit from any provision of these Conditions, with the exception of all affiliates of NIA. Any modification of these Terms, including any release or compromise of any liability under them, may be made without the approval of any third party, notwithstanding anything else in these Terms.</li>
          <li>The laws of India shall govern your use of this website and these terms, and those laws shall be interpreted accordingly.</li>
        </ul>
      </div>
    </div>

    </div>
    </>
  )
}

export default PrivacyRefund