import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { MdDeleteForever } from "react-icons/md";

const NotificationAlerts = () => {
  const latestUpdatesurl = `${process.env.REACT_APP_HOSTURL}/api/v1/notificationalert`;
  const uploadNotificationUrl = `${process.env.REACT_APP_HOSTURL}/api/v1/notificationalert`;

  const [latestAlertData, setLatestAlertData] = useState([]);
  const [listContent, setContent] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const fetchLatestAlert = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axios.get(latestUpdatesurl);

      setLatestAlertData(res.data.data);
      setLoading(false);
    } catch (err) {
      setError(err.response.data.message);
      setLoading(false);
    }
  }, [latestUpdatesurl]);

  useEffect(() => {
    fetchLatestAlert();
  }, [fetchLatestAlert]);

  const uploadNotification = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(uploadNotificationUrl, {
        list: listContent,
      });

      setLatestAlertData((prev) => [res.data.data, ...prev]);
      setContent("");
      setError("");
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  async function deleteAlertsNotification(e) {
    e.preventDefault();
    const id = e.currentTarget.id;
    try {
      setLoading(true);
      const res = await axios.delete(`${latestUpdatesurl}/${id}`);
      if (res.data.statusCode === 200) {
        const newData = latestAlertData.filter((d) => d._id !== id);
        console.log(newData);
        setLatestAlertData(newData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <section className="container">
        <h2 className="reg-title-div">Update Notification Alert</h2>
        <div className="row">
          <div className="col-lg-5">
            <div className="inner-notify-alert-form  m-3 shadow-sm">
              <form onSubmit={uploadNotification}>
                <label htmlFor="">Enter Notifications</label>
                <textarea
                  className="form-control"
                  name=""
                  id=""
                  cols="30"
                  rows="4"
                  required={true}
                  placeholder="Type here..."
                  onChange={(e) => setContent(e.target.value)}
                  value={listContent}
                ></textarea>
                <button
                  type="submit"
                  className="btn btn-warning mt-4"
                  disabled={loading ? true : false}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="inner-notify-div-right-div shadow-sm">
              <div className="fs-2 text-center text-capitalize text-muted fw-bold">
                {error}
              </div>
              {loading ? (
                <div className="d-flex align-content-center justify-content-center">
                  <div
                    className="spinner-border m-4 text-primary"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div>
                  {latestAlertData?.map((d, i) => {
                    return (
                      <div
                        className="alert alert-warning notify-list-div"
                        role="alert"
                        key={i}
                      >
                        <p className="notify-list-cnt">{d.list}</p>
                        <MdDeleteForever
                          className="deleted-notify-icon"
                          id={d._id}
                          onClick={deleteAlertsNotification}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotificationAlerts;
