import React from 'react'
import {Helmet} from 'react-helmet';

const BecameCabinCrew = () => {
  return (
    <>
       <Helmet>
     
      <title>Became cabin crew</title>
      <meta charSet='UTF-8'/>
        <meta name="keywords" content="Aviation, Aviation Jobs, Noida Airport, Noida International Aviation Services, NIAS, N.I.A.S, airport jobs, graound staff jobs"/>
        <meta name="description" content="The cabin crew always has to make sure of the passengers inside the aircraft and the passenger does not have any kind of problems inside the aircraft and the cabin crew always has to satisfy their passengers inside the aircraft. Along with taking care of the health of the passengers, the cabin crew also has to take care of the safety measures for the passenger"/>
        <meta name="author" content="Nia aviation services pvt ltd"/>
        <meta name="refresh" content="50"/>
        <meta http-equi="refresh" content="" url="www.niaaviation.com"/>
    </Helmet>

      <div className='container-fluid  top-banner-pg-div-cabin-crew'>
          <div className='inner-pg-wrapper-div'>
              <div className='container ot-page-banner-heading'> 
                  <h1>Became cabin crew</h1>
                  <p>Home / became cabin crew</p>
              </div>
          </div>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/became-cabin-crew/bcc1.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/became-cabin-crew/bcc2.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Cabin Crew Members</h3>
              <p> The demand for trained workers is rising along with the demand for air travel in India, which is one of the world's top aviation markets. Airlines are constantly looking for highly qualified individuals to work as cabin crew. In order to become a member of the cabin crew, candidates must possess the theoretical knowledge, professional grooming, and total personality development that NIA Aviation Services Pvt. Ltd. provides. At the moment, some of the top domestic and international airlines have hired some of our prospects.
               <br />
               <br />
               Flight attendants make decisions based on orders supplied by pilots or co-pilots outside the cockpit. On a flight, they are also in responsibility of the passengers' safety and comfort, and they update the passengers as needed. While female flight attendants are known as hostesses, male flight attendants are referred to as hosts. The selection of cabin crew must follow specific guidelines established by aviation organisations, and they must also abide by guidelines established by the General Directorate of Civil Aviation.
            
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container ot-page-cnt-form-div'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='ot-page-cnt-leftidv'>
            <h3>Cabin Crew Position</h3>
              <p>You'll ensure the comfort and safety of the passengers as a member of the cabin crew by giving them outstanding customer service during the journey. Together with providing travellers with beverages and meals, you'll also sell them gifts and duty-free goods.
               <br />
               <br />
               <b>Purser</b> Senior flight attendant is called the purser, he is the chief flight attendant inside the flight who takes care of other flight attendants and finally the purser submits all the reports of the flight to the airline company, including the health of the passengers and their Safety majors are taken full care of.You'll receive training to handle security and emergency situations, which may involve providing first aid to passengers, as part of your work.
               <br />
               <br />
             <b>Flight Attendant</b>
                Flight attendants are known as stewards inside an airline. Flight attendants also help passengers understand and speak their languages. Flight attendants serving food to passengers, serving beverages, and ensuring and fulfilling pilot's orders are all the functions of a flight attendant inside the airline.Also, it is your responsibility to guarantee that there are enough supplies on board and that all emergency equipment is operational before takeoff. You'll then participate in a safety demonstration to ensure that passengers are aware of the equipment's usage and safety protocols.
               </p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/became-cabin-crew/bcc3.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/became-cabin-crew/bcc4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img-div-bccmd1'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
         
          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right-btm'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>What jobs are assigned to the cabin crew?</h3>
              <p>The space between the cockpit door and the back kitchen is under their control. Outside of the aircraft or in the cockpit, the cabin crew is not in charge.
               <br />
               <br />
               <b>*</b>   Both routine and urgent issues can be handled by the cabin staff.
               <br />
               <br />
               <b>*</b>     Hosts and hostesses are also entrusted with the responsibility of opening the emergency exit door in case of an emergency. The seats nearest to the emergency exit doors are also regarded to be occupied by passengers, and they are held accountable.
               <br />
               <br />
               <b>*</b>    The responsibility of the cabin crew differs from company to company when the appropriate goods need to be loaded into the aircraft.
               <br />
               <br />
               <b>*</b>    Depending on the companies, hosts' and hostesses' job descriptions for serving responsibilities differ. On some airlines, the cabin crew attends to all passenger requests, whereas on others, they are only allowed to begin serving passengers from the buffet at specific hours.
               <br />
               <br />
               <b>*</b>    The primary responsibility of the cabin crew is to instruct passengers verbally and visually on what to do in an emergency
               <br />
               <br />
               <b>*</b>    Hosts and hostesses are also required to give travellers the information they need to know about the trip.

               </p>
             </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left-btm'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/became-cabin-crew/bcc5.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/became-cabin-crew/bcc6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img2-div-bccmd2'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/became-cabin-crew/bcc7.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/became-cabin-crew/bcc8.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Get customer service experience</h3>
              <p>Cabin crew jobs for an airline may require skills and experience in customer service. While doing the work of cabin crew, they have to take care of all types of passenger feedback, in which it has to be ensured that there is no inconvenience to the passengers.
               <br />
               <br />
          <b>Communication Skills: </b> Customer Service Experience has to serve one's best communication skills and interact very well with the customers.
               <br />
               <br />
               <b>Conflict resolution:</b>  The cabin Crew is required to resolve all conflicts between the passengers and the flight attendant while serving the customer.
               <br />
               <br />
               <b> Leadership:</b> While working as Cabin Crew, the Flight Attendant Leader improves the ship quality so that there is no problem in solving the problems ahead
               <br />
               <br />
               <b>Teamwork:</b> Customer service jobs can give you experience collaborating with others. You can use this experience when working with other cabin crew members.
               </p>
             </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BecameCabinCrew