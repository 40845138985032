import React from 'react'
import {Helmet} from 'react-helmet';

import ContactForm from '../ContactForm';

const EligibilityExamForAviation = () => {
  return (
    <>
       <Helmet>
      <meta charSet='UTF-8'/>
      <title>Eligibility criteria in aviation</title>
      <meta name='description' content='paste your same description which is used on the same page' />
      <meta name='keywords' content='Nia aviation private limited , airport ground staff, nia aviation, job at airport' />
    </Helmet>

      <div className='container-fluid  top-banner-pg-div-eligibility'>
          <div className='inner-pg-wrapper-div'>
              <div className='container ot-page-banner-heading'>
                  <h1>Eligibility & Etrance For Exam</h1>
                  <p>Home / eligibility & entrance for exam</p>
              </div>
          </div>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/eligibility-exam-img/ee1.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/eligibility-exam-img/ee2.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Eligibility criteria for the course</h3>
              <ul>
                <li>10+2 (In Any Stream)</li>
                <li>Height: as per airline standard policy</li>
                <li>Physical Features</li>
                <li>Age minimum 18 year</li>
                <li>No visible tattoos/scars</li>
                <li>Clear skin complexion</li>
              </ul>
              <h3>What are the Requirements to Become a Flight Attendant?</h3>
              <p>You must fulfil a number of prerequisites in order to work as a flight attendant. There is a list of "Minimum Qualifications to Become a Cabin Attendant" issued by the General Directorate of Civil Aviation, despite the fact that these requirements vary between firms. In addition to these, depending on your job zone, it is also expected that you speak one or more foreign languages.

                <br/>
                <br/>

              </p>
             </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container ot-page-cnt-form-div'>
        <div className='row'>
          <div className='col-lg-7'>
            <div className='ot-page-cnt-leftidv'>
            {/* <h3>Put Heading Here...</h3> */}
              <p>
              Hours of labour The shifts that cabin crew members work are sometimes unpredictable and unsociable. This may entail working in the wee hours of the night, on weekends, and on legal holidays. Some airlines have varying work schedules from week to week. Long-haul flights may offer fewer regular hours than short-haul flights.
               <br />
               <br />
               If your return trip is cancelled or delayed, you might occasionally need to work or be flexible on your days off, but in these situations, you'll be paid.
There are opportunities for part-time work, but they still require irregular hours.
              
               </p>
            </div>
          </div>
          <div className='col-lg-5'>
            <div className='ot-page-cnt-rightidv border'>
              <ContactForm className='ot-pg-form-right'/>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img-div-eemd1'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
         
          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right-btm'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>What to expect</h3>
            <ul>
              <li>Airlines that serve the package vacation industry typically hire flight attendants on a seasonal basis.</li>
              <li>Several airlines have a requirement that employees live close to the airport or within easy driving distance. Staff members may need to be on call for work at short notice, therefore flexibility is essential. Also, you might reside abroad.</li>
              <li>The task can be challenging because cabin crew members frequently struggle with jet lag and fatigue when travelling between different time zones. You'll have to stand for long periods of time while working in small places. Even while dealing with difficult passengers, you'll need to keep a good attitude.</li>
              <li>The airline will give you a uniform, and you'll be required to always dress formally and maintain good grooming.</li>
              <li>Depending on the airline you work for and whether you work on short- or long-haul flights, the amount of time spent away from home varies. While long-haul flights necessitate spending nights away from home, short-haul flights usually allow you to return the same day.</li>
              <li>A diversity of people from various origins and cultures will be your coworkers. Even if it could just be for a few hours or one evening in certain situations, long-haul travellers will get to experience the places they are travelling to. Short-haul flights usually have a short turnaround time of 45 minutes to 2 hours before returning. You won't have time to visit any of the destination at that time because the aircraft will be getting ready for the flight.</li>
            </ul>
             </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left-btm'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/eligibility-exam-img/ee4.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/eligibility-exam-img/ee5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid background-attach-fix-img2-div-eemd2'>
      </div>

      <div className='container ot-pg-cnt-main-div'>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='ot-page-top-cnt-inner-left'>
              <div className='ot-page-post-top'>
                <img className='ot-page-top-pos1-img' src="/eligibility-exam-img/ee7.png" alt="" />
              </div>
              <div className='ot-page-post-bottom'>
                 <img className='ot-page-top-pos1-img' src="/eligibility-exam-img/ee6.png" alt="" />
              </div>
            </div>
          </div>

          <div className='col-lg-8'> 
            <div className='ot-page-top-cnt-inner-right'>
             <div className='ot-pg-put-right-top-cnt'>
              <h3>Skills</h3>
              <h3>Your ability to demonstrate:</h3>
             <ul>
              <li>Outstanding communication abilities</li>
              <li>First-rate client service</li>
              <li>Assurance when interacting with a variety of people</li>
              <li>Excellent teamwork abilities because you'll frequently collaborate with other teams.</li>
              <li>Empathy and the capacity to assist your coworkers.</li>
              <li>Tact when interacting with VIPs.</li>
              <li>The capability to handle challenging circumstances and maintain composure under pressure and in emergency situations.</li>
              <li>The talent for communicating with passengers diplomatically while still being aggressive when necessary.</li>
              <li>Sales expertise and business acumen.</li>
              <li>The ability to work irregular hours at any time of the year.</li>
              <li>The ability to operate swiftly and effectively under pressure, frequently. </li>
              <li>The ability to work in a small space.</li>
              <li>Numeracy abilities for managing cash, including foreign currencies.</li>
              <li>The capability to diffuse conflict gently and promptly.</li>
             </ul>
             </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EligibilityExamForAviation