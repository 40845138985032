import React from 'react'
import '../css/policies.css'

const Termandcondition = () => {
  return (
    <>
        <div className='termmaincontainer-div pt-3 pb-3'>
            <div className='container shadow p-2'>
                <div className='termcn-title-div'>
                    <h1>Term & conditions</h1>
                </div>

                <div className='iner-terncnd-cnt-dv'>
                <p><u>We appreciate your use of a computer, tablet, or mobile phone to access our website. The company NIA Aviation Service Pvt.Ltd. runs this website.</u></p>
                <p><u>An agreement between our candidates and NIA is made legally binding by the terms and conditions listed below.</u></p>
                </div>

                <div className='termcnd-list'>
                    <ul>
                        <li>The data processing, including the gathering, use, disclosure, and transfer of any personal information we obtain about you or that you voluntarily supply to us, is governed by the Privacy Policy and Cookie Policy on this website. You also consent to be legally bound by the terms of the Privacy and Cookie Policies by using this website.</li>
                        <li>NIA maintains the right, at any time, and without previous notice to you, to change, add to, vary, or amend all or a portion of these Terms. When any modifications are made on the website, if you use the website or any of the
                        services offered on it, it will be assumed that you have accepted the terms as
                        updated.</li>
                        <li>NIA reserves the right to take any action it deems necessary, including
                        terminating your use of the Website without notice and, in the case of illegal use, initiating legal proceedings, in the event that NIA determines, in its sole discretion, that you are making any illegal and/or unauthorised use of the Website or that your use of the Website is in violation of these Terms.</li>
                        <li>There is no way to guarantee the security of data communications via the Internet or over wireless networks. As a result, you transmit any information and/or communication to NIA at your own risk because NIA cannot and does not guarantee the security, validity, integrity, or confidentiality of such
                        information and/or communication.</li>
                        <li>You consent to NIA using the Personal Information you voluntarily supply so
                        that proper anti-fraud checks can be carried out. A credit reference or fraud prevention agency may receive the personal information you supply, and the agency may keep a record of the information.</li>
                        <li>You may only use this website for noncommercial, personal purposes. It is prohibited to alter, duplicate, distribute, transmit, perform, display, reproduce,
                        publish, licence, commercially exploit, transfer, sell, or create derivative works from any of the information, software, goods, or services found on this website. You are not permitted to use this website or any of its contents for any commercial endeavours, including any advertising or revenue-generating efforts on your own website.</li>
                        <li>Fire, accident, any law, order, proclamation, regulation, demand, or requirement of the Government of India or of any of its government agencies,
                        strikes, labour disputes, a lack of skilled labour, a lack of labour, a lack of products or raw materials, a delay in transit, or any other causes (whether related to the foregoing or not) beyond NIA's reasonable control.</li>
                        <li>War or armed conflict or acts of terrorism, which include disruption of civil authority, transportation, and communication services.</li>
                        <li>Act of God (i.e. extraordinary or unforeseeable manifestation of the forces of nature beyond the powers of human intervention, such as tornadoes, hurricanes, typhoons, and earthquakes).</li>
                        <li>No other person, whether under the Indian Contract Act of 1872 or any other legislation, shall have any rights to enforce or benefit from any provision of these Conditions, with the exception of all affiliates of NIA. Any modification of these Terms, including any release or compromise of any liability under them, may be made without the approval of any third party, notwithstanding anything else in these Terms.</li>
                        <li>The laws of India shall govern your use of this website and these terms, and those laws shall be interpreted accordingly.</li>
                        <li>All the candidates are enrolling on this website of their own free will and no pressure of any kind is being put on the candidates by the company.</li>
                        <li>After enrolling on the website, PDF of application form and payment invoice will be sent to all the candidates on their registered mail.</li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default Termandcondition