import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from "react-redux";
import { Tooltip } from 'antd';

const UpperBnr = () => {

const userExist = useSelector((state)=> state.user.user)

    
  return (
    <>
        <div className='bnr-md-nwhm-main-container'>
            <div className='container'>
                <div className='md-inr-bnr-wrapper'>
                <div className='md-bnr-register-dv'>
                        <img className='btm_mdbnr-imng' src="/images/nia-form.svg" alt="register" />
                        <div className='register-main-bnr-inr-div'>
                        <h1>Apply For <br/>Customer Service Associate (CSA)</h1>
                        {
                            userExist ? (
                                <>
                                <Tooltip placement="top" title="Application Submission Date Is Over">
                                  <h1 id='register_text' className='blink registe_txt_upr'>APPLY NOW !</h1>
                                </Tooltip>
                                  {/* <h1 id='register_text' className='blink registe_txt_upr'><Link to={'/register-now'}>APPLY NOW !</Link></h1> */}
                                  <br/>
                                  {/* <h1 style={{fontSize:'13px'}} id='admitcard_text' className='blink registe_txt_upr'><Link to={'/getadmitcard'}><u >Download Admit Card</u>&nbsp;<span className='new-post'>new</span></Link></h1> */}
                                </>
                            ) : (
                                <>
                                 <Tooltip placement="top" title="Application Submission Date Is Over">
                                <h1 id='register_text' className='blink registe_txt_upr'>REGISTER NOW !</h1>
                                 </Tooltip>
                                {/* <h1 id='register_text' className='blink registe_txt_upr'><Link to={'/register-now'}>REGISTER NOW !</Link></h1> */}
                                {/* <br/>
                                <h1 style={{fontSize:'13px'}} id='admitcard_text' className='blink registe_txt_upr'><Link to={'/getadmitcard'}><u >Download Admit Card</u>&nbsp;<span className='new-post'>new</span></Link></h1> */}
                                </>
                            )
                        }
                      
                        </div>
                    </div>
                    <div className='sapnokiudanimg-dv'>
                       <img className='btm_mdbnr-imng' src="/images/nia-admitcard.svg" alt="career" />
                        <div className='udan-inr-div'>
                          <Link to={'/getadmitcard'}>
                        <h1 className='blink' style={{fontSize:'17px', fontWeight: 'bold'}}>Admit Card Available (Download) &nbsp;</h1>
                        {/* <h1 className='blink' style={{fontSize:'17px', fontWeight: 'bold'}}>Admit Card Available (Download) &nbsp;<span className='new-post'>new</span></h1> */}
                          </Link>
                        </div>
                    </div>
                    <div className='startcarerr-bnf-main-dv'>
                    <img className='btm_mdbnr-imng'  src="/images/nia-result.svg" alt="career" />
                    <div className='careersatrtbnt-int-dv'>
                    <Link to={'/result-search'}>
                    {/* <h1 className='' style={{fontSize:'17px', fontWeight: 'bold'}}>Result Not Available</h1> */}
                        <h1 className='blink' style={{fontSize:'17px', fontWeight: 'bold'}}>Result Available (Download)&nbsp; <span className='new-post'>new</span></h1>
                          </Link>
                    </div>
                    </div>

                   
                </div>
            </div>
        </div>
    </>
  )
}

export default UpperBnr