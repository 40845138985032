import React, { useState } from 'react'
import '../components/css/Form.css'
import { EyeFilled } from '@ant-design/icons';
import { Button,Modal } from 'antd';

const FormPreview = ({prevData}) => {

  //  console.log(prevData)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

  return (
    <>
       <Button className="preview-btn"  type="primary" icon={<EyeFilled />} onClick={showModal}>
              Preview
        </Button>

        <Modal title="Form Preview" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000}>
        <div className='form-prv-main-dv border'>
        <div className='form-prv-heaidng-dv'>
            <h4>Check Your Form Details Before Submit</h4>
        </div>

            <div className='form-prv-body-dv'>

                <div className='inr-form-heading-name-input border'>
                    <h6>Candidate Name</h6>
                    <p>{prevData.candidatename ? prevData.candidatename : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Last Name</h6>
                    <p>{prevData.lastname ? prevData.lastname : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Father Name</h6>
                    <p>{prevData.fathername ? prevData.fathername : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Mother Name</h6>
                    <p>{prevData.mothername ? prevData.mothername : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>E-mail</h6>
                    <p>{prevData.email ? prevData.email : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Phone No.</h6>
                    <p>{prevData.phone ? prevData.phone : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Gender</h6>
                    <p>{prevData.gender ? prevData.gender : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Date of Birth</h6>
                    <p>{prevData.dob ? prevData.dob : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Marital Status</h6>
                    <p>{prevData.married ? prevData.married : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Category(Cast)</h6>
                    <p>{prevData.cast ? prevData.cast : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Education</h6>
                    <p>{prevData.education ? prevData.education : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Division</h6>
                    <p>{prevData.educationdivision ? prevData.educationdivision : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Intermediate</h6>
                    <p>{prevData.intermediate ? prevData.intermediate : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Division</h6>
                    <p>{prevData.intermediatedivision ? prevData.intermediatedivision : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>High School</h6>
                    <p>{prevData.highschool ? prevData.highschool : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Division</h6>
                    <p>{prevData.highschooldivision ? prevData.highschooldivision : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Apply For</h6>
                    <p>{prevData.postapplyName ? prevData.postapplyName : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Exam Fees</h6>
                    <p>{prevData.fees ? prevData.fees : '-'}</p>
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Upload Photo</h6>
                    <img src={prevData.profilePhoto ? URL.createObjectURL(prevData.profilePhoto)
                  : "/images/user.png"} width={100}  alt="uploadphoto" />
                </div>
                <div className='inr-form-heading-name-input border'>
                    <h6>Upload Signatures</h6>
                    <img src={prevData.signature ? URL.createObjectURL(prevData.signature) : "/images/sign.png"} width={100}  alt="uploadsignature" />
                </div>
            </div>

                <div className='form-prv-presentadd-dv'>
                    <h5>Present Address</h5>
                </div>

                    <div className='form-prv-presrentadd-inrr-dv'>
                    <div className='inr-form-heading-name-input border'>
                    <h6>Street Name</h6>
                    <p>{prevData.presentstreetname ? prevData.presentstreetname : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>House No</h6>
                    <p>{prevData.presenthouseno ? prevData.presenthouseno : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>Village</h6>
                    <p>{prevData.presentvillage ? prevData.presentvillage : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>City</h6>
                    <p>{prevData.presentcity ? prevData.presentcity : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>Post-office</h6>
                    <p>{prevData.presentpostoffice ? prevData.presentpostoffice : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>State</h6>
                    <p>{prevData.presentstate ? prevData.presentstate : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>District</h6>
                    <p>{prevData.presentdistrict ? prevData.presentdistrict : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>Pincode</h6>
                    <p>{prevData.presentpincode ? prevData.presentpincode : '-'}</p>
                    </div>
                    </div>

                    <div className='form-prv-presentadd-dv border p-3'>
                    <h5>Same Address : {prevData.permanentAddressChecked ? '-' : 'same as above'}</h5>
                    </div>

                    <div className='form-prv-presentadd-dv'>
                    <h5>Permanent Address</h5>
                    </div>

                    <div className='form-prv-presrentadd-inrr-dv'>
                    <div className='inr-form-heading-name-input border'>
                    <h6>Street Name</h6>
                    <p>{prevData.permanentstreetname ? prevData.permanentstreetname : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>House No</h6>
                    <p>{prevData.permanenthouseno ? prevData.permanenthouseno : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>Village</h6>
                    <p>{prevData.permanentvillage ? prevData.permanentvillage : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>City</h6>
                    <p>{prevData.permanentcity ? prevData.permanentcity : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>Post-office</h6>
                    <p>{prevData.permanentpostoffice ? prevData.permanentpostoffice : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>State</h6>
                    <p>{prevData.permanentstate ? prevData.permanentstate : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>District</h6>
                    <p>{prevData.permanentdistrict ? prevData.permanentdistrict : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>Pincode</h6>
                    <p>{prevData.permanentpincode ? prevData.permanentpincode : '-'}</p>
                    </div>
                    </div>

                    <div className='form-prv-presentadd-dv'>
                    <h5>Examination Location</h5>
                    </div>

                    <div className='form-prv-presrentadd-inrr-dv'>
                    <div className='inr-form-heading-name-input border'>
                    <h6>Exam Center State</h6>
                    <p>{prevData.choosestate ? prevData.choosestate : '-'}</p>
                    </div>
                    <div className='inr-form-heading-name-input border'>
                    <h6>Exam Center City</h6>
                    <p>{prevData.chooseCity ? prevData.chooseCity : '-'}</p>
                    </div>
                    </div>

        </div>
        </Modal>
    </>
  )
}

export default FormPreview
