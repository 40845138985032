import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { ExportJsonCsv } from "react-export-json-csv";

const RegisteredUsers = () => {
  const regUsersURL = `${process.env.REACT_APP_HOSTURL}/api/v1/allregisteredusers`;

  const [regData, setRegData] = useState([]);
  const [loading, setLoading] = useState(false);


  const [allFormsData, setAllFormsData] = useState([])
  const [csvLoading,setCSVLoading] = useState(false)

  const [totalUser,setTotalUser] = useState('')

  //* Pagination Start
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [itemsPerpage, setItemsPerPage] = useState(10);

  const indexOfLastitems = currentPage * itemsPerpage;
  const indexOfFirstItems = indexOfLastitems - itemsPerpage;
  const currentItems = regData?.slice(indexOfFirstItems, indexOfLastitems);

  // eslint-disable-next-line
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
// eslint-disable-next-line
  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let increamentBtn = null;
  if (pages.length > maxPageNumberLimit) {
    // eslint-disable-next-line
    increamentBtn = <li onClick={handleNextBtn}> &hellip; </li>;
  }

  for (let i = 0; i <= Math.ceil(regData?.length / itemsPerpage); i++) {
    pages.push(i);
  }
// eslint-disable-next-line
  const renderPageNumber = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  //* Pagination End




  const [page, setPage] = useState(1)
  const [limit,setLimit] = useState(10)
  

  function paginationNextHandler(){
    setPage((page)=> page +1)
    setLimit((limit)=> limit + 10)
    // if(page < 1){
     
    // }else{
    //   return
    // }
  }

  function paginationBackHandler(){
    setPage((page)=> page - 1)
    setLimit((limit)=> limit - 10)
  }


  //* fetch Registered Users

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${regUsersURL}?page=${page}&limit=${limit}`);
     
      setRegData(res.data.response);
      setTotalUser(res.data.total)
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [regUsersURL,page,limit]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  if (loading) {
    return <div className="container">Loading...</div>;
  }

  //*export excel CSV start

  const fetchAllFormsDataCSV = async()=>{
    setCSVLoading(true)
    try{
      const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/allregisteredusers?page=${1}&limit=${999999999}`)

      setAllFormsData(res.data.response)

    }catch(err){
     alert(err.response.data.message)
    }finally{
      setCSVLoading(false)
    }
  }


  const headers = [
    {
      key: "userUniqueId",
      name: "User Unique ID",
    },
    {
      key: "registrationNumber",
      name: "Registration Number",
    },
    {
      key: "candidateName",
      name: "Candidate Name",
    },
    {
      key: "email",
      name: "E-mail Address",
    },
    {
      key: "phone",
      name: "Phone Number",
    },
    {
      key: "dob",
      name: "Date Of Birth",
    },
    {
      key: "date",
      name: "Candidate Registration Date",
    },
  ];

  const datas = [{}];
  allFormsData?.map((d, i) => {
    const obj = {
      userUniqueId: d._id,
      registrationNumber: d.registrationnumber,
      candidateName: d.name,
      email: d.email,
      phone: d.phone,
      dob: d.password,
      date: new Date(d.createdAt).toDateString(),
    };
    datas.push(obj);
    return d;
  });
  //*export excel CSV end

  return (
    <>
      <section className="container">
        <div>
          <div></div>
          <div className="pages-btn-div">

          {allFormsData.length === 0 ? (<button onClick={fetchAllFormsDataCSV} disabled={csvLoading} style={{
                padding: "8px",
                borderRadius: "4px",
                backgroundColor: "teal",
                color: "white",
              }}>{csvLoading ? 'Processing...': 'Load CSV Data'}</button>) : (
                <>
              <ExportJsonCsv
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  backgroundColor: "teal",
                  color: "white",
                }}
                headers={headers}
                items={datas}
                >
                Click To Download
              </ExportJsonCsv>
                </>
              )}

            <div>
              <button
                type="button"
                className="btn btn-primary position-relative"
              >
                Total Registered Users
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {totalUser}
                  <span className="visually-hidden">unread messages</span>
                </span>
              </button>
            </div>
            <ul className="pageNumbers">
              <li>
                <button
                  className="btn btn-warning"
                  onClick={paginationBackHandler}
                 // onClick={handlePrevBtn}
                 // disabled={currentPage === pages[1] ? true : false}
                  disabled={page=== 1}
                >
                  PREV
                </button>
              </li>
              {/* {renderPageNumber}
              {increamentBtn} */}
             <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px'}}>{page}</div>
              <li>
                <button
                  className="btn btn-warning"
                  onClick={paginationNextHandler}
                  // disabled={
                  //   currentPage === pages[pages.length - 1] ? true : false
                  // }
                >
                  NEXT
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="reg-title-div">
          <h2>Registered Cadidates</h2>
        </div>
        <div className="reg-forms-table-div">
          <table className="table table-striped table-hover table-bordered">
            <thead className="table-info">
              <tr>
                <th scope="col">Reg No.</th>
                <th scope="col">Student Name</th>
                <th scope="col">Email Address </th>
                <th scope="col">Phone No.</th>
                <th scope="col">Date Of Birth</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {currentItems?.map((d, i) => {
                return (
                  <tr key={i}>
                    <th scope="row">{d?.registrationnumber}</th>
                    <td>{d?.name}</td>
                    <td>{d?.email}</td>
                    <td>{d?.phone}</td>
                    <td>{d?.password}</td>
                    <td>{new Date(d?.createdAt).toDateString()}</td>
                    <td>{new Date(d?.createdAt).toLocaleTimeString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default RegisteredUsers;
