import "./App.css";
//import Form from "./components/Form"; //!razopay gateway inside
import Form from "./phonepegateway_form/Form"; //!phonepeGatewainside
import SuccessApplicationPDF from "./phonepegateway_form/SuccessApplicationPDF";
import PaymentFailed from "./phonepegateway_form/PaymentFailed";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
//import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
//import Home from "./components/Home";

import Applicationpdf from "./components/Applicationpdf";
import Layout from "./layouts/index";
import Candidate from "./components/Candidate/Candidate";
import Admin from "./components/Admin/Admin";
import Error from "./components/Error";
import ForgetPassword from "./components/ForgetPassword";
import AdminLogin from "./components/Admin/Login";
import Login from "./components/Login";
import Contactquery from "./components/Contactquery";
import { useCookies } from "react-cookie";
import ViewCandidatesDetails from "./components/ViewCandidatesDetails";
import Gallery from "./components/Gallery";
import { useSelector,useDispatch } from "react-redux";
import Careeratairport from "./components/pages/Careeratairport";
import Careerjob from "./components/pages/Careerjobs";
import Insidetheaviation from "./components/pages/Insidetheaviation";
import Selectionprocess from "./components/pages/Selectionprocess";
import Visionandvalue from "./components/pages/Visionandvalue";
import Whyaviation from "./components/pages/Whyaviation";
import FlightAttendant from "./components/pages/FlightAttendant";
import AdmitCard from "./components/Admitcard/AdmitCard";

import NewHomeDesign from "./components/NewHomeDesign/NewHomeDesign";


//other pages
import About from "./components/pages/About";
import AirportDetails from "./components/pages/AirportDetails";
import AptitudeSkill from "./components/pages/AptitudeSkill";
import AviationAftertwelfth from "./components/pages/AviationAftertwelfth";
import BecameCabinCrew from "./components/pages/BecameCabinCrew";
import BecameGroundStaff from "./components/pages/BecomeGroundStaff";
import Cafeteria from "./components/pages/Cafeteria";
import EligibilityExamForAviation from "./components/pages/EligibilityExamForAviation";
import GroundStaff from "./components/pages/GroundStaff";
import Help from "./components/pages/Help";
import HouseKeeping from "./components/pages/HouseKeeping";
import Portal from "./components/pages/Portal";
import PrivacyRefund from "./components/pages/PrivacyRefund";
import ProfessionalTraining from "./components/pages/ProfessionalTraining";
import TrainingOverview from "./components/pages/TrainingOverview";
import WheelChairStaff from "./components/pages/WheelChairStaff";
//import HomeWrapper from "./components/pages/HomeWrapper";
import TestPage from "./components/TestPage";
import PaymentStatus from "./components/PaymentStatus";
import Register from "./components/Register";
import Termandcondition from "./components/policies/Termandcondition";
import PrivacyPol from "./components/policies/PrivacyPol";
import RefundPol from "./components/policies/RefundPol";
import Shipping from "./components/policies/Shipping";
import { useCallback, useEffect } from "react";
import axios from 'axios'
import { updateEditModeStatus } from "./store/editModeSlice";
import AdmitcardForm from "./components/Admitcard/AdmitcardForm";
import ResultForm from "./components/Result/ResultForm";
import ResultData from "./components/Result/ResultData";

function App() {

  const dispatch = useDispatch();


  const getEditModestatus = useCallback( async()=>{
      try{
          const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/editmode`)
         // console.log(res.data.data[0]?.editModeStatus)
          dispatch(updateEditModeStatus(res.data.data[0]?.editModeStatus))

      }catch(err){
        console.log(err)
      }
  },[dispatch])

  useEffect(()=>{
    getEditModestatus()
  },[getEditModestatus])


  let user;

  const extusr = useSelector((state) => state.user.user);
  const [cookies] = useCookies();

  if (extusr) {
    user = true;
  } else if (cookies.email && cookies.password) {
    user = true;
  } else {
    user = false;
  }

  const queryClient = new QueryClient({
    defaultOptions:{
      queries:{
        // staleTime: 60 * 1000, // ye fetch kiya hua data 1 minute ke liye data ko cache me store kar dega after 1 mint ye dubara new data fetch karega
        staleTime: 0, // 0 => ye data ko stale mod me rakhega means ye data ko cache me nahi dalega, jab bhi hum apne current tab pr jayenge wo API call kar ke data re-fetch kar lega
      },
    }
  })


  return (
    <BrowserRouter>
     <QueryClientProvider client={queryClient}>
 
      <Layout>
        <Routes>
          <Route path="/" element={<NewHomeDesign />} />
          {/* <Route path="/" element={<HomeWrapper />} /> */}
          {/* <Route path="/register-now" element={user ? <Form /> : <Register/>} /> */}

          <Route path="/register-now" element={ <Register/>} />
          <Route path="/successapplicationpdf/:id" element={<SuccessApplicationPDF />} />
          <Route path="/paymentfailed" element={<PaymentFailed />} />
          <Route path="/applicationpdf/:id" element={<Applicationpdf />} />
          <Route path="/candidatedashboard" element={<Candidate />} />
          <Route path="/niadb/controller/access" element={<Admin />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/forget" element={user ? "Already Logged In" : <ForgetPassword />}/>
          <Route path="/contactus" element={<Contactquery />} />
          <Route path="/viewcandidatesdetails/:id" element={<ViewCandidatesDetails />}/>
          <Route path="/payment-status" element={<PaymentStatus/>}/>
          <Route path="/application-form" element={<Form/>}/>
          <Route path="/loginuser" element={<Login/>}/>
          <Route path="/hallticket/:ticket" element={<AdmitCard/>}/>
          <Route path="/getadmitcard" element={<AdmitcardForm/>}/>
          <Route path="/result-search" element={<ResultForm/>}/>
          <Route path="/result/:data" element={<ResultData/>}/>

          {/* <Route path="/apply-now" element={user ? <Form/> :  <Register/>}/>
          <Route path="/loginuser" element={user ? <Form/> :  <Login/>}/> */}

          <Route path="/careeratairports" element={<Careeratairport />} />
          <Route path="/careerjob" element={<Careerjob />} />
          <Route path="/insidetheaviation" element={<Insidetheaviation />} />
          <Route path="/jobselectionprocess" element={<Selectionprocess />} />
          <Route path="/visionandvalue" element={<Visionandvalue />} />
          <Route path="/whyaviation" element={<Whyaviation />} />
          <Route path="/flightattandent" element={<FlightAttendant />} />

          <Route path="/aboutnia" element={<About />} />
          <Route path="/airportdetails" element={<AirportDetails />} />
          <Route path="/aptitudeskills" element={<AptitudeSkill />} />
          <Route
            path="/aviationaftertwelfth"
            element={<AviationAftertwelfth />}
          />
          <Route path="/becamecabincrew" element={<BecameCabinCrew />} />
          <Route path="/becamegroundstaff" element={<BecameGroundStaff />} />
          <Route path="/cafeteria" element={<Cafeteria />} />
          <Route
            path="/eligibilityexamforaviation"
            element={<EligibilityExamForAviation />}
          />
          <Route path="/groundstaffs" element={<GroundStaff />} />
          <Route path="/help" element={<Help />} />
          <Route path="/housekeeping" element={<HouseKeeping />} />
          <Route path="/portal" element={<Portal />} />
          <Route path="/privacyrefund" element={<PrivacyRefund />} />
          <Route path="/termcondition" element={<Termandcondition/>} />
          <Route path="/privacypolicy" element={<PrivacyPol/>} />
          <Route path="/refundpolicy" element={<RefundPol/>} />
          <Route path="/shippingdelivery" element={<Shipping/>} />
          <Route
            path="/professionaltraining"
            element={<ProfessionalTraining />}
          />
          <Route path="/trainingoverviews" element={<TrainingOverview />} />
          <Route path="/wheelchairstaff" element={<WheelChairStaff />} />

          <Route path="/test" element={<TestPage/>}/>
          

          <Route path="*" element={<Error />} />
        </Routes>
      </Layout>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
